import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableHeaderItem from "./TableHeaderItem";
import { SortFields, SortOrder } from "model/Business";

interface Props {
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "5px",
        paddingTop: "14px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "8px",
        paddingTop: "22px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "11px",
        paddingTop: "29px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "17px",
        paddingTop: "44px",
        fontSize: "16px",
      },
    },
    issueName: {
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      width: "31%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "91px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "146px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "195px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "295px",
      },
    },
    priority: {
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      width: "13%"
    },
    reporter: {
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      width: "16%",
    },
    date: {
      width: "16%",
    },
    status: {
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      width: "22%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "75px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "122px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "162px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "245px",
      },
    }
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields | undefined, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.cell, classes.issueName)}>
          <TableHeaderItem
            label="Issue"
            sortField={SortFields.ISSUE_NAME}
            sortOrder={sortField === SortFields.ISSUE_NAME ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.priority)}>
          <TableHeaderItem
            label="Priority"
            sortField={SortFields.PRIORITY}
            sortOrder={sortField === SortFields.PRIORITY ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.reporter)}>
          <TableHeaderItem
            label="Reporter"
            sortField={SortFields.REPORTER}
            sortOrder={sortField === SortFields.REPORTER ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.date)}>
          Date
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.status)}>
          <TableHeaderItem
            label="Status"
            sortField={SortFields.STATUS}
            sortOrder={sortField === SortFields.STATUS ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
