import React, { FunctionComponent, MouseEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown, faLongArrowUp } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { SortFields, SortOrder } from "model/Business";

interface Props {
  label: string;
  sortField: SortFields | undefined;
  sortOrder: SortOrder | undefined;
  className?: string;
  center?: boolean;
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: Props) => ({
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: props.center ? "center" : "undefined"
    }),
    icon: {
      marginLeft: "10px",
    },
  })
);

export const TableHeaderItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const clickHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    props.onSort(
      props.sortField,
      props.sortOrder === undefined || props.sortOrder === SortOrder.DESCENDANT
        ? SortOrder.ASCENDANT
        : SortOrder.DESCENDANT
    );
  };

  return (
    <Box className={clsx(classes.container, props.className)} onClick={clickHandler}>
      {props.label}
      {props.sortOrder && (
        <FontAwesomeIcon
          className={classes.icon}
          icon={props.sortOrder === SortOrder.ASCENDANT ? faLongArrowUp : faLongArrowDown}
        />
      )}
    </Box>
  );
};

export default TableHeaderItem;
