import React from "react";
import { Box, ClickAwayListener, Dialog, DialogContent, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";

interface OrderCancelPopUpProps {
    //
    onClose: () => void;
    onConfirmCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            "& .MuiDialog-paper": {
                boxShadow: "0px 10px 40px #00000029",
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(600)}px`,
                    minWidth: `${reduceResolution(600)}px`,
                    borderRadius: `${reduceResolution(21)}px`,
                    height: `${reduceResolution(290)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    width: "600px",
                    minWidth: "600px",
                    borderRadius: "21px",
                    height: "290px",
                },
            },

            "& .MuiDialogContent-root": {
                overflowY: "hidden",
                paddingTop: 0,
            }
        },

        boxContainer: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(40)}px`,
                paddingRight: `${reduceResolution(40)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "40px",
                paddingRight: "40px",
            },
        },

        boxSpinner: {
            display: "flex",
            height: "100%",
        },

        dialogContainer: {
            width: "100%",
            "& .MuiDialog-paper": {
                overflowY: "hidden"
            },
            display: "flex",
            flexDirection: "column",
            padding: 0,
        },

        boxButtons: {
            borderTop: "1px solid #DDDDDD",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
                paddingTop: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: "20px",
                paddingTop: "20px",
            },
        },
        boxButtonsFlex: {
            width: "100%",
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(30)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "30px",
            },
        },
        buttonsContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(145)}px`,
                height: `${reduceResolution(47)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "145px",
                height: "47px",
            },
        },

        buttonCancel: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(8)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: "8px",
            },
        },

        question: {
            fontWeight: 600,
            textAlign: "center",
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                lineHeight: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(50)}px`,
                paddingBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: "24px",
                lineHeight: "30px",
                paddingTop: "50px",
                paddingBottom: "20px",
            },
        },

        typoQuestion: {
            textAlign: "center",
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(0)}px`,
                paddingBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: "30px",
                paddingTop: "20px",
                paddingBottom: "20px",
            },
        }

    })
);

export const OrderCancelPopUp: FunctionComponent<OrderCancelPopUpProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();

    const handleConfirmSelection = () => {
        props.onConfirmCancel();
    };

    const handlerCancel = () => {
        props.onClose();
    };

    const contentView = (
        <>
            <Box className={classes.boxContainer}>
                <Typography className={classes.question}>Cancel Order</Typography>
                <Typography className={clsx(terminalCommonClasses.typo20_400, classes.typoQuestion)}>Are you sure you want cancel this order?</Typography>
            </Box>
            <Box className={classes.boxButtons}>
                <Box className={classes.boxButtonsFlex}>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            label="Discard"
                            loading={false}
                            onClick={handlerCancel}
                            variant="primary"
                            size="medium"
                            color={"black"}
                            className={clsx(classes.button)}
                        />
                        <Button
                            label="Confirm"
                            loading={false}
                            onClick={handleConfirmSelection}
                            variant="danger"
                            size="medium"
                            color={"green"}
                            className={clsx(classes.button, classes.buttonCancel)}
                        />
                    </Box>
                </Box>
            </Box>
        </>);

    return (<Box>
        <Dialog open={true} className={clsx(classes.container)}>
            <ClickAwayListener onClickAway={handlerCancel}>
                <DialogContent className={classes.dialogContainer}>
                    {contentView}
                </DialogContent>
            </ClickAwayListener>
        </Dialog>
    </Box>);
};

export default OrderCancelPopUp;
