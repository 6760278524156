import React, { FunctionComponent } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import LeftHeader from "./LeftHeader";
import { Button } from "components/UI";
import { statusTerminal, Terminal } from "model/TerminalOrder";
import { reduceResolution, wbp } from "Theme";
interface Props {
  terminal: Terminal
  businessName?: string;
  className?: string;
  //
  onClickBusinessName: () => void;
  onActive: (terminalId: number, active: boolean) => void;
  onDelete: (terminalId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      borderBottom: "1px solid #F1F1F1",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(85)}px`,
        paddingLeft: `${reduceResolution(55)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "85px",
        paddingLeft: "55px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    leftHeaderContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "20px",
      },
    },
    butonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    buttonContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    buttonDelete: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "10px",
      },
    },
  })
);

export const HeaderComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const deleteHandler = () => {
    props.onDelete(props.terminal.id);
  };

  //

  const leftHeader = (
    <LeftHeader
      terminal={props.terminal}
      businessName={props.businessName}
      //
      onClickBusinessName={() => props.onClickBusinessName()}
    />
  );

  const button: JSX.Element = (
    <Box className={classes.buttonContent}>
      {props.terminal.status !== statusTerminal.DELETED && <Button
        label="Delete"
        variant="secondary"
        color="red"
        onClick={deleteHandler}
        loading={false}
        className={classes.buttonDelete}
      />}
      {props.terminal.active &&
        <Button
          label="Disable"
          variant="danger"
          onClick={() => props.onActive(props.terminal.id, false)}
          loading={false}
        />}

      {!props.terminal.active &&
        <Button
          label="Enable"
          color="green"
          variant="primary"
          onClick={() => props.onActive(props.terminal.id, true)}
          loading={false}
        />
      }
    </Box>
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.headerContainer}>
        <Grid container>
          <Grid item xs={10}>
            <Box className={classes.leftHeaderContainer}>
              {leftHeader}
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.butonContainer}>
            {button}
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
};

export default HeaderComponent;
