import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Path } from "routes/Paths";

export interface Item {
  name: string;
  path: Path;
  active?: boolean;
}
interface Props {
  item: Item;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    container: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        marginRight: "17px",
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "27px",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "36px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "54px",
      },
    },
    nameContainer: {
      display: "flex",
      height: "100%",
      flex: "1 1 auto",
      justifyContent: "center",
      alignItems: "center",
    },
    name: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "20px",
      },
    },
    activeName: {
      fontWeight: 600,
    },
    active: {
      position: "absolute",
      bottom: "-3px",
      height: "4px",
      width: "100%",
      backgroundColor: theme.palette.primary.main
    },
  })
);

export const HeaderItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Link to={props.item.path} className={classes.link}>
      <Box className={classes.container}>
        <Box className={clsx(classes.nameContainer)}>
          <Typography className={clsx(classes.name, { [classes.activeName]: props.item.active })}>
            {props.item.name}
          </Typography>
          {props.item.active && <Box className={classes.active}></Box>}
        </Box>
      </Box>
    </Link>
  );
};

export default HeaderItem;
