import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box, Typography,
} from "@material-ui/core";
import { HomePath } from "routes/Paths";
import { User } from "model/User";
import ChipMUI from "@material-ui/core/Chip";
import { Link } from "components/UI";

interface Props {
  user?: User;
  businessName?: string;
  onClickBusinessName: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex"
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    avatar: {
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        marginRight: "7px",
        width: "20px",
        height: "20px"
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "11px",
        width: "33px",
        height: "33px"
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "15px",
        width: "44px",
        height: "44px"
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "22px",
        width: "66px",
        height: "66px"
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",       
      [theme.breakpoints.down("sm")]: {
        paddingTop: "6px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "18px",
      },
    },
    content: {
      display: "flex",
    },
    breadcrumb: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "3px",
        fontSize: "7px",
        lineHeight: "7px",
        paddingTop: "3px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "5px",
        fontSize: "9px",
        lineHeight: "11px",
        paddingTop: "2px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "7px",
        fontSize: "11px",
        lineHeight: "15px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "10px",
        fontSize: "14px",
        lineHeight: "22px"
      },
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "3px",
        fontSize: "11px",
        lineHeight: "17px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "5px",
        fontSize: "18px",
        lineHeight: "27px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "7px",
        fontSize: "24px",
        lineHeight: "36px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "10px",
        fontSize: "36px",
        lineHeight: "54px"
      },
    },
    location: {
      [theme.breakpoints.down("sm")]: {
        lineHeight: "6px",
        fontSize: "5px",
        marginBottom: "6px",
      },
      [theme.breakpoints.only("md")]: {
        lineHeight: "10px",
        fontSize: "8px",
        marginBottom: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        lineHeight: "13px",
        fontSize: "11px",
        marginBottom: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        lineHeight: "20px",
        fontSize: "16px",
        marginBottom: "18px",
      },
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "2px",
        fontSize: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "4px",
        fontSize: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "5px",
        fontSize: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "8px",
        fontSize: "16px",
      },
    },
    link: {
      textDecoration: "none",
      marginTop: "1px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "-15px",
        fontSize: "4px",
        height: "0px"
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "-10px",
        fontSize: "7px",
        height: "0px"
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "-6px",
        fontSize: "9px",
        height: "12px"
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "-2px",
        fontSize: "14px",
        height: "20px",
      },
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#DBDAD7",
      [theme.breakpoints.down("sm")]: {
        width: "33px",
        height: "8px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "70px",
        height: "12px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "84px",
        height: "17px",
        fontSize: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "109px",
        height: "25px",
        fontSize: "14px",
      },
    },
    suspendedContainer: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px",
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: "8px",
        marginTop: "3px"
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: "11px",
        marginTop: "7px"
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "17px",
        marginTop: "14px"
      },
    }
  })
);

export const LeftHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [user, setUser] = useState<User | undefined>(props.user);

  useEffect(() => {
    setUser(props.user)
  }, [props.user])

  return (
    <Box className={classes.container}>
      <Box className={classes.avatarContainer}>
        <Avatar src="" className={classes.avatar} />
      </Box>
      <Box className={classes.titleContainer}>
        <Box className={classes.content}>
          <Link 
            label="Clients" 
            linkTo={HomePath}
            size="small"
            color="black" 
            className={classes.link} 
          />
          <Typography className={classes.breadcrumb}>
            / 
          </Typography>
          <Link 
            label={props.businessName} 
            onClick={() => props.onClickBusinessName()}
            size="small"
            color="black" 
            className={classes.link} 
          />
          <Typography className={classes.breadcrumb}>
            / <strong>User Profile</strong>
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.title}>{user?.firstName} {user?.lastName}</Typography>
          <Box className={classes.suspendedContainer}>
            {!user?.active &&
              <ChipMUI
                label="Suspended"
                className={classes.chip}
                clickable={false}
                onClick={() => {}}
            />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftHeader;
