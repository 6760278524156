import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  title: string;
  description: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "42px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: "56px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "64px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "72px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "96px",
      },
    },
    title: {
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
        fontWeight: 600,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "16px",
        fontWeight: 600,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "18px",
        fontWeight: 600,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "20px",
        fontWeight: 600,
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "24px",
        fontWeight: 600,
      },
    },
    description: {
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        marginTop: "8px",
        fontSize: "13px",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "8px",
        fontSize: "14px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "8px",
        fontSize: "15px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "8px",
        fontSize: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "12px",
        fontSize: "18px",
      },
    },
  })
);

export const TableMessage: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{props.title}</Typography>
      <Typography className={classes.description}>
        {props.description}
      </Typography>
    </Box>
  );
};

export default TableMessage;
