import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutThunk } from "actions/login/LoginActions";

export const Logout: FunctionComponent = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(logoutThunk());
  }, []);

  return null;
};

export default Logout;
