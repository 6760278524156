import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SuperadminsComponent from "components/Superadmins";

interface Props {

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "#fff"
    },
  })
);

export const Superadmins: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <SuperadminsComponent />
    </Box>
  );
};

export default Superadmins;

