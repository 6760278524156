import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { SingleChip } from "components/UI";
import React, { FunctionComponent } from "react";
import { TerminalStatuses } from "model/Masterdata";
import { useMasterData } from "hooks";
import { statusTerminal } from "model/TerminalOrder";

interface TerminalStatusProps {
  terminalStatus: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: "#FFF8DD",
      textTransform: "capitalize",
      "& p": {
        color: "#C18733",
      },
      width: "fit-content",
    },

    ready: {
      backgroundColor: "#E9F8FF",
      "& p": { color: "#00B0FF", },
    },

    inUse: {
      backgroundColor: "#5E8677",
      "& p": { color: "#FFF", },
    },

    support: {
      backgroundColor: "#C18733",
      "& p": { color: "#FFF", },
    },

    deleted: {
      backgroundColor: "#EF4F57",
      "& p": { color: "#FFF", },
    },

    decomissioned: {
      backgroundColor: "#E6E6E6",
      "& p": { color: "#000", },
    },
  })
);

export const TerminalStatus: FunctionComponent<TerminalStatusProps> = (props) => {
  const masterData = useMasterData();
  const terminalStatus: TerminalStatuses | undefined = masterData.terminalStatuses.find((status) => status.id === props.terminalStatus);
  const classes = useStyles();

  return (
    <SingleChip className={
      clsx(classes.default,
        { [classes.ready]: terminalStatus?.id === statusTerminal.READY },
        { [classes.inUse]: terminalStatus?.id === statusTerminal.IN_USE },
        { [classes.support]: terminalStatus?.id === statusTerminal.SUPPORT },
        { [classes.deleted]: terminalStatus?.id === statusTerminal.DELETED },
        { [classes.decomissioned]: terminalStatus?.id === statusTerminal.DECOMISSIONED },
      )}>
      {terminalStatus?.name || "-"}
    </SingleChip>
  );
};

export default TerminalStatus;
