import { Auth } from "model/Auth";

export interface LoginState {
  auth: Auth;
  status: LoginStatus;
  loading: boolean;
}

export enum LoginStatus {
  Initial,
  Loging,
  LoginSuccess,
  LoginFail,
  Logout,
  Error
}