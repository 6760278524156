import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { BusinessStaff as BusinessStaffModel, SortFields, SortOrder } from "model/Business";
import { Table } from "components/UI";
import TableHeader from "./TableStaff/TableHeader";
import TableBody from "./TableStaff/TableBody";

interface Props {
  staff: Array<BusinessStaffModel>;
  marketplaceId: number;
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
  onShow: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },
  })
);

export const BusinessStaff: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const businessStaffComponent = (
    <Table
      head={<TableHeader onSort={props.onSort} />}
      body={<TableBody staff={props.staff} marketplaceId={props.marketplaceId} onShow={props.onShow}/>}
    />
  );

  return (
    <Box className={classes.container}>
      {businessStaffComponent}
    </Box>
  );
};

export default BusinessStaff;
