import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItemWithConfirmation from "components/UI/Toolbar/ToolbarItemWithConfirmation";
import { faBan, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { Superadmin } from "model/Superadmin";

interface Props {
  superadmin: Superadmin;
  onEditSuperadmin: (superadmin: Superadmin) => void;
  activate: (superadminId: number, active: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "28px",
        paddingRight: "28px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        paddingTop: "13px",
        paddingBottom: "13px",
        paddingLeft: "45px",
        paddingRight: "45px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        paddingTop: "17px",
        paddingBottom: "17px",
        paddingLeft: "60px",
        paddingRight: "60px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
        paddingLeft: "90px",
        paddingRight: "90px"
      },
    },
    email: {
      width: "29%",
    },
    status: {
      width: "9%",
      color: "#5E8677",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "6px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "21px",
      }
    },
    disabled: {
      color: "#D3D3D3"
    },
  })
);

export const SuperadminRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const handleActivate = () => {
    if(props.superadmin.id){
      props.activate(props.superadmin.id, true);
    }
  };
  const handleDeactivate = () => {
    if(props.superadmin.id){
      props.activate(props.superadmin.id, false);
    }
  };

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, {[classes.disabled]: props.superadmin.status.id === 2})} onClick={() =>props.onEditSuperadmin(props.superadmin)}>
        {props.superadmin.firstName}
      </TableCell>
      <TableCell className={clsx(classes.cell, {[classes.disabled]: props.superadmin.status.id === 2})} onClick={() =>props.onEditSuperadmin(props.superadmin)}>
        {props.superadmin.lastName}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.email, {[classes.disabled]: props.superadmin.status.id === 2})} onClick={() =>props.onEditSuperadmin(props.superadmin)}>
        {props.superadmin.email}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.status, {[classes.disabled]: props.superadmin.status.id === 2})} onClick={() =>props.onEditSuperadmin(props.superadmin)}>
        {props.superadmin.status.name.toUpperCase()}
      </TableCell>
      <TableCell className={classes.cell}>
        <ToolbarButton>
          {props.superadmin.status.id !== 1 ? (
            <ToolbarItemWithConfirmation
              icon={faCheckCircle}
              onConfirmedClick={handleActivate}
              text={"Activate"}
              confirmQuestion={
                <Typography>
                  Do you want to activate{" "}
                  <b>{props.superadmin.firstName} {props.superadmin.lastName}</b> ?{" "}
                </Typography>
              }
              confirmButtonLabel="Yes"
              cancelButtonLabel="No"
            />
          ) : (
            <ToolbarItemWithConfirmation
              icon={faBan}
              onConfirmedClick={handleDeactivate}
              text={"Deactivate"}
              confirmQuestion={
                <Typography>
                  Do you want to deactivate{" "}
                  <b>{props.superadmin.firstName} {props.superadmin.lastName}</b> ?{" "}
                </Typography>
              }
              confirmButtonLabel="Yes"
              cancelButtonLabel="No"
            />
          )}        
        </ToolbarButton>
      </TableCell>
    </TableRow>
  );
};

export default SuperadminRow;