import React, { FunctionComponent } from "react";
import { Box, TableBody as TableBodyMUI } from "@material-ui/core";
import SuperadminRow from "../SuperadminRow";
import { Superadmin } from "model/Superadmin";

interface Props {
  superadmins: Array<Superadmin>;
  onEditSuperadmin: (superadmin: Superadmin) => void;
  activate: (superadminId: number, active: boolean) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.superadmins.map((superadmin, index) => (
        <SuperadminRow 
          key={index} 
          superadmin={superadmin} 
          activate={props.activate}
          onEditSuperadmin={props.onEditSuperadmin}
        /> 
      ))}
    </TableBodyMUI>
  );
};

export default TableBody;
