import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Avatar, ClickAwayListener } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Button, CheckboxCircle } from "components/UI";
import _ from "lodash";
import { Option } from "@spike/model";
import { wbp, reduceResolution } from "Theme";

interface FilterProps {
  className?: string;
  icon: IconDefinition;
  label?: string;
  options: Array<Option<string>>;
  //
  onSelect: (ids: Array<string | undefined>, all: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    button: {
      background: "#fff",
      [theme.breakpoints.down(wbp)]: {
        minWidth: `${reduceResolution(115)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        minWidth: "115px",
      },
    },
    buttonAvatar: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(21)}px`,
        height: `${reduceResolution(21)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "21px",
        height: "21px",
      },
    },
    spinnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(143)}px`,
        height: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "40px",
        width: "143px",
      },
    },
    spinner: {
      "& .MuiCircularProgress-root": {
        [theme.breakpoints.down(wbp)]: {
          width: `${reduceResolution(20)}px !important`,
          height: `${reduceResolution(20)}px !important`,
        },
        [theme.breakpoints.up(wbp)]: {
          height: "20px !important",
          width: "20px !important",
        },
      },
    },
    list: {
      position: "absolute",
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: "white",
      zIndex: 10,
      [theme.breakpoints.down(wbp)]: {
        top: `${reduceResolution(50)}px`,
        borderRadius: `${reduceResolution(12)}px`,
        minWidth: `${reduceResolution(143)}px`,
        maxWidth: `${reduceResolution(394)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        top: "50px",
        borderRadius: "12px",
        minWidth: "143px",
        maxWidth: "394px",
      },
    },
    listHeader: {
      width: "100%",
      borderBottom: "1px solid #D3D3D3",
      [theme.breakpoints.down("md")]: {
        paddingTop: "13px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "16px",
        paddingBottom: "7px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "20px",
        paddingBottom: "9px",
      },
    },
    listBody: {
      width: "100%",
      overflowY: "scroll",
      [theme.breakpoints.down(wbp)]: {
        maxHeight: `${reduceResolution(180)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        maxHeight: "180px",
      },
    },
    listFooter: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      borderBottom: "1px solid #D3D3D3",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(16)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
        borderRadius: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "16px",
        paddingBottom: "20px",
        borderRadius: "12px"
      },
    },
    listPadding: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(18)}px`,
        paddingRight: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "18px",
        paddingRight: "18px",
      },
    },
    firstOption: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "5px",
      },
    },
    allStatus: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    option: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "11px",
      },

      "& .MuiTypography-root": {
        fontWeight: 400,
        fontFamily: "Poppins",
        [theme.breakpoints.down(wbp)]: {
          fontSize: `${reduceResolution(13)}px`,
          lineHeight: `${reduceResolution(22)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "13px",
          lineHeight: "22px",
        },
      },
    },
  })
);

export const Filter: FunctionComponent<FilterProps> = (props) => {
  const classes = useStyles();

  const [showList, setShowList] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(props.label ? props.label : "Status");
  const [buttonAvatar, setButtonAvatar] = useState<JSX.Element | undefined>();
  const [selected, setSelected] = useState<Array<string | undefined>>([...props.options.map(option => option.id)]);
  const [allSelected, setAllSelected] = useState(true);
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    const ids = props.options.map(option => option.id);
    const newIds = _.difference(props.options.map(option => option.id), options.map(option => option.id));
    setSelected(prev => [...prev.filter(id => ids.includes(id!)), ...newIds]);
    setOptions(props.options);
  }, [props.options]);

  const closeHandler = () => {
    setShowList(false);
    const ids = props.options.map(option => option.id);
    const newIds = _.difference(props.options.map(option => option.id), options.map(option => option.id));
    setSelected(prev => [...prev.filter(id => ids.includes(id!)), ...newIds]);
  };

  const clickButtonHandler = () => {
    setShowList((prev) => !prev);
  };

  const selectOptionHandler = (checked: boolean, optionId: string | undefined) => {
    if (checked) {
      setAllSelected(selected.length === options.length);
      setSelected((prev) => [...prev.filter((id) => id !== optionId), optionId]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== optionId));
      setAllSelected(false);
    }
  };

  const clickAllHandler = (checked: boolean) => {
    setAllSelected(checked);
    if (checked) {
      setSelected([...options.map((option) => option.id)]);
    } else {
      setSelected([]);
    }
  };

  const clickApplyHandler = () => {
    if (allSelected) {
      setButtonLabel(props.label ? "All " + props.label : "All");
      setButtonAvatar(undefined);
    } else if (selected.length === 0) {
      setButtonLabel(props.label ? "No " + props.label : "No Status");
      setButtonAvatar(undefined);
    } else if (selected.length === 1 && selected[0] !== undefined) {
      const option = options.find((option) => option.id === selected[0])!;
      setButtonLabel(option.name);
      setButtonAvatar(props.label ? undefined : <Avatar src={option.description} className={classes.buttonAvatar} />);
    } else if (selected.length === 1 && selected[0] === undefined) {
      setButtonLabel(`Unassigned`);
      setButtonAvatar(undefined);
    } else {
      setButtonLabel(props.label ? `${props.label} (${selected.filter(id => id !== undefined).length})` : `Status (${selected.filter(id => id !== undefined).length})`);
      setButtonAvatar(undefined);
    }
    props.onSelect(selected, allSelected);
    setShowList(false);
  };

  const list = (
    <Box className={classes.list}>
      <Box className={clsx(classes.listHeader, classes.listPadding)}>
        <CheckboxCircle
          label="All"
          checked={allSelected}
          onChange={clickAllHandler}
          className={clsx(classes.option, classes.allStatus)}
        />
      </Box>
      <Box className={clsx(classes.listBody, classes.listPadding)}>
        {options.map((option, index) => (
          <CheckboxCircle
            key={option.id}
            label={`${option.name}`}
            checked={selected.includes(option.id)}
            onChange={(checked: boolean) => {
              selectOptionHandler(checked, option.id);
            }}
            className={clsx(classes.option, { [classes.firstOption]: index === 0 })}
          />
        ))}
      </Box>
      <Box className={clsx(classes.listFooter, classes.listPadding)}>
        <Button label="Apply" color="green" onClick={clickApplyHandler} />
      </Box>
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <Box className={clsx(classes.container, props.className)}>
        <Button
          label={buttonLabel}
          startIcon={!buttonAvatar ? props.icon : undefined}
          startElement={buttonAvatar}
          onClick={clickButtonHandler}
          variant="secondary"
          color="green"
          className={classes.button}
        />
        {showList && list}
      </Box>
    </ClickAwayListener>
  );
};

export default Filter;
