import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { wbp, reduceResolution } from "Theme";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
    },
    cell: {
      paddingTop: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      textAlign: "left",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "14px",
      },
    },

    cellOrder: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(70)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "80px",
      },
    },
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  return (
    <TableHead className={classes.table}>
      <TableRow>
        <TableCell className={clsx(classes.cell, classes.cellOrder, terminalCommonClasses.typo16_600)}>
          Order
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Client
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Date
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Items
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Amount
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Status
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          City
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Courier
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
          Tracking #
        </TableCell>
        <TableCell className={clsx(classes.cell, terminalCommonClasses.typo16_600)}>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
