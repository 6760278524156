import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import ChipMUI from "@material-ui/core/Chip";
import { UserBusiness } from "model/User";
import { Button } from "components/UI";
import { faUser, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import SelectBusinessAccess  from "./SelectBusinessAccess";
import SelectBusinessRole  from "./SelectBusinessRole";
import { Option } from "model";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";

interface Props {
  business?: UserBusiness;
  onImpersonate: (businessId: number) => void;
  onChangeRole: (role: Option<number>, workId: number, currentAccess: Option<number>) => void;
  onChangeAccess: (access: Option<number>, workId: number, currentRole: Option<number>) => void;
  onRemove: (business: UserBusiness) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      paddingLeft: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        paddingTop: "17px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    businessName: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px",
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "16px",
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "22px",
        fontSize: "14px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "33px",
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    category: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "42px"
      },
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    loginButton: {
      "& .MuiButton-startIcon": {
        [theme.breakpoints.down("sm")]: {
          marginRight: "1px"
        },
        [theme.breakpoints.only("md")]: {
          marginRight: "1px"
        },
        [theme.breakpoints.only("lg")]: {
          marginRight: "2px"
        },
        [theme.breakpoints.only("xl")]: {
          marginRight: "4px"
        },
      },
      "& .MuiButton-label": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "5px"
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "8px"
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "11px"
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px"
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "31px",
        height: "15px",
      },
      [theme.breakpoints.only("md")]: {
        width: "50px",
        height: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "66px",
        height: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        height: "49px",
      },
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "3px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "4px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "6px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "3px"
      },
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#DBDAD7",
      [theme.breakpoints.down("sm")]: {
        width: "33px",
        height: "8px",
        fontSize: "5px",
        marginBottom: "5px"
      },
      [theme.breakpoints.only("md")]: {
        width: "70px",
        height: "12px",
        fontSize: "8px",
        marginBottom: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "84px",
        height: "17px",
        fontSize: "10px",
        marginBottom: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "109px",
        height: "25px",
        fontSize: "14px",
        marginBottom: "16px",
      },
    },
    dental: {
      backgroundColor: "#A6BAC8"
    },
    grooming: {
      backgroundColor: "#E9F0ED"
    },
    boarding: {
      backgroundColor: "#E4DDD5"
    },
    daycare: {
      backgroundColor: "#FAEFDF"
    },
    role: {
      verticalAlign: "initial",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "13px",
      },
      [theme.breakpoints.only("md")]: {
        width: "81px",
        height: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "108px",
        height: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "190px",
        height: "43px",
      },
    },
    access: {
      verticalAlign: "initial",
      [theme.breakpoints.down("sm")]: {
        width: "68px",
        height: "13px",
      },
      [theme.breakpoints.only("md")]: {
        width: "109px",
        height: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "145px",
        height: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "220px",
        height: "43px",
      },
    },
    multiplesCategories: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "9px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "14px",
      },
    },
    singleCategories: {
      marginBottom: "0px !important"
    }
  })
);

export const BusinessRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [business, setBusiness] = useState<UserBusiness>(props.business!);

  useEffect(() => {
    setBusiness(props.business!);
  }, [props.business]);

  const handleChangeRole = (role: Option<number> | undefined) => {
    setBusiness((prev) => {
      return {...prev!, role: role!};
    });
    props.onChangeRole(role!, business.workId, business.accessLevel);
  };

  const handleChangeAccess = (access: Option<number> | undefined) => {
    setBusiness((prev) => {
      return {...prev!, accessLevel: access!};
    });
    props.onChangeAccess(access!, business.workId, business.role);
  };

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.businessName)}>
        {business?.businessName}
      </TableCell>
      <TableCell className={
        clsx(classes.cell, classes.text, {[classes.multiplesCategories] : business?.businessCategories.length > 1})}>
        {business?.businessCategories.map((categ) => {
          return <Box>
            <ChipMUI
              label={props.business?.businessCategories[0]?.name}
              className={
                clsx(classes.chip, {
                  [classes.dental]: categ.id === "dental", 
                  [classes.grooming]: categ.id === "grooming", 
                  [classes.boarding]: categ.id === "boarding", 
                  [classes.daycare]: categ.id === "daycare",
                  [classes.singleCategories]: business?.businessCategories.length === 1                  
                })
              }
              clickable={false}
            />
          </Box>
        })}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)}>
        <SelectBusinessRole
          selected={business.role}
          onChange={handleChangeRole}
          className={classes.role}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)}>
        <SelectBusinessAccess
          selected={business.accessLevel}
          onChange={handleChangeAccess}
          className={classes.access}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.buttonContainer)}>
        <Button
          startIcon={faUser}
          label="Login"
          onClick={() => props.onImpersonate(business.businessId)}
          variant="primary"
          size="medium"
          className={classes.loginButton}
        /> 
      </TableCell> 
      <TableCell className={clsx(classes.cell, classes.buttonContainer)}>
        <ToolbarButton>
          <ToolbarItem
            icon={faTrashAlt}
            text={"Remove"}
            onClick={() => props.onRemove(business)}
          />
        </ToolbarButton>
      </TableCell>
    </TableRow>
  );
};

export default BusinessRow;