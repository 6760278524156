import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import ApiClient from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { showErrorAlert, showSuccessAlert } from "actions/alerts/AlertsActions";
import { UserDto } from "./ImpersonationDtos";
import { convert } from "./ImpersonationConverter";
import { createTokenConfig } from "api/ApiClient";

const usersUrl = "admin/users";

export const impersonateThunk = (
  userId: number,
  marketplaceId: number,
): ThunkAction<void, null, null, AlertAction> => {
  return async (dispatch) => {
    const HTTP_STATUS_401_UNAUTHORIZED = 401;

    const url=`${usersUrl}/${userId}`;
    try {
      const response: AxiosResponse<UserDto> = await ApiClient.get(url, createTokenConfig());

      const impersonateUrl = `${process.env.REACT_APP_IMPERSONATE_MARKETPLACE_URL}/${marketplaceId}/${convert(response.data, marketplaceId)}`;
      dispatch(showSuccessAlert("Impersonated!"));

      window.open(impersonateUrl, "cuddles_marketplace");
    } catch (apiError) {
      if (apiError.response && apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
        dispatch(showErrorAlert(`Unauthorized to get users data.`));
      } else {
        dispatch(showErrorAlert(`Error getting user id = ${userId}.`));
      }
    }
  };
};

