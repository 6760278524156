import { MasterdataDto } from "./MasterdataDtos";
import { Masterdata } from "model/Masterdata";

export const convert = (dto: MasterdataDto): Masterdata => {
  return {
    accessLevels: [...dto.access_level],
    businessAreas: [...dto.business_areas],
    orderStatuses: dto.order_statuses.map(
      (order) => (
        {
          id: order.id,
          name: order.name,
          backgroundColor: order.background_color,
          fontColor: order.font_color
        })) || [],
    roles: [...dto.roles],
    terminalsModels: dto.terminal_models.map(
      (order) => (
        {
          id: order.id,
          name: order.name,
          description: order.description,
          price: order.price,
          type: order.type,
        })) || [],
    terminalStatuses: dto.terminal_statuses.map(
      (terminal) => (
        {
          id: terminal.id,
          name: terminal.name,
        })) || [],
  };
}
