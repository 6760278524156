import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box
} from "@material-ui/core";
import { Table } from "components/UI";
import TableHeader from "./TableAlerts/TableHeader";
import TableBody from "./TableAlerts/TableBody";
import { BusinessIssue, SortFields, SortOrder } from "model/Business";

interface Props {
  issues: Array<BusinessIssue>;
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },
  })
);

export const BusinessAlerts: FunctionComponent<Props> = (props) => {
  const classes = useStyles();


  const businessAlertsComponent = (
    <Table
      head={<TableHeader onSort={props.onSort} />}
      body={<TableBody issues={props.issues} />}
    />
  );

  return (
    <Box className={classes.container}>
      {businessAlertsComponent}
    </Box>
  );
};

export default BusinessAlerts;
