import { AlertsState } from "./AlertsState";
import {
  RESET_ALERT_ACTION_TYPE,
  ADD_ALERT_ACTION_TYPE,
  REMOVE_ALERT_ACTION_TYPE,
  AlertAction,
} from "../../actions/alerts/AlertsActionsTypes";
import { Reducer } from "redux";

const initialState: AlertsState = {
  messages: []
};

export const alertsReducer: Reducer<AlertsState, AlertAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RESET_ALERT_ACTION_TYPE:
      return {
        ...initialState
      };
    case ADD_ALERT_ACTION_TYPE:
      return {
        ...state,
        messages: [...state.messages, {...action.payload}],
      };
    case REMOVE_ALERT_ACTION_TYPE:
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default alertsReducer;
