import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Header } from "components/UI";
import { ClientsOverview } from "model/Client";

interface Props {
  title?: string;
  overview?: ClientsOverview;
  searchPlaceholder?: string;
  onSearch?: (text: string) => void;
  onBack?: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dataContainer: {
      textAlign: "center",
      borderRight: "1px solid #F1F1F1",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "13px",
        paddingRight: "13px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "21px",
        paddingRight: "21px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "28px",
        paddingRight: "28px"
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "42px",
        paddingRight: "42px"
      },
    },
    revenueContainer: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "13px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "28px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "42px",
      },
    },
    number: {
      fontWeight: 300,
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "13px",
        paddingBottom: "5px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "21px",
        lineHeight: "21px",
        paddingBottom: "7px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "28px",
        lineHeight: "28px",
        paddingBottom: "10px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "42px",
        lineHeight: "42px",
        paddingBottom: "5px"
      },
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "13px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
        lineHeight: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
        lineHeight: "28px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
        lineHeight: "42px",
      },
    }
  })
);

export const HeaderComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const overview: JSX.Element = (
    <Box className={classes.overviewContainer}>
      <Box className={classes.dataContainer}>
        <Typography className={classes.number}>{props.overview?.totalBusinesses}</Typography>
        <Typography className={classes.text}>BUSINESSES</Typography>
      </Box>
      <Box className={classes.dataContainer}>
        <Typography className={classes.number}>{props.overview?.totalUsers}</Typography>
        <Typography className={classes.text}>USERS</Typography>
      </Box>
      <Box className={classes.revenueContainer}>
        <Typography className={classes.number}>{props.overview?.totalRevenue}</Typography>
        <Typography className={classes.text}>REVENUE</Typography>
      </Box>
    </Box>
  );

  return (
    <Header {...props} searchPlaceholder="Search by Business Name" rightElements={overview}/>
  );
};

export default HeaderComponent;
