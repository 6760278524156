import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography
} from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Terminal, TerminalModel } from "model/TerminalOrder";
import {
  getTerminalThunk,
  changeTerminalStatusThunk,
  saveTerminalStatusThunk,
  activeTerminalStatusThunk,
} from "actions/orders/OrdersActions";
import clsx from "clsx";
import { OrdersStatus } from "reducers/orders/OrdersState";
import HeaderComponent from "./UI/HeaderComponent";
import { useTerminalCommonStyles } from "components/Orders/TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import { TerminalImage } from "components/Orders/UI/TerminalImage";
import { useMasterData } from "hooks";
import { TerminalStatus } from "./UI/TerminalStatus";
import { TextField2, SelectField } from "components/UI";
import { Button } from "components/UI";
import { Option } from "model";
import set from "lodash/set";
import { TerminalActive } from "./UI/TerminalActive";
import { statusTerminal } from "model/TerminalOrder";
import { TerminalDeletePopUp } from "./UI/TerminalDeletePopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";

interface TerminalsProps {
  terminalId: number;
  businessName: string;
  //
  onClickBusinessName: () => void;
  onRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
    },

    contentContainer: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(40)}px ${reduceResolution(150)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "40px 150px",
      },
    },

    rightContentContainer: {
      width: "45%",
    },

    leftContainer: {
      width: "30%",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(100)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "100px",
      },
    },

    title: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
        lineHeight: "27px"
      },
    },

    typoItems: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
        paddingTop: "20px",
      },
    },

    descriptionModel: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(220)}px`,
        paddingTop: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "6px",
        width: "220px",
      },
    },

    boxItem: {
      borderBottom: "1px solid #F1F1F1",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px ${reduceResolution(0)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 0px",
      },
    },

    boxItemOptions: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "14px",
      },
    },

    boxOption: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },

    boxCourier: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },

    terminalName: {
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "20px",
      },
    },

    gridModel: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
        paddingLeft: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
        paddingLeft: "5px",
      },
    },

    typoType: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "6px",
      },
    },

    mapIcon: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(8)}px`,
        fontSize: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "8px",
        fontSize: "22px",
      },
    },

    location: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    }
  })
);

export const Terminals: FunctionComponent<TerminalsProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const terminalCommonClasses = useTerminalCommonStyles();
  const masterData = useMasterData();

  const modelsTerminal: Array<TerminalModel> = masterData.terminalsModels;
  const terminal = useSelector<RootState, Terminal | undefined>(state => state.orders.terminal);

  const [businessName] = useState(props.businessName);
  const [terminalModelOption, setTerminalModelOption] = useState<Option<string | number>>();
  const [terminalStatusOption, setTerminalStatusOption] = useState<Option<string | number>>();
  const [terminalSelected, setTerminalSelected] = useState<Terminal>();
  const [model, setModel] = useState<TerminalModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const orderStatus = useSelector<RootState, OrdersStatus>(state => state.orders.status);

  useEffect(() => {
    dispatch(getTerminalThunk(props.terminalId));
  }, []);

  useEffect(() => {
    if (terminal) {
      // set Terminal
      setTerminalSelected(terminal);

      // set Model
      let mod = modelsTerminal.find((model) => model.id.toUpperCase() === terminal?.terminalModelId.toUpperCase())
      if (mod) {
        setTerminalModelOption({ id: mod.id, name: mod.name });
        setModel(mod);
      };
      // set Model

      // set Status
      const statuses = masterData.terminalStatuses!.find((t) => t.id === terminal.status);
      if (statuses) setTerminalStatusOption({ id: statuses.id, name: statuses.name });
    }
  }, [terminal]);

  useEffect(() => {
    if (orderStatus === OrdersStatus.SaveTerminalSuccess) {
      setLoading(false);
      props.onRefresh();
    }
  }, [orderStatus])
  //
  const changeHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedTerminal: Terminal = terminalSelected!;
      set(updatedTerminal, fieldName, value);
      setTerminalSelected(updatedTerminal);

      return updatedTerminal;
    }
    else {
      return terminalSelected;
    }
  };

  const updateStatusHandler = (terminalId: number, status: string) => {
    changeHandler(status, "status");
    dispatch(changeTerminalStatusThunk(terminalId, status));
  };

  const changeStatusHandler = (option: Option<string | number>) => {
    setTerminalStatusOption(option);
    changeHandler(option.id.toString(), "status");
  };

  const changeModelHandler = (option: Option<string | number>) => {
    setTerminalModelOption(option);

    // set Model
    let mod = modelsTerminal.find((model) => model.id.toUpperCase() === terminal?.terminalModelId.toUpperCase())
    setModel(mod);
    //

    changeHandler(option.id.toString(), "terminalModelId");
  };


  const deleteHandler = (terminalId: number) => {
    setShowDelete(false);
    updateStatusHandler(terminalId, statusTerminal.DELETED);
  };

  const showDeleteHandler = (terminalId: number) => {
    setShowDelete(true);
  };

  const activeHandler = (terminalId: number, active: boolean) => {
    changeHandler(active, "active");
    dispatch(activeTerminalStatusThunk(terminalId, active))
  };

  const saveTerminalHandler = () => {
    setLoading(true);
    dispatch(saveTerminalStatusThunk(terminalSelected!));
  };

  const closeDeleteHandler = () => {
    setShowDelete(false);
  };
  //

  const header = (
    <HeaderComponent
      terminal={terminalSelected!}
      businessName={businessName}
      //
      onDelete={showDeleteHandler}
      onActive={activeHandler}
      onClickBusinessName={props.onClickBusinessName}
    />
  );

  const deletePopUp = (
    <TerminalDeletePopUp
      terminal={terminalSelected!}
      //
      onClose={closeDeleteHandler}
      onConfirmDelete={deleteHandler}
    />
  )
  const itemView = (
    terminalSelected && model &&
    <Box className={classes.boxItem}>
      <Grid container>
        <Grid item xs={2}>
          <TerminalImage id={terminalSelected.terminalModelId} />
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={7} className={classes.gridModel}>
          <Typography className={clsx(terminalCommonClasses.typo15_500, terminalCommonClasses.typo_orange, classes.typoType)}>{model.type}</Typography>
          <Typography className={terminalCommonClasses.typo16_600}>
            {terminalSelected.terminalModelId.search('Cuddles') > 0 ? "" : "Cuddles "}
            {terminalSelected.terminalModelId.toUpperCase()}
          </Typography>
          <Typography className={clsx(terminalCommonClasses.typo15_400, classes.descriptionModel)}>
            {model.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const itemOptionsView = (
    terminal &&
    <Box className={classes.boxItemOptions}>
      <Box className={classes.boxOption}>
        <Typography className={clsx(terminalCommonClasses.typo12_400_title, terminalCommonClasses.typo_grey)}>Location</Typography>
        <Typography className={clsx(terminalCommonClasses.typo16_400, classes.location)}>
          <FontAwesomeIcon
            className={classes.mapIcon}
            icon={faMapMarkerAlt}
            size="2x"
          />
          {terminal.address ? terminal.address.city : ""}
        </Typography>
      </Box>
      <Box className={classes.boxOption}>
        <Typography className={clsx(terminalCommonClasses.typo12_400_title, terminalCommonClasses.typo_grey)}>Status</Typography>
        <Typography><TerminalStatus terminalStatus={terminal.status} /></Typography>
      </Box>
      <Box>
        <Typography className={clsx(terminalCommonClasses.typo12_400_title, terminalCommonClasses.typo_grey)}>Active</Typography>
        <Typography className={clsx(terminalCommonClasses.typo16_400)}><TerminalActive terminalStatus={terminal.active} /></Typography>
      </Box>
    </Box>
  );

  const leftContainerView = (
    terminalSelected &&
    <>
      <Box>{itemView}</Box>
      <Box>{itemOptionsView}</Box>
    </>
  );

  const rightContainerView = (
    terminalSelected &&
    <>
      <Box className={clsx(classes.boxCourier, terminalCommonClasses.flexRow)}>
        <TextField2
          placeholder={"Enter terminal name"}
          label="Terminal Name"
          name="name"
          value={terminalSelected.name}
          onChange={changeHandler}
          required={true}
          className={classes.terminalName}
        />
        <SelectField
          placeholder={"Select model"}
          label="Model"
          options={modelsTerminal.map((model) => ({ id: model.id, name: model.name }))}
          selectedOption={terminalModelOption!}
          //
          onSelect={changeModelHandler}
        />
      </Box>
      <Box className={classes.boxCourier}>
        <TextField2
          placeholder={"Enter a serial number"}
          label="Serial"
          name="serial"
          value={terminalSelected.serial}
          onChange={changeHandler}
          required={true}
        />
      </Box>
      <Box className={classes.boxCourier}>
        <SelectField
          placeholder={"Enter a status terminal"}
          label="Status"
          options={masterData.terminalStatuses.filter((status) => status.id !== statusTerminal.DELETED).map((status) => ({ id: status.id, name: status.name }))}
          selectedOption={terminalStatusOption!}
          //
          onSelect={changeStatusHandler}
        />
      </Box>
      <Box>
        <Button
          label="Save"
          variant="primary"
          color="green"
          onClick={saveTerminalHandler}
          loading={loading}
        />
      </Box>
    </>
  );
  //

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        {terminalSelected && header}
        {
          <Box className={classes.contentContainer}>
            <Box className={classes.leftContainer}>
              {leftContainerView}
            </Box>
            <Box className={classes.rightContentContainer}>
              {rightContainerView}
            </Box>
          </Box>
        }
      </OverlayScrollbarsComponent>

      {showDelete && deletePopUp}
    </Box>
  );
};

export default Terminals;
