import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import SelectionBarItem from "./SelectionBarItem";
import { wbp, reduceResolution } from "Theme";

interface Props {
  options: Array<Option<string>>;
  selectedId: string;
  onSelect: (id: string) => void;
  selectedTotalRecords?: number;
  background?: string;
  borderColor?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: Props) => ({
      display: "flex",
      background: props.background || "#F8F8F8",
      border: props.borderColor ? `1px solid ${props.borderColor}` : "none",
      height: "fit-content",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(100)}px`,
        paddingLeft: `${reduceResolution(8)}px`,
        paddingRight:`${reduceResolution(8)}px`,
        paddingTop: `${reduceResolution(5)}px`,
        paddingBottom: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "100px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    }),
    button: {
      marginRight: "14px",
      marginLeft: "14px",
    },
    firstButton: {
      marginLeft: "0px",
    },
    lastButton: {
      marginRight: "0px",
    },
  })
);

export const SelectionBar: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [selectedId, setSelectedId] = useState(props.selectedId);

  useEffect(() => {
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  const clickHandler = (id: string) => {
    setSelectedId(id);
    props.onSelect(id);
  };

  const buttons = props.options.map((option, index) => {
    const buttonStyle = clsx(classes.button, {
      [classes.firstButton]: index === 0,
      [classes.lastButton]: props.options.length === 1 || index + 1 === props.options.length,
    });

    return (
      <Fragment key={option.id}>
        <Box>
          <SelectionBarItem
            label={
              option.id === selectedId && props.selectedTotalRecords !== undefined
                ? `${option.name} (${props.selectedTotalRecords})`
                : option.name
            }
            selected={option.id === selectedId}
            onClick={() => {
              clickHandler(option.id);
            }}
            className={buttonStyle}
          />
        </Box>
      </Fragment>
    );
  });

  return(
  <Box className={clsx(classes.container, props.className)}>{buttons}</Box>
  )
};

export default SelectionBar;
