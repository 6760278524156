import React, { FunctionComponent, useEffect } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { BusinessIssue } from "model/Business";
import AlertRow from "./AlertRow";

interface Props {
  issues: Array<BusinessIssue>;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.issues.map((issue, index) => (
        <AlertRow 
          key={index} 
          issue={issue} 
        /> 
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
