import React, { FunctionComponent } from "react";
import { useMasterData } from "hooks";
import Select from "./Select";
import { Option } from "@spike/model";

export interface Props {
  label?: string;
  selected?: Option<number>;
  onChange: (value?: Option<number>) => void;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  className?: string;
}

export const SelectBusinessAccess: FunctionComponent<Props> = (props) => {
  const masterData = useMasterData();

  const changeHandler = (value: string | number) => {
    props.onChange(masterData?.accessLevels.find((a) => a.id === value));
  };

  return (
    <Select
      {...props}
      value={props.selected?.id}
      options={masterData?.accessLevels!}
      onChange={changeHandler}
    />
  );
};

export default SelectBusinessAccess;
