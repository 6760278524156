import React, { FunctionComponent } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import TableOrderRow from "./TableOrderRow";
import { Order } from "model/TerminalOrder";

interface Props {
  orders: Array<Order>;
  //
  onEditOrder: (order: Order) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.orders.map((order, index) => (
        <TableOrderRow
          key={index}
          order={order}
          //
          onEditOrder={props.onEditOrder}
        />
      ))}
    </TableBodyMUI>
  );
};

export default TableBody;
