import React, { FunctionComponent } from "react";
import { Header } from "components/UI";
interface Props {
  title?: string;
  searchPlaceholder?: string;
  className?: string;
  loading?: boolean;
  //
  onClickButton?: () => void;
  onSearch?: (text: string) => void;
  onBack?: () => void;
}

export const HeaderComponent: FunctionComponent<Props> = (props) => {
  return (
    <Header {...props} searchPlaceholder={props.searchPlaceholder} />
  );
};

export default HeaderComponent;
