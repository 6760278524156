import { Action } from "redux";
import { Masterdata } from "model/Masterdata";

//Types
export const MASTERDATA_RESET_ACTION_TYPE = "MASTERDATA_RESET";
export const MASTERDATA_GET_START_ACTION_TYPE = "MASTERDATA_GET_START";
export const MASTERDATA_GET_SUCCESS_ACTION_TYPE = "MASTERDATA_GET_SUCCESS";
export const MASTERDATA_ERROR_ACTION_TYPE = "MASTERDATA_ERROR";

//Actions
interface MasterdataResetAction extends Action<typeof MASTERDATA_RESET_ACTION_TYPE> {}

interface MasterdataGetStartAction extends Action<typeof MASTERDATA_GET_START_ACTION_TYPE> {}

interface MasterdataGetSuccessAction extends Action<typeof MASTERDATA_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    masterdata: Masterdata;
  }
}

interface MasterdataErrorAction extends Action<typeof MASTERDATA_ERROR_ACTION_TYPE> {}

//BusinessAction
export type MasterdataAction =
  | MasterdataResetAction
  | MasterdataGetStartAction
  | MasterdataGetSuccessAction
  | MasterdataErrorAction;
