import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import store from "store";

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
}

const ApiClient: AxiosInstance = axios.create(config);

export default ApiClient;

export const createTokenConfig = ():AxiosRequestConfig => {
  const token = store.getState().login.auth.token;
  if(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  } else {
    return {};
  }
}
