import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { SortFields, SortOrder } from "model/Business";
import { Terminal } from "model/TerminalOrder";
import TableHeader from "./TableTerminal/TableHeader";
import TableBody from "./TableTerminal/TableBody";
import { wbp, reduceResolution } from "Theme";
import { Table } from "components/UI";
import { FilterHeader } from "./TableTerminal/FilterHeader";
interface BusinessTerminalProps {
  terminal: Array<Terminal>;
  marketplaceId: number;
  //
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
  onShow: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },

    boxContentFilter: {
      background: "#F1F1F1",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(12)}px`,
        margin: `${reduceResolution(12)}px ${reduceResolution(84)}px`,
        padding: `${reduceResolution(12)}px ${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "12px",
        margin: "12px",
        padding: "12px 20px",
      },
    },

    boxSearch: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(500)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "500px",
      },
    },

    searchBox: {
    },

    boxFilter: {
      display: "flex",
    }
  })
);

export const BusinessTerminal: FunctionComponent<BusinessTerminalProps> = (props) => {
  const classes = useStyles();

  const [filterTerminals, setFilterTerminals] = useState<Array<Terminal>>(props.terminal);

  const filterHandler = (terminals: Array<Terminal>) => {
    setFilterTerminals(terminals);
  };

  const filterView = (
    <FilterHeader
      terminals={filterTerminals}
      //
      onFilter={filterHandler}
    />
  )
  const businessTerminalComponent = (
    <Table
      head={<TableHeader onSort={props.onSort} />}
      body={<TableBody
        terminal={filterTerminals}
        marketplaceId={props.marketplaceId}
        onShow={props.onShow} />}
    />
  );

  return (
    <Box className={classes.container}>
      {filterView}
      {businessTerminalComponent}
    </Box>
  );
};

export default BusinessTerminal;
