import { OrderDto, PaymentDto, RefundDto, TerminalDto } from "./OrdersDtos";
import { marketplacesOrders, Order, Payment, Refund, Terminal } from "model/TerminalOrder";

export const convertOrders = (orders: Array<OrderDto>): marketplacesOrders => {
  return {
    orders: orders.map((order) => convertToOrder(order))
  };
};

export const convertToOrder = (orderDto: OrderDto): Order => {
  return {
    active: orderDto.active,
    adminPayments: orderDto.admin_payments ? orderDto.admin_payments.map((payment) => convertToPayment(payment)) : [],
    courier: orderDto.courier,
    createAt: orderDto.created_at,
    deliveryAddress: orderDto.delivery_address,
    deliveryCity: orderDto.delivery_city,
    deliveryCountry: orderDto.delivery_country,
    deliveryName: orderDto.delivery_name,
    deliveryPhone: orderDto.delivery_phone,
    deliveryState: orderDto.delivery_state,
    deliverySuite: orderDto.delivery_suite,
    deliveryZipcode: orderDto.delivery_zipcode,
    id: orderDto.id,
    number: orderDto.number,
    marketplace: orderDto.marketplace,
    marketplaceId: orderDto.marketplace_id,
    notes: orderDto.notes,
    price: orderDto.price,
    status: orderDto.status,
    terminals: orderDto.terminals.map((terminal) => convertToTerminal(terminal)),
    trackingNumber: orderDto.tracking_number,
    uuid: orderDto.uuid,
  };
};

export const convertToPayment = (paymentDto: PaymentDto): Payment => {
  return {
    id: paymentDto.id,
    cardDigits: paymentDto.card_digits,
    clientName: paymentDto.client_name,
    paymentMethodId: paymentDto.payment_method_id,
    adminRefunds: paymentDto.admin_refunds ? paymentDto.admin_refunds.map((refund) => convertRefund(refund)) : [],
  }
};

export const convertToTerminal = (terminalDto: TerminalDto): Terminal => {
  return {
    active: terminalDto.active,
    address: terminalDto.address,
    addressId: terminalDto.address_id,
    businessProfileId: terminalDto.business_profile_id,
    createdAt: terminalDto.created_at,
    id: terminalDto.id,
    marketplaceId: terminalDto.marketplace_id,
    model: terminalDto.model,
    name: terminalDto.name,
    poi: terminalDto.poi,
    serial: terminalDto.serial,
    status: terminalDto.status,
    terminalModelId: terminalDto.terminal_model_id,
    terminalOrderId: terminalDto.terminal_order_id,
    updatedAt: terminalDto.updated_at,
    uuid: terminalDto.uuid
  };
};

export const convertRefund = (refundDto: RefundDto): Refund => {
  return {
    adminInvoiceId: refundDto.admin_invoice_id,
    adminPaymentId: refundDto.admin_payment_id,
    amount: refundDto.amount,
    createAt: refundDto.create_at,
    id: refundDto.id,
    pspReference: refundDto.psp_reference,
    reason: refundDto.reason,
    statusId: refundDto.status_id,
    updateAt: refundDto.update_at,
    uuid: refundDto.uuid,
  }
};
