import { ClientDto, ClientsOverviewDto } from "./ClientsDtos";
import { Client, ClientsOverview } from "model/Client";
import { store } from "store";

export const convertClient = (dto: ClientDto): Client => {
  const businessAreas = store.getState().masterdata.data!.businessAreas || [];

  return {
    id: dto.id,
    name: dto.business_name,
    city: "Miami",
    categories: dto.categories.map(catId => businessAreas.find(ba => ba.id === catId)!),
    locationsQty: dto.locations,
    usersQty: dto.staff,
    terminals: dto.terminals,
    status: dto.status ? { id: 1, name: "Active" } : { id: 2, name: "Inactive" },
  };
};

export const convertOverview = (dto: ClientsOverviewDto): ClientsOverview => {
  return {
    totalBusinesses: dto.marketplaces,
    totalUsers: dto.staff,
    totalRevenue: dto.revenue,
  };
};
