import React, { FunctionComponent } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import RightHeader from "./RightHeader";
import LeftHeader from "./LeftHeader";
import { Business } from "model/Business";

interface Props {
  business?: Business;
  loadingStaff: boolean;
  loadingIssues: boolean;
  gettingBusiness: boolean;
  issues: number;
  users: number;
  terminals: number;
  selectedItemId: string;
  className?: string;
  onSelect: (selected: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "40px",
        paddingLeft: "40px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "65px",
        paddingLeft: "65px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "87px",
        paddingLeft: "87px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "132px",
        paddingLeft: "132px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    leftHeaderContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingRight: "20px",
      height: "100%",
    },
  })
);

export const HeaderComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const leftHeader = (
    <LeftHeader
      business={props.business}
    />
  );

  const rightHeader = (
    <RightHeader
      issues={props.issues}
      users={props.users}
      terminals={props.terminals}
      loadingIssues={props.loadingIssues}
      loadingStaff={props.loadingStaff}
      gettingBusiness={props.gettingBusiness}
      selectedItemId={props.selectedItemId}
      onSelect={props.onSelect}
    />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.headerContainer}>
        <Grid container>
          <Grid item xs={8}>
            <Box className={classes.leftHeaderContainer}>
              {leftHeader}
            </Box>
          </Grid>
          <Grid item xs={4}>
            {rightHeader}
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
};

export default HeaderComponent;
