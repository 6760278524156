import { Box, InputAdornment, InputBaseComponentProps, TextField as MUITextField } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { useNonInitialEffect } from "@versiondos/hooks";
import clsx from "clsx";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import upperCase from "lodash/upperCase";
import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from "react";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";

export interface TextField2Props {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: string;
  required?: boolean;
  errors?: Array<FieldError>;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
  disabled?: boolean;
  capitalize?: boolean;
  upperCase?: boolean;
  maxLength?: number;
  //
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onEnterPressed?: () => void;
  onFocus?: () => void;
}

export const TextField2: FunctionComponent<TextField2Props> = (props) => {
  const classes = useFieldStyles();

  const [text, setText] = useState(props.value);

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    if (props.capitalize) {
      setText(startCase(camelCase(newValue)));
    } else if (props.upperCase) {
      setText(upperCase(newValue));
    } else {
      setText(newValue);
    }
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  let inputProps = {};

  if (props.endIcon) {
    inputProps = { ...inputProps, endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment> };
  }

  if (props.startIcon) {
    inputProps = { ...inputProps, startAdornment: <InputAdornment position="start">{props.startIcon}</InputAdornment> };
  }

  let properties: InputBaseComponentProps | undefined = undefined;
  if (props.capitalize) {
    properties = { style: { textTransform: "capitalize" } };
  } else if (props.upperCase) {
    properties = { style: { textTransform: "uppercase" } };
  }

  if (props.maxLength) {
    properties = { ...properties, maxLength: props.maxLength };
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      <Box>
        <MUITextField
          name={props.name}
          placeholder={props.placeholder}
          variant="outlined"
          defaultValue={props.value}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(classes.field, {
            [classes.fieldWithStartAdornment]: props.startIcon,
            [classes.fieldWithEndAdornment]: props.endIcon,
            [classes.emptyField]: text.length === 0,
            [classes.disabledField]: props.disabled,
            [classes.fieldError]: hasError,
          })}
          error={hasError}
          InputProps={inputProps}
          multiline={props.multiline}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
          inputProps={properties}
        />
      </Box>
    </Box>
  );
};

export default TextField2;
