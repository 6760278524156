import React, { FunctionComponent } from "react";
import { Button as MUIButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";
import {useHistory} from 'react-router-dom';

export interface Props {
  label?: string
  size?: "small" | "medium" | "large";
  color?: "orange" | "green" | "black";
  onClick?: (event: any) => void;
  linkTo?: string; 
  disabled?: boolean;
  startIcon?: IconProp;
  endIcon?: IconProp;
  underline?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "9px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "11px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "14px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    medium: {
     "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "8px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
          lineHeight: "27px",
        },
      },
    },
    small: {
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "7px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "9px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "11px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "14px",
          lineHeight: "27px",
        },
      },
    },
    underline: {
      "& .MuiButton-label": {
        textDecoration: "underline",
      },
    },
    largeIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "7px",
        height: "7px",
      },
      [theme.breakpoints.only("md")]: {
        width: "12px",
        height: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "24px",
        height: "24px",
      },
    },
    mediumIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "5px",
        height: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "8px",
        height: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "11px",
        height: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "16px",
        height: "16px",
      },
    },
    smallIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "5px",
        height: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "8px",
        height: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "11px",
        height: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "16px",
        height: "16px",
      },
    },
    orange: {
      color: "#EAB464",
      "&:hover": {
        color: "#C18733",
      },
      "&.Mui-disabled": {
        color: "rgba(255,255,255,0)"
      },
    },
   green: {
      color: "#92B4A7",
      "&:hover": {
        color: "#5E8677",
      },
      "&.Mui-disabled": {
        color: "rgba(255,255,255,0)"
      },
    },
    black: {
      color: "#222222, 100%",
      "&:hover": {
        color: "#EAB464",
      },
      "&.Mui-disabled": {
        color: "rgba(255,255,255,0)"
      },
    },
  })
);

export const Link: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const history = useHistory();

  const clickHandler = (event: any) => {
    if(props.linkTo) {
      history.push(props.linkTo);
    } else {
      props.onClick && !props.disabled && props.onClick(event);
    }
  };

  const startIconElement = props.startIcon && (
    <FontAwesomeIcon
      icon={props.startIcon!}
      className={clsx({
        [classes.largeIcon]: props.size === undefined || props.size === "large",
        [classes.mediumIcon]: props.size === "medium",
        [classes.smallIcon]: props.size === "small",
      })}
    />
  );

  const endIconElement = props.endIcon && (
    <FontAwesomeIcon
      icon={props.endIcon!}
      className={clsx({
        [classes.largeIcon]: props.size === undefined || props.size === "large",
        [classes.mediumIcon]: props.size === "medium",
        [classes.smallIcon]: props.size === "small",
      })}
    />
  );

  return (
    <MUIButton
      variant="text"
      disableElevation={true}
      disableFocusRipple={true}
      disableRipple={true}
      disabled={props.disabled}
      disableTouchRipple={true}
      onClick={clickHandler}
      startIcon={startIconElement}
      endIcon={endIconElement}
      className={clsx(
        {
          [classes.large]: props.size === undefined || props.size === "large",
          [classes.medium]: props.size === "medium",
          [classes.small]: props.size === "small",
          [classes.orange]: (props.color === undefined || props.color === "orange"),
          [classes.green]: props.color === "green",
          [classes.black]: props.color === "black",
          [classes.underline]: props.underline,
        },
        props.className
      )}
    >
      {props.label}
    </MUIButton>
  );
};

export default Link;
