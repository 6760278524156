import React, { FunctionComponent, useEffect } from "react";
import { Box, TableBody as TableBodyMUI } from "@material-ui/core";
import { Client } from "model/Client";
import NewBusinessRow from "./NewBusinessRow";

interface Props {
  clients: Array<Client>;
  clientSelected?: number;
  loadingAddingBusiness: boolean;
  onAdd: (client: Client) => void;  
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.clients.map((client, index) => (
        <NewBusinessRow 
          key={index} 
          clientSelected={props.clientSelected}
          client={client} 
          onAdd={props.onAdd}
          loadingAddingBusiness={props.loadingAddingBusiness}
        /> 
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
