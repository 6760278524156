import React, { FunctionComponent } from "react";
import { Button as MUIButton, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import clsx from "clsx";

export interface Props {
  label?: string;
  selected?: boolean;
  type?: string;
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  onClick?: (event: any) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "11px",
        paddingRight: "11px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "18px",
        paddingRight: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "9px",
        paddingBottom: "9px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "14px",
        paddingBottom: "14px",
        paddingLeft: "36px",
        paddingRight: "36px",
      },
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "9px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "11px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "14px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    medium: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingLeft: "7px",
        paddingRight: "7px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "8px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
          lineHeight: "27px",
        },
      },
    },
    small: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "2px",
        paddingBottom: "2px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "11px",
        paddingRight: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "6px",
        paddingBottom: "6px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "7px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "9px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "11px",
          lineHeight: "22px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "14px",
          lineHeight: "27px",
        },
      },
    },
    black: {
      color: "#FFFFFF",
      backgroundColor: "#222222",
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#D3D3D3",
      },
    },
    secondaryBlack: {
      color: "#222222",
      backgroundColor: "#FFFFFF",
      border: "2px solid #000000",
      "&:active": {
        backgroundColor: "#BCB8AE",
      },
      "&.Mui-disabled": {
        color: "#DBDAD7",
        backgroundColor: "#FFFFFF",
        border: "2px solid #DBDAD7",
      },
    },
    hoverDanger: {
      "&:hover": {
        color: "#CC444B",
        borderColor: "#CC444B",
      },
    },
    hoverSuccess: {
      "&:hover": {
        color: "#51A372",
        borderColor: "#51A372",
      },
    },
    danger: {
      color: "#CC444B",
      borderColor: "#CC444B",
    },
    success: {
      color: "#51A372",
      borderColor: "#51A372",
    },
    blackLoading: {
      backgroundColor: "#000000",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#000000",
      },
    },
    secondaryBlackLoading: {
      backgroundColor: "#BCB8AE",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#BCB8AE",
      },
    },
    dangerLoading: {
      backgroundColor: "#CC444B",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#CC444B",
      },
    },
    successLoading: {
      backgroundColor: "#51A372",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#51A372",
      },
    },
    spinnerContainer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      width: "100%",
      height: "100%",
    },
  })
);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const ButtonSuspend: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const clickHandler = (event: any) => {
    props.onClick && !props.loading && !props.disabled && props.onClick(event);
  };

  const spinner = (
    <Box className={classes.spinnerContainer}>
      <PulseLoader
        color={"black"}
        loading={props.loading}
        css={override}
        size={5}
      />
    </Box>
  );

  return (
    <MUIButton
      variant={props.variant === "secondary" ? "outlined" : "contained"}
      disableElevation={true}
      disableFocusRipple={true}
      disableRipple={true}
      disabled={props.disabled}
      disableTouchRipple={true}
      onClick={clickHandler}
      className={clsx(
        {
          [classes.large]: props.size === undefined || props.size === "large",
          [classes.medium]: props.size === "medium",
          [classes.small]: props.size === "small",
          [classes.black]: (props.variant === undefined || props.variant === "primary"),
          [classes.danger]: props.type === "suspend" && props.selected,
          [classes.success]: props.type === "activate" && props.selected,
          [classes.secondaryBlack]: props.variant === "secondary",
          [classes.blackLoading]:
            props.loading && (props.variant === undefined || props.variant === "primary"),
          [classes.secondaryBlackLoading]: props.loading && props.variant === "secondary",
          [classes.dangerLoading]: props.loading && props.type === "suspend",
          [classes.successLoading]: props.loading && props.type === "activate",
          [classes.hoverDanger]: props.type === "suspend",
          [classes.hoverSuccess]: props.type === "activate"
        },
        props.className
      )}
    >
      {props.label}
      {props.loading && spinner}
    </MUIButton>
  );
};

export default ButtonSuspend;
