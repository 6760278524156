import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import { FieldError } from "@spike/model";
import NumberField from "./NumberField";
import { useFieldStyles } from "./FieldStyles";

export interface PriceFieldProps {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: number | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  disabled?: boolean;
  scale?: number;
  precision?: number;
  //
  onEnterPressed?: () => void;
  onBlur?: () => void;
  onChange: (value: number | undefined, name?: any) => void;
  onFocus?: () => void;
}

export const PriceField: FunctionComponent<PriceFieldProps> = (props) => {

  const classes = useFieldStyles();

  return (
    <NumberField
      {...props}
      startIcon={<Typography className={classes.adornmentText}>$</Typography>}
    />
  );
};

export default PriceField;
