import React, { FunctionComponent, MouseEvent, useState } from "react";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, createStyles, makeStyles, Theme, ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";

export interface Props {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerDropDown: {
      display: "flex",
      position: "relative",
      width: "30px",
      justifyContent: "center",
    },
    dropdown: {
      display: "flex",
      position: "absolute",
      width: "auto",
      right: "0px",
      zIndex: 10,
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: "1px",
      [theme.breakpoints.only("xs")]: {
        marginTop: "35px",
        borderRadius: "8px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "35px",
        borderRadius: "8px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "35px",
        borderRadius: "9px",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "35px",
        borderRadius: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "40px",
        borderRadius: "15px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
    dropdownItemsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    icon: {
      cursor: "pointer"
    }
  })
);

const ToolbarButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [show, setShow] = useState(false);

  const clickToolbarButtonHandler = (event: MouseEvent): void => {
    setShow((prev) => { return !prev });
  };

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <Box className={clsx(classes.containerDropDown, props.className)} onClick={clickToolbarButtonHandler}>
        <Box className={classes.icon}>
          {show ? (
            <FontAwesomeIcon color="#92B4A7" icon={faEllipsisV} />
          ) : (
              <FontAwesomeIcon color="#000000" icon={faEllipsisH} />
            )}
        </Box>
        {show && (
          <ClickAwayListener onClickAway={() => setShow(false)}>
            <Box className={classes.dropdown}>
              <Box className={classes.dropdownItemsContainer}>{props.children}</Box>
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ToolbarButton;
