import React, { FunctionComponent, useState, useEffect } from "react";
import { Checkbox as MUICheckbox, Typography, Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export interface Props {
  label?: string;
  checked: boolean;
  fontColor?: string;
  onChange: (checked: boolean) => void;
  className?: string;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props) => ({
      display: "flex",
      justifyContent:
        props.label === undefined || props.label.length === 0
          ? "center"
          : "flex-start",
    }),
    form: {
      marginLeft: 0,
      marginRight: 0,
    },
    noneLabel: {
      marginLeft: 0,
      marginRight: 0,
    },
    label: (props: Props) => ({
      fontColor: props.fontColor,
      whiteSpace: "nowrap",
      [theme.breakpoints.only("xs")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    }),
  })
);

export const Checkbox: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const label = (
    <Typography className={classes.label}>{props.label || ""}</Typography>
  );

  const clickHandler = (): void => {
    props.onChange(!checked);
  };

  return (
    <Box className={classes.container}>
      <FormControlLabel
        value="top"
        control={
          <MUICheckbox
            checked={checked}
            color="default"
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
            onChange={clickHandler}
            icon={props.icon}
            checkedIcon={props.checkedIcon}
            disabled={props.disabled}
          />
        }
        label={label}
        labelPlacement="end"
        className={clsx(classes.form, props.className)}
      />
    </Box>
  );
};

export default Checkbox;
