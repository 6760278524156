import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box, Typography,
} from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SortFields, SortOrder, User, UserBusiness } from "model/User";
import { changeStatusThunk, addBusinessThunk, getThunk, removeBusinessThunk, resetPasswordThunk, checkAppointmentsThunk, updateUserThunk } from "actions/users/UsersActions";
import HeaderComponent from "./UI/HeaderComponent";
import UserComponent from "./UI/UserComponent";
import { Table, TableMessage } from "components/UI";
import TableBody from "./UI/TableUsers/TableBody";
import TableHeader from "./UI/TableUsers/TableHeader";
import { Option } from "model";
import NewBusiness from "./UI/NewBusiness";
import { Client } from "model/Client";
import ConfirmDialog from "./UI/ConfirmDialog";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { impersonateThunk } from "actions/impersonation/ImpersonationActions";
import clsx from "clsx";
import { Img } from "react-image";
import { notifyUserActivatedThunk, notifyUserSuspendedThunk } from "actions/notifications/NotificationsActions";
import { UsersStatus } from "reducers/users/UsersState";
import { fetchUsersThunk } from "actions/businesses/BusinessesActions";


interface Props {
  staffId: number;
  businessName: string;
  businessId?: number;
  onClickBusinessName: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    contentContainer: {
      display: "flex",
    },
    rightContentContainer: {
      width: "100%",
      borderLeft: "1px solid #F1F1F1"
    },
    userContainer: {
      width: "25%",
      height: "100%"
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "8px"
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "13px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "27px"
      },
    },
    iconRemoveContainer: {
      justifyContent: "start",
    },
    icon: {
      color: "#5E8677",
      [theme.breakpoints.down("sm")]: {
        width: "11px !important",
        height: "11px",
      },
      [theme.breakpoints.only("md")]: {
        width: "18px !important",
        height: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "24px !important",
        height: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "36px !important",
        height: "36px",
      },
    },
    iconCalendar: {
      [theme.breakpoints.down("sm")]: {
        width: "15px !important",
        height: "14px",
      },
      [theme.breakpoints.only("md")]: {
        width: "25px !important",
        height: "23px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "33px !important",
        height: "31px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "50px !important",
        height: "47px",
      },
    },
    iconSuspendActivate: {
      [theme.breakpoints.down("sm")]: {
        width: "14px !important",
        height: "12px",
      },
      [theme.breakpoints.only("md")]: {
        width: "23px !important",
        height: "19px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "31px !important",
        height: "26px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "47px !important",
        height: "39px",
      },
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
        lineHeight: "8px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        lineHeight: "13px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        lineHeight: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "20px",
        lineHeight: "27px"
      },
    },
    titleRemoveSuspendActivate: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px",
        lineHeight: "6px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "9px",
        lineHeight: "10px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "11px",
        lineHeight: "13px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "16px",
        lineHeight: "20px"
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column"
    },
    space: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "8px"
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "13px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "27px"
      },
    }
  })
);

export const Users: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const userStatus = useSelector<RootState, UsersStatus>(state => state.users.status);

  const ADD_BOOKING_SUCCESS = 8;
  const GROOMER = 4;
  const COLLABORATOR = 2;
  const CHANGING_STATUS = 5;
  const CHANGE_STATUS_SUCCESS = 6;
  const REMOVE_BUSINESS_SUCCESS = 10;
  const CHECK_APPOINTMENTS_SUCCESS = 12;
  const user = useSelector<RootState, User | undefined>(state => state.users.user);
  const loading = useSelector<RootState, boolean>(state => state.users.loading);
  const loadingResetPassword = useSelector<RootState, boolean>(state => state.users.resettingPassword);
  const changeStatus = useSelector<RootState, number>(state => state.users.status);
  const loadingAddingBusiness = useSelector<RootState, boolean>(state => state.users.addingBusiness);
  const loadingRemovingBusiness = useSelector<RootState, boolean>(state => state.users.removingBusiness);
  const statusAddingBusiness = useSelector<RootState, number>(state => state.users.status);
  const activeAppoinments = useSelector<RootState, number | undefined>(state => state.users.appointments?.activeAppointments);
  const [userSelected, setUserSelected] = useState<User | undefined>(user);
  const [workSelected, setWorkSelected] = useState<Number | undefined>(undefined);
  const [userBusinessToUpdate, setUserBusinessToUpdate] = useState<UserBusiness | undefined>();
  const [isEmpty, setIsEmpty] = useState(userSelected?.businesses.length === 0 ? true : false);
  const [showNewBusiness, setShowNewBusiness] = useState(false);
  const [showConfirmNotifyDialog, setShowConfirmNotifyDialog] = useState(false);
  const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false);
  const [showNotifyRemoveDialog, setShowNotifyRemoveDialog] = useState(false);
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [showActivateDialog, setShowActivateDialog] = useState(false);
  const [minHeightRemove, setMinHeightRemove] = useState(false);
  const [clientToAdd, setClientToAdd] = useState<Client | undefined>(undefined);
  const [businessToRemove, setBusinessToRemove] = useState<UserBusiness | undefined>(undefined);
  const [headerNotify, setHeaderNotify] = useState<any>(undefined);
  const [headerRemove, setHeaderRemove] = useState<any>(undefined);
  const [userIdSelected, setUserIdSelected] = useState<number | undefined>(undefined);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    dispatch(getThunk(props.staffId));
  }, []);

  useEffect(() => {
    if (user) {
      setUserSelected(user);
      if (workSelected) {
        const userBusinessesAux = user.businesses.filter((business) => business.workId === workSelected);
        const userBusinessAux = userBusinessesAux && userBusinessesAux[0];
        if (userBusinessAux && userBusinessAux) {
          setUserBusinessToUpdate(userBusinessAux);
        } else {
          const userBusinessAux2 = user.businesses && user.businesses[0];
          setUserBusinessToUpdate(userBusinessAux2);
        }
      }
    }
    if (user?.businesses) {
      setIsEmpty(false);
    }
  }, [user]);


  useEffect(() => {
    if (userStatus === UsersStatus.UserUpdateSuccess) {
      if (props.businessId) {
        dispatch(fetchUsersThunk(props.businessId));
      }
    }

  }, [userStatus]);

  useEffect(() => {
    if (changeStatus === CHANGE_STATUS_SUCCESS) {
      setShowConfirmNotifyDialog(true);
      setShowConfirmRemoveDialog(false);
      setUserIdSelected(undefined);
      setShowSuspendDialog(false);
      setShowActivateDialog(false);
      setHeaderNotify(<Box><strong>{userSelected?.firstName} {userSelected?.lastName}</strong> was suspended from all business.</Box>);
    }
    if (changeStatus === CHECK_APPOINTMENTS_SUCCESS) {
      if (activeAppoinments && activeAppoinments > 0) {
        setShowConfirmRemoveDialog(true);
        setHeaderRemove(
          <Box className={classes.titleContainer}>
            <Typography className={clsx(classes.titleRemoveSuspendActivate, classes.space)}>
              <Fragment>{userSelected?.firstName} {userSelected?.lastName} has <strong>{activeAppoinments} appointments</strong> assigned at <strong>{businessToRemove?.businessName}.</strong></Fragment>
            </Typography>
            <Typography className={classes.titleRemoveSuspendActivate}>
              If you remove this business, all appointments will remain “unassigned”.
            </Typography>
          </Box>
        );
      } else {
        setShowConfirmRemoveDialog(true);
        setMinHeightRemove(true);
        setHeaderRemove(<Fragment></Fragment>);
      }
    }
    if (changeStatus === REMOVE_BUSINESS_SUCCESS) {
      setShowNotifyRemoveDialog(true);
      setShowConfirmRemoveDialog(false);
      setMinHeightRemove(false);
      setHeaderNotify(<Box><strong>{businessToRemove?.businessName}</strong> was remove from {userSelected?.firstName} {userSelected?.lastName}.</Box>);
    }
  }, [changeStatus]);

  useEffect(() => {
    if (statusAddingBusiness === ADD_BOOKING_SUCCESS) {
      setShowNewBusiness(false);
      setShowConfirmNotifyDialog(true);
      setHeaderNotify(<Box><strong>{clientToAdd?.name}</strong> was added to <strong>{userSelected?.firstName} {userSelected?.lastName}</strong> profile.</Box>);
    }
  }, [statusAddingBusiness]);

  const sort = (field: SortFields | undefined, order: SortOrder) => {
    if (field !== undefined && userSelected) {
      let userAux = { ...userSelected };
      userAux.businesses.sort((business, otherBusiness) => {
        if (field === "businessName") {
          if (business.businessName.toLowerCase() > otherBusiness.businessName.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (business.businessName.toLowerCase() < otherBusiness.businessName.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else {
          return 1;
        }
      });
      setUserSelected(userAux);
    }
  }

  const handleResetPassword = (userId: number | undefined) => {
    if (userId) {
      dispatch(resetPasswordThunk(userId));
    }
  }

  const handleSave = () => {
    if (userBusinessToUpdate && userSelected && userBusinessToUpdate.role && userBusinessToUpdate.accessLevel) {
      dispatch(updateUserThunk(userBusinessToUpdate.workId, userSelected.staffId, userBusinessToUpdate.role.id, userBusinessToUpdate.accessLevel.id));
    }

  }

  const handleActivateUser = (active: boolean) => {
    if (active) {
      setShowSuspendDialog(true);
    } else {
      setShowActivateDialog(true);
    }
  }

  const handleChangeAccess = (access: Option<number> | undefined, workId: number, currentRole: Option<number> | undefined) => {
    if (access && currentRole) {
      setWorkSelected(workId);
      setUserBusinessToUpdate((prev) => {
        return { ...prev!, accessLevel: access, workId: workId, role: currentRole };
      });
    }
  }

  const handleChangeRole = (role: Option<number> | undefined, workId: number, currentAccess: Option<number> | undefined) => {
    if (role && currentAccess) {
      setWorkSelected(workId);
      setUserBusinessToUpdate((prev) => {
        return { ...prev!, role: role, workId: workId, accessLevel: currentAccess };
      });
    }
  }

  const handleAddClient = (client: Client) => {
    dispatch(addBusinessThunk(userSelected!.staffId, client.id, GROOMER, COLLABORATOR));
    setClientToAdd(client);
  };

  const handleRemoveBusiness = (business: UserBusiness) => {
    dispatch(checkAppointmentsThunk(userSelected?.staffId!, business.businessId));
    setBusinessToRemove(business);
  };

  const handleNotify = (userName: boolean, businessOwner: boolean) => {
    setShowConfirmNotifyDialog(false);
    setShowNotifyRemoveDialog(false);
  };

  const handleNotifyActivateSuspend = (userName: boolean, businessOwner: boolean) => {
    setShowConfirmNotifyDialog(false);
    setShowNotifyRemoveDialog(false);
    if (activated) {
      dispatch(notifyUserSuspendedThunk(userId!, userName, businessOwner));
    } else {
      dispatch(notifyUserActivatedThunk(userId!, userName, businessOwner));
    }
  };


  const handleSkip = () => {
    setShowConfirmNotifyDialog(false);
    setShowNotifyRemoveDialog(false);
  };

  const handleRemove = (staffId: number, business: UserBusiness) => {
    dispatch(removeBusinessThunk(staffId, business));
  };

  const handleRemoveSuspend = (userId: number | undefined) => {
    setUserId(userId);
    setActivated(true);
    dispatch(changeStatusThunk(userId!, false));
  };

  const handleSuspend = (userId: number | undefined, activeAppoinments: number) => {
    if (activeAppoinments > 0) {
      setShowConfirmRemoveDialog(true);
      setHeaderRemove(
        <Box className={classes.titleContainer}>
          <Typography className={clsx(classes.titleRemoveSuspendActivate, classes.space)}>
            <Fragment>{userSelected?.firstName} {userSelected?.lastName} has <strong>{userSelected?.businesses.length} appointments</strong> assigned.</Fragment>
          </Typography>
          <Typography className={classes.titleRemoveSuspendActivate}>
            If you remove this business, all appointments will remain “unassigned”.
          </Typography>
        </Box>
      );
      setUserIdSelected(userId);
    } else {
      setActivated(true);
      dispatch(changeStatusThunk(userId!, false));
    }
  };

  const handleActivate = (userId: number | undefined) => {
    setUserId(userId);
    setActivated(false);
    dispatch(changeStatusThunk(userId!, true));
  };

  const impersonationHandler = (userId: number, businessId: number) => {
    dispatch(impersonateThunk(userId, businessId));
  }

  const handleNoRemoveSuspend = () => {
    setShowSuspendDialog(false);
    setShowConfirmRemoveDialog(false);

  }

  const header = (
    <HeaderComponent
      user={userSelected}
      businessName={props.businessName}
      onAddBusiness={() => setShowNewBusiness(true)}
      onClickBusinessName={props.onClickBusinessName}
    />
  );

  const userComponent = (
    <UserComponent
      loadingResetPassword={loadingResetPassword}
      selected={showConfirmNotifyDialog || showConfirmRemoveDialog || showNotifyRemoveDialog || showSuspendDialog || showActivateDialog}
      user={userSelected}
      onResetPassword={handleResetPassword}
      onActivateUser={handleActivateUser}
      onSave={handleSave}
    />
  );

  const userTable = (
    <Table
      head={<TableHeader onSort={(field, order) => sort(field, order)} />}
      body={<TableBody
        user={userSelected}
        onImpersonate={(businessId) => { impersonationHandler(userSelected!.userId, businessId) }}
        onChangeAccess={handleChangeAccess}
        onChangeRole={handleChangeRole}
        onRemove={handleRemoveBusiness}
      />}
    />
  );

  const newBusiness = (
    <NewBusiness
      user={userSelected}
      client={clientToAdd}
      loadingAddingBusiness={loadingAddingBusiness}
      onClose={() => setShowNewBusiness(false)}
      onAdd={handleAddClient}
    />
  );

  const confirmNotifyDialog = (
    <ConfirmDialog
      type="notify"
      user={userSelected}
      client={clientToAdd}
      onSkip={handleSkip}
      onNotify={handleNotifyActivateSuspend}
    >
      <Box className={classes.iconContainer}>
        <FontAwesomeIcon icon={faCheckCircle} className={classes.icon} />
      </Box>
      <Typography className={classes.title}>
        {headerNotify}
      </Typography>
    </ConfirmDialog>
  );

  const confirmRemoveDialog = (
    <ConfirmDialog
      type="remove"
      user={userSelected}
      userIdSelected={userIdSelected}
      businessToRemove={businessToRemove}
      onNoRemove={() => setShowConfirmRemoveDialog(false)}
      onRemove={(business: UserBusiness) => handleRemove(userSelected!.staffId, business)}
      onRemoveSuspend={handleRemoveSuspend}
      onNoRemoveSuspend={handleNoRemoveSuspend}
      loadingRemovingBusiness={loadingRemovingBusiness}
      minHeightRemove={minHeightRemove}
    >
      <Box className={clsx(classes.iconContainer, classes.iconRemoveContainer)}>
        <Img src="/images/icons/calendar.svg" className={classes.iconCalendar} />
      </Box>
      {headerRemove}
    </ConfirmDialog>
  );

  const confirmNotifyRemoveDialog = (
    <ConfirmDialog
      type="notify"
      user={userSelected}
      onSkip={handleSkip}
      onNotify={handleNotify}
    >
      <Box className={classes.iconContainer}>
        <FontAwesomeIcon icon={faCheckCircle} className={classes.icon} />
      </Box>
      <Typography className={classes.title}>
        {headerNotify}
      </Typography>
    </ConfirmDialog>
  );

  const confirmSuspendDialog = (
    <ConfirmDialog
      type="suspend"
      user={userSelected}
      onNoSuspend={() => setShowSuspendDialog(false)}
      onSuspend={handleSuspend}
      userIdSelected={userIdSelected}
    >
      <Box className={clsx(classes.iconContainer, classes.iconRemoveContainer)}>
        <Img src="/images/icons/suspend.svg" className={classes.iconSuspendActivate} />
      </Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.titleRemoveSuspendActivate}>
          Suspending a user will make it <strong>inactive</strong>. They won’t be able to login until you reactive them.
        </Typography>
      </Box>
    </ConfirmDialog>
  );

  const confirmActivateDialog = (
    <ConfirmDialog
      type="activate"
      user={userSelected}
      onActivate={handleActivate}
      onNoActivate={() => setShowActivateDialog(false)}
      loadingChangeStatus={changeStatus === CHANGING_STATUS}
    >
      <Box className={clsx(classes.iconContainer, classes.iconRemoveContainer)}>
        <Img src="/images/icons/activate.svg" className={classes.iconSuspendActivate} />
      </Box>
      <Typography className={classes.titleRemoveSuspendActivate}>
        Activating a user will allow them to <strong>login to all business</strong> attached to them.
      </Typography>
    </ConfirmDialog>
  );

  const empty = <TableMessage title="Not Found!" description="Businesses not found." />;

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        {header}
        <Box className={classes.contentContainer}>
          <Box className={classes.userContainer}>
            {userComponent}
          </Box>
          <Box className={classes.rightContentContainer}>
            {loading || !isEmpty ? userTable : empty}
          </Box>
        </Box>
        {showNewBusiness && newBusiness}
        {showConfirmNotifyDialog && confirmNotifyDialog}
        {showConfirmRemoveDialog && confirmRemoveDialog}
        {showNotifyRemoveDialog && confirmNotifyRemoveDialog}
        {showSuspendDialog && confirmSuspendDialog}
        {showActivateDialog && confirmActivateDialog}
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Users;
