import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Table, TableMessage } from "components/UI";
import TableHeader from "./UI/TableNewBusiness/TableHeader";
import SearchComponent from "./UI/SearchComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { RootState } from "store";
import { Client, SortFields, SortOrder } from "model/Client";
import useNonInitialEffect from "@versiondos/hooks";
import { fetchThunk } from "actions/clients/ClientsActions";
import TableBody from "./UI/TableNewBusiness/TableBody";
import { User } from "model/User";

interface Props {
  user?: User | undefined;
  client?: Client;
  loadingAddingBusiness: boolean;
  onClose: () => void;
  onAdd: (client: Client) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("sm")]: {
        height: "25px"
      },
      [theme.breakpoints.only("md")]: {
        height: "41px"
      },
      [theme.breakpoints.only("lg")]: {
        height: "54px"
      },
      [theme.breakpoints.only("xl")]: {
        height: "82px"
      },
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "15px",
          paddingTop: "6px",
          paddingRight: "11px",
          paddingBottom: "5px",
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "25px",
          paddingTop: "10px",
          paddingRight: "17px",
          paddingBottom: "7px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "33px",
          paddingTop: "13px",
          paddingRight: "23px",
          paddingBottom: "10px",
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "50px",
          paddingTop: "20px",
          paddingRight: "35px",
          paddingBottom: "15px",
        },
      },
    },
    title: {
      fontWeight: 600,
      color: "#5E8677",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("sm")]: {
          width: "285px",
          minWidth: "285px",
          height: "178px",
          borderRadius: "7px",
        },
        [theme.breakpoints.only("md")]: {
          width: "459px",
          minWidth: "459px",
          height: "287px",
          borderRadius: "12px",
        },
        [theme.breakpoints.only("lg")]: {
          width: "612px",
          minWidth: "612px",
          height: "382px",
          borderRadius: "16px",
        },
        [theme.breakpoints.only("xl")]: {
          width: "928px",
          minWidth: "928px",
          height: "579px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        padding: "0px",
      }
    },
    sectionTitleContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    sectionSearchContainer: {
      display: "flex"
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "11px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "18px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "24px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "36px"
      },
    },
    iconTimes: {
      color: "#222222",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "24px"
      },
    }
  })
);

export const PetPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const clients = useSelector<RootState, Array<Client>>(state => state.clients.clients);
  const loading = useSelector<RootState, boolean>(state => state.clients.loading);

  const [filterClients, setFilterClients] = useState<Array<Client>>(clients);
  const [filteredClients, setFilteredClients] = useState<Array<Client>>([]);

  const [searchText, setSearchText] = useState("");

  const [isEmpty, setIsEmpty] = useState(false);

  const filter = (text: string): Array<Client> => {
    let filteredClientsAux: Array<Client> = filterClients;

    if (text.length > 0) {
      filteredClientsAux = filterClients.filter((client) => {
        const name = client.name.toLowerCase();
        return name.indexOf(text.toLowerCase()) > -1;
      });
    }

    return filteredClientsAux;
  };

  const sort = (field: SortFields | undefined, order: SortOrder) => {
    if(field !== undefined){
      let filteredClientsAux = [...filteredClients];
      filteredClientsAux.sort((client, otherClient) => {
        if(field === "businessName") {
          if(client.name.toLowerCase() > otherClient.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if(client.name.toLowerCase() < otherClient.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        }else{
          return 1;
        }
      });
      setFilteredClients(filteredClientsAux);
    }
  }

  const changeFilteredClients = (filteredClients: Array<Client>) => {
    setFilteredClients(filteredClients);
    setIsEmpty(filteredClients.length === 0);
  };

  useEffect(() => {
    if(clients && props.user) {
      const userBusinessIds =  props.user!.businesses.filter(b => b.active).map(b => b.businessId);
      const notAssignedClients = clients.filter(c => !userBusinessIds.includes(c.id));
      setFilteredClients(notAssignedClients);
      setFilterClients(notAssignedClients);
    }else{
      changeFilteredClients(filter(searchText));
    }
  }, [clients]);

  useNonInitialEffect(() => {
    changeFilteredClients(filter(searchText));
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchThunk());
  }, []);

  const table = (
    <Table 
      head={<TableHeader  onSort={(field, order)=> sort(field, order)} />}
      body={<TableBody 
        loadingAddingBusiness={props.loadingAddingBusiness}
        clientSelected={props.client?.id}
        clients={filteredClients} 
        onAdd={props.onAdd}
      />}
    />
  );

  const empty = <TableMessage title="Not Found!" description="Clients not found." />;

  return (<Box>
    <Dialog open={true} className={classes.container}>
      <DialogTitle className={classes.titleContainer}>
        <Box className={classes.sectionTitleContainer}>
          <Typography className={classes.title}>All Businesses</Typography>
        </Box>
        <Box className={classes.sectionTitleContainer}>
          <Box className={classes.sectionSearchContainer}>
            <SearchComponent 
              placeholder="Search by business name" 
              onSearch={setSearchText}
            />
            <Box className={classes.iconContainer}>
              <FontAwesomeIcon icon={faTimes} className={classes.iconTimes} onClick={() => props.onClose()}/>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
          {loading || !isEmpty ? table : empty}
        </OverlayScrollbarsComponent>
      </DialogContent>
    </Dialog>
  </Box>);
};

export default PetPopup;
