import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box, Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";
import { HomePath } from "routes/Paths";
import { Business } from "model/Business";
import { Link } from "components/UI";

interface Props {
  business?: Business;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",       
      [theme.breakpoints.down("sm")]: {
        paddingTop: "6px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "18px",
      },
    },
    content: {
      display: "flex",
    },
    breadcrumb: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "3px",
        fontSize: "7px",
        lineHeight: "7px",
        paddingTop: "3px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "5px",
        fontSize: "9px",
        lineHeight: "11px",
        paddingTop: "2px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "7px",
        fontSize: "11px",
        lineHeight: "15px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "10px",
        fontSize: "14px",
        lineHeight: "22px"
      },
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "3px",
        fontSize: "11px",
        lineHeight: "17px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "5px",
        fontSize: "18px",
        lineHeight: "27px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "7px",
        fontSize: "24px",
        lineHeight: "36px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "10px",
        fontSize: "36px",
        lineHeight: "54px"
      },
    },
    location: {
      [theme.breakpoints.down("sm")]: {
        lineHeight: "6px",
        fontSize: "5px",
        marginBottom: "6px",
      },
      [theme.breakpoints.only("md")]: {
        lineHeight: "10px",
        fontSize: "8px",
        marginBottom: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        lineHeight: "13px",
        fontSize: "11px",
        marginBottom: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        lineHeight: "20px",
        fontSize: "16px",
        marginBottom: "18px",
      },
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "2px",
        fontSize: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "4px",
        fontSize: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "5px",
        fontSize: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "8px",
        fontSize: "16px",
      },
    },
    link: {
      textDecoration: "none",
      marginTop: "1px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "-15px",
        fontSize: "4px",
        height: "0px"
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "-10px",
        fontSize: "7px",
        height: "0px"
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "-6px",
        fontSize: "9px",
        height: "12px"
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "-2px",
        fontSize: "14px",
        height: "20px",
      },
    }
  })
);

export const LeftHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Link 
          label="Clients" 
          linkTo={HomePath}
          size="small"
          color="black" 
          className={classes.link} 
        />
        <Typography className={classes.breadcrumb}>
          / <strong>{props.business?.name}</strong>
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.title}>{props.business?.name}</Typography>
      </Box>
      <Box className={classes.content}>
        <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.icon}/>
        <Typography className={classes.location}>{props.business?.city}</Typography>
      </Box>
    </Box>
  );
};

export default LeftHeader;
