import { Action } from "redux";

//Types
export const NOTIFICATIONS_RESET_ACTION_TYPE = "notifications/reset";
export const NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE = "notifications/suspended_user_start";
export const NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE = "notifications/suspended_user_success";
export const NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE = "notifications/activated_user_start";
export const NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE = "notifications/activated_user_success";
export const NOTIFICATIONS_ERROR_ACTION_TYPE = "notifications/error";

//Actions
interface NotificationsResetAction extends Action<typeof NOTIFICATIONS_RESET_ACTION_TYPE> {}

interface NotificationsSuspendedUserStartAction extends Action<typeof NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE> {}
interface NotificationsSuspendedUserSuccessAction extends Action<typeof NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE> {}

interface NotificationsActivatedUserStartAction extends Action<typeof NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE> {}
interface NotificationsActivatedUserSuccessAction extends Action<typeof NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE> {}

interface NotificationsErrorAction extends Action<typeof NOTIFICATIONS_ERROR_ACTION_TYPE> {}

//UsersAction
export type NotificationsAction =
  | NotificationsResetAction
  | NotificationsSuspendedUserStartAction
  | NotificationsSuspendedUserSuccessAction
  | NotificationsActivatedUserStartAction
  | NotificationsActivatedUserSuccessAction
  | NotificationsErrorAction;
