import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessIssue, BusinessStaff as BusinessStaffModel, Business as BusinessModel, SortFields, SortOrder } from "model/Business";
import { RootState } from "store";
import HeaderComponent from "./UI/HeaderComponent";
import BusinessAlerts from "./UI/BusinessAlerts";
import BusinessStaff from "./UI/BusinessStaff";
import BusinessCalendar from "./UI/BusinessCalendar";
import BusinessTerminal from "./UI/BusinessTerminal";
import UserComponent from "components/Users";
import TerminalComponent from "components/Terminals";

import { getThunk } from "actions/businesses/BusinessesActions";
import { Spinner, TableMessage } from "components/UI";
import { Terminal } from "model/TerminalOrder";
import { BusinessesState } from "reducers/businesses/BusinessesState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    headerContainer: {
      border: "1px solid #F1F1F1"
    },
    spinner: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "25px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "40px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "53px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "80px"
      },
    }
  })
);

export const Business: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const GETTING_BUSINESS = 1;

  const business = useSelector<RootState, BusinessModel | undefined>(state => state.businesses.business);
  const businessIssues = useSelector<RootState, Array<BusinessIssue>>(state => state.businesses.issues);
  const businessStaff = useSelector<RootState, Array<BusinessStaffModel>>(state => state.businesses.staff);
  const businessTerminal = useSelector<RootState, Array<Terminal>>(state => state.businesses.terminals);

  const loadingIssues = useSelector<RootState, boolean>(state => state.businesses.loadingIssues);
  const loadingStaff = useSelector<RootState, boolean>(state => state.businesses.loadingUsers);
  const loadingTerminal = useSelector<RootState, boolean>(state => state.businesses.loadingTerminal);

  const statusBusiness = useSelector<RootState, number>(state => state.businesses.status);

  const [businessIssuesSorted, setBusinessIssuesSorted] = useState<Array<BusinessIssue>>(businessIssues);
  const [businessStaffSorted, setBusinessStaffSorted] = useState<Array<BusinessStaffModel>>(businessStaff);
  const [businessTerminalSorted, setBusinessTerminalSorted] = useState<Array<Terminal>>(businessTerminal);

  const [isEmptyAlerts, setIsEmptyAlerts] = useState(businessIssues.length === 0 ? true : false);
  const [isEmptyStaff, setIsEmptyStaff] = useState(businessStaff.length === 0 ? true : false);
  const [isEmptyTerminal, setIsEmptyTerminal] = useState(businessTerminal.length === 0 ? true : false);

  const alertsItem = "alerts";
  const usersItem = "users";
  const calendarItem = "calendar";
  const terminalsItem = "terminals";

  const [selectedHeaderItem, setSelectedHeaderItem] = useState(alertsItem);

  const [showStaff, setShowStaff] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [terminalId, setTerminalId] = useState(0);
  const [userId, setUserId] = useState(0);

  const sortIssues = (field: SortFields | undefined, order: SortOrder) => {
    if (field !== undefined) {
      let businessIssuesAux = [...businessIssues];
      businessIssuesAux.sort((issue, otherIssue) => {
        if (field === "issueName") {
          if (issue.name.toLowerCase() > otherIssue.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (issue.name.toLowerCase() < otherIssue.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "priority") {
          if (issue.priority.name.toLowerCase() > otherIssue.priority.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (issue.priority.name.toLowerCase() < otherIssue.priority.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "reporter") {
          if (issue.reporter.firstName.toLowerCase() > otherIssue.reporter.firstName.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (issue.reporter.firstName.toLowerCase() < otherIssue.reporter.firstName.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "status") {
          if (issue.status.name.toLowerCase() > otherIssue.status.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (issue.status.name.toLowerCase() < otherIssue.status.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else {
          return 1;
        }
      });
      setBusinessIssuesSorted(businessIssuesAux);
    }
  }

  const sortUsers = (field: SortFields | undefined, order: SortOrder) => {
    if (field !== undefined) {
      let businessStaffAux = [...businessStaff];
      businessStaffAux.sort((staff, otherStaff) => {
        if (field === "staffName") {
          if (staff.firstName.toLowerCase() > otherStaff.firstName.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.firstName.toLowerCase() < otherStaff.firstName.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "role") {
          if (staff.role.name.toLowerCase() > otherStaff.role.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.role.name.toLowerCase() < otherStaff.role.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "accessLevel") {
          if (staff.accessLevel.name.toLowerCase() > otherStaff.accessLevel.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.accessLevel.name.toLowerCase() < otherStaff.accessLevel.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        }
        else if (field === "email") {
          if (staff.email.toLowerCase() > otherStaff.email.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.email.toLowerCase() < otherStaff.email.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else {
          return 1;
        }
      });
      setBusinessStaffSorted(businessStaffAux);
    }
  };

  const sortTerminals = (field: SortFields | undefined, order: SortOrder) => {
    if (field !== undefined) {
      let businessStaffAux = [...businessStaff];
      businessStaffAux.sort((staff, otherStaff) => {
        if (field === "staffName") {
          if (staff.firstName.toLowerCase() > otherStaff.firstName.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.firstName.toLowerCase() < otherStaff.firstName.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "role") {
          if (staff.role.name.toLowerCase() > otherStaff.role.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.role.name.toLowerCase() < otherStaff.role.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else if (field === "accessLevel") {
          if (staff.accessLevel.name.toLowerCase() > otherStaff.accessLevel.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.accessLevel.name.toLowerCase() < otherStaff.accessLevel.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        }
        else if (field === "email") {
          if (staff.email.toLowerCase() > otherStaff.email.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (staff.email.toLowerCase() < otherStaff.email.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else {
          return 1;
        }
      });
      setBusinessStaffSorted(businessStaffAux);
    }
  };

  const handleShowStaff = (staffId: number) => {
    setShowStaff(true);
    setUserId(staffId);
  };

  const handleShowTerminal = (terminalId: number) => {
    setShowTerminal(true);
    setTerminalId(terminalId);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.pathname.split('/')[1];
    dispatch(getThunk(parseInt(id!)));
  }, []);

  useEffect(() => {
    setBusinessIssuesSorted(businessIssues);
    if (businessIssues) {
      setIsEmptyAlerts(false);
    }
  }, [businessIssues]);

  useEffect(() => {
    setBusinessStaffSorted(businessStaff);
    if (businessStaff) {
      setIsEmptyStaff(false);
    }
  }, [businessStaff]);

  useEffect(() => {
    setBusinessTerminalSorted(businessTerminal);
    if (businessTerminal) {
      setIsEmptyTerminal(false);
    }
  }, [businessTerminal]);


  const onRefreshHandler = () => {
    const url = new URL(window.location.href);
    const id = url.pathname.split('/')[1];
    dispatch(getThunk(parseInt(id!)));
  };

  const header = (
    <HeaderComponent
      business={business}
      loadingIssues={loadingIssues}
      loadingStaff={loadingStaff}
      gettingBusiness={statusBusiness === GETTING_BUSINESS}
      issues={businessIssues.length}
      users={businessStaff.length}
      terminals={businessTerminal.length}
      selectedItemId={selectedHeaderItem}
      onSelect={(selected) => { setSelectedHeaderItem(selected) }}
    />
  );

  const businessAlertsComponent = (
    <BusinessAlerts
      issues={businessIssuesSorted}
      onSort={sortIssues}
    />
  );

  const businessStaffComponent = (
    <BusinessStaff
      staff={businessStaffSorted}
      marketplaceId={parseInt((new URL(window.location.href)).pathname.split('/')[1])}
      onSort={sortUsers}
      onShow={handleShowStaff}
    />
  );

  const businessTerminalComponent = (
    <BusinessTerminal
      terminal={businessTerminalSorted}
      marketplaceId={parseInt((new URL(window.location.href)).pathname.split('/')[1])}
      //
      onSort={sortTerminals}
      onShow={handleShowTerminal}
    />
  );

  const userComponent = (
    <UserComponent
      staffId={userId}
      businessName={business?.name || ""}
      businessId={business && business.id}
      onClickBusinessName={() => setShowStaff(false)}
    />
  );

  const terminalComponent = (
    <TerminalComponent
      terminalId={terminalId}
      businessName={business?.name || ""}
      //
      onClickBusinessName={() => setShowTerminal(false)}
      onRefresh={onRefreshHandler}
    />
  );

  const businessCalendarComponent = (
    <BusinessCalendar />
  );

  const emptyAlerts = <TableMessage title="Not Found!" description="Alerts not found." />;
  const emptyUsers = <TableMessage title="Not Found!" description="Users not found." />;
  const emptyTerminals = <TableMessage title="Not Found!" description="Terminals not found." />;

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        {showStaff ?
          userComponent :
          showTerminal ?
            terminalComponent :
            <Box>
              <Box className={classes.headerContainer}>
                {header}
              </Box>
              {loadingIssues || loadingStaff || statusBusiness === GETTING_BUSINESS ? <Spinner className={classes.spinner} /> :
                <Box>
                  {selectedHeaderItem === alertsItem ? loadingIssues || !isEmptyAlerts ? businessAlertsComponent : emptyAlerts : undefined}
                  {selectedHeaderItem === usersItem ? loadingStaff || !isEmptyStaff ? businessStaffComponent : emptyUsers : undefined}
                  {selectedHeaderItem === calendarItem && businessCalendarComponent}
                  {selectedHeaderItem === terminalsItem ? !loadingTerminal && !isEmptyTerminal ? businessTerminalComponent : emptyTerminals : undefined}
                </Box>}
            </Box>
        }
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Business;
