import React, { FunctionComponent, useState, useEffect } from "react";
import { TextField as MUITextField } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import {useNonInitialEffect} from "@versiondos/hooks";
import { FieldError } from "model";
import InputMask from 'react-input-mask';

export interface Props {
  name?: string;
  label: string;
  value: string;
  placeholder?:string;
  error?: boolean; //Deprecated
  errorMessage?: string; //Deprecated
  errors?: Array<FieldError>;
  onChange: (value: string, name?: any,) => void;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        height: "100%",
        "& .MuiOutlinedInput-inputMultiline": {
          height: "100% !important",
        }
      },
      "& div.Mui-disabled": {
        backgroundColor: "#f5f5f5",
      }
    }
  })
);

export const PhoneField: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [label, setLabel] = useState(props.label);
  const [error, setError] = useState(props.error);
  const [text, setText] = useState(props.value);

  useEffect(() => {
    if (props.errors) {
      const hasError = props.errors.some((e) => e.fieldName === props.name);
      setError(hasError);
      if (hasError) {
        setLabel(props.errors.find((e) => e.fieldName === props.name)!.errorMessage);
      } else {
        setLabel(props.label);
      }
    } else {
      //Deprecated
      setError(props.error);
      if (props.errorMessage && props.errorMessage.length > 0) {
        setLabel(props.errorMessage);
      } else {
        setLabel(props.label);
      }
    }
  }, [props.error, props.errorMessage, props.label, props.errors]);


  const isNumeric = (value: string): boolean => {
    return /^\d+$/.test(value);
  }
  const convertToNumbers = (value: string): string =>{
    return !value ? "" : value.split("").filter(token => isNumeric(token)).join("");
  }
  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(convertToNumbers(text), props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: any): void => {
    const value = convertToNumbers(event.target.value);
    setText(value);

    setError(false);
    setLabel(props.label);
  };

  return (
    <InputMask mask="(999) 999-9999" value={text} onChange={changeHandler} disabled={props.disabled}>
      {(inputProps: any) => 
      <MUITextField
      name={props.name}
      placeholder={props.placeholder}
      variant="outlined"
      label={label}
      autoFocus={props.autoFocus}
      className={clsx(classes.field, props.className)}
      error={error}
      disabled={props.disabled}
    />
      }
    </InputMask>);
};

export default PhoneField;
