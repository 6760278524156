import { Client, ClientsOverview } from "model/Client";

export interface ClientsState {
  clients: Array<Client>;
  overview: ClientsOverview | undefined;
  status: ClientsStatus;
  loading: boolean;
}

export enum ClientsStatus {
  Initial,
  Fectching,
  FetchSuccess,
  Saving,
  SaveSuccess,
  GettingOverview,
  GetOverviewSuccess,
  Error,
}
