import { LoginState, LoginStatus } from "./LoginState";
import {
  LOGIN_START_ACTION_TYPE,
  LOGIN_SUCCESS_ACTION_TYPE,
  LOGIN_FAIL_ACTION_TYPE,
  LOGIN_RESET_ACTION_TYPE,
  LOGOUT_ACTION_TYPE,
  LOGIN_ERROR_ACTION_TYPE,
  LoginAction,
} from "actions/login/LoginActionsTypes";
import { Reducer } from "redux";
import { getFromStorage } from "auth";
import { logoutAuth } from "model/Auth";

const auth = getFromStorage();

const initialState: LoginState = {
  auth,
  status: LoginStatus.Initial,
  loading: false,
};

export const loginReducer: Reducer<LoginState, LoginAction> = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START_ACTION_TYPE:
      return {
        ...state,
        auth: { ...logoutAuth },
        status: LoginStatus.Loging,
        loading: true,
      };
    case LOGIN_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        auth: action.payload.auth,
        status: LoginStatus.LoginSuccess,
        loading: false,
      };
    case LOGIN_FAIL_ACTION_TYPE:
      return {
        ...state,
        auth: { ...logoutAuth },
        status: LoginStatus.LoginFail,
        loading: false,
      };
    case LOGIN_RESET_ACTION_TYPE:
    case LOGOUT_ACTION_TYPE:
      return {
        ...state,
        auth: { ...logoutAuth },
        status: LoginStatus.Initial,
        loading: false,
      };
    case LOGIN_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: LoginStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
