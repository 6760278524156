import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
} from "react";
import { Box, InputAdornment, OutlinedInput } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

interface Props {
  onSearch: (text: string) => void;
  placeholder: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "38px",
      },
      [theme.breakpoints.only("sm")]: {
        height: "39px",
      },
      [theme.breakpoints.only("md")]: {
        height: "40px",
      },
      [theme.breakpoints.only("lg")]: {
        height: "45px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "62px",
      },
    },
    iconContainer: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      paddingLeft: "14px",
    },
    searchField: {
      background: "#fff",
      height: "100%",
      width: "100%",
      borderRadius: "100px",
    },
    pointer: {
      cursor: "pointer",
    },
    fonts: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "20px",
      },
    },
  })
);

export const SearchComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  const iconClickHandler = (): void => {
    setSearchText("");
    setShowSearchBox((current) => {
      return !current;
    });
  };

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSearchText(event.currentTarget.value);
  };

  const searchIcon = (
    <Box className={classes.iconContainer} onClick={iconClickHandler}>
      <FontAwesomeIcon
        className={clsx(classes.pointer, classes.fonts)}
        icon={faSearch}
      />
    </Box>
  );

  const searchBox = (
    <OutlinedInput
      autoFocus
      placeholder={props.placeholder}
      value={searchText}
      className={clsx(classes.searchField, classes.pointer, classes.fonts)}
      onChange={changeHandler}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon
            icon={faSearch}
            className={classes.fonts}
            onClick={iconClickHandler}
          />
        </InputAdornment>
      }
    />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      {showSearchBox ? searchBox : searchIcon}
    </Box>
  );
};

export default SearchComponent;
