import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableHeaderItem from "./TableHeaderItem";
import { SortFields, SortOrder } from "model/Client";

interface Props {
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "4px",
        paddingTop: "4px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "6px",
        paddingTop: "6px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "9px",
        paddingTop: "9px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "13px",
        paddingTop: "13px",
        fontSize: "16px",
      },
    },
    businessName: {
      width: "36%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "25px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "33px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "50px",
      },
    },
    category: {
      width: "19%",
    },
    cellHeader: {
      width: "15%",
    },
    todo: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "14px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "17px"
      },
    }
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields | undefined, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.cell, classes.businessName)}>
          <TableHeaderItem
            label="Business Name"
            sortField={SortFields.BUSINESS_NAME}
            sortOrder={sortField === SortFields.BUSINESS_NAME ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.category)}>
          Category
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.cellHeader)}>
          Users
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.cellHeader)}>
          Status
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.cellHeader, classes.todo)}>
          ToDo
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
