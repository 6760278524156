import _ from "lodash";

export const LoginPath: string = "/login";
export const LogoutPath: string = "/logout";
export const SuperadminsPath: string = "/superadmins";
export const OrdersPath: string = "/orders";
export const BusinessPath: string = "/:id/business";
export const HomePath: string = "/";
export const InsightsPath: string = "/insights";

export const publicPaths = [
  LoginPath,
  LogoutPath
].map((p) => _.replace(p, /:token/g, ""));

export const isPrivatePath = (pathName: string): boolean => {
  return !publicPaths.some((p) => pathName.startsWith(p));
};

export type Path =
  | typeof LoginPath
  | typeof LogoutPath
  | typeof OrdersPath
  | typeof SuperadminsPath
  | typeof HomePath
  | typeof InsightsPath;
