import {
  BusinessesAction,
  BUSINESSES_RESET_ACTION_TYPE,
  BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE,
  BUSINESSES_GET_BUSINESS_START_ACTION_TYPE,
  BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE,
  BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE,
  BUSINESSES_FETCH_STAFF_START_ACTION_TYPE,
  BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE,
  BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE,
  BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE,
  BUSINESSES_ERROR_ACTION_TYPE,
} from "./BusinessesActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { BusinessesState } from "reducers/businesses/BusinessesState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { Business, BusinessIssue, BusinessStaff } from "model/Business";
import { alertErrorHandler } from "actions/ActionUtils";
import { BusinessDto, BusinessIssueDto, BusinessStaffDto } from "./BusinessesDtos";
import { convertBusiness, convertBusinessIssue, convertBusinessStaff } from "./BusinessesConverter";
import moment from "moment-timezone";
import { TerminalDto } from "actions/orders/OrdersDtos";
import { convertToTerminal } from "actions/orders/OrdersConverter";
import { Terminal } from "model/TerminalOrder";

const marketplacesUrl = "admin/marketplaces";
const staffUrl = "admin/staffs";
const terminalUrl = "admin/marketplace_terminals";

export const getThunk = (
  businessId: number
): ThunkAction<void, BusinessesState, null, BusinessesAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(gettingBusiness());

    const url = `${marketplacesUrl}/${businessId}`;
    try {
      const response: AxiosResponse<BusinessDto> = await ApiClient.get(url, createTokenConfig());
      dispatch(getBusinessSuccess(convertBusiness(response.data)));
      dispatch(fetchTerminalsThunk(businessId));
      dispatch(fetchUsersThunk(businessId));

      dispatch(
        fetchIssuesSuccess([
          {
            id: 1,
            name: "Issue 1",
            priority: { id: 1, name: "High" },
            reporter: {
              id: 1,
              firstName: "Alejandro",
              lastName: "Kullmer",
              role: { id: 1, name: "Groomer" },
              accessLevel: { id: 1, name: "Colaborator" },
              email: "alejandrok@versiondos.com",
              phone: "1111122222",
              active: true,
              userId: 11
            },
            date: moment(),
            status: { id: 1, name: "Pending" },
          },
          {
            id: 2,
            name: "Issue 2",
            priority: { id: 2, name: "Medium" },
            reporter: {
              id: 1,
              firstName: "Alejandro",
              lastName: "Kullmer",
              role: { id: 1, name: "Groomer" },
              accessLevel: { id: 1, name: "Colaborator" },
              email: "alejandrok@versiondos.com",
              phone: "1111122222",
              active: true,
              userId: 11
            },
            date: moment(),
            status: { id: 2, name: "Working" },
          },
          {
            id: 3,
            name: "Issue 3",
            priority: { id: 3, name: "Lowest" },
            reporter: {
              id: 2,
              firstName: "Ariel",
              lastName: "Presa",
              role: { id: 1, name: "Groomer" },
              accessLevel: { id: 1, name: "Colaborator" },
              email: "ariel@versiondos.com",
              phone: "3333344444",
              active: true,
              userId: 12
            },
            date: moment(),
            status: { id: 3, name: "Done" },
          },
          {
            id: 4,
            name: "Issue 4",
            priority: { id: 3, name: "Lowest" },
            reporter: {
              id: 3,
              firstName: "Cristian",
              lastName: "Casais",
              role: { id: 1, name: "Groomer" },
              accessLevel: { id: 1, name: "Colaborator" },
              email: "cristianc@versiondos.com",
              phone: "9999988888",
              active: true,
              userId: 13
            },
            date: moment(),
            status: { id: 3, name: "Done" },
          },
        ])
      );
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error fetching business id = ${businessId}`);
    }
  };
};

export const fetchUsersThunk = (
  businessId: number
): ThunkAction<void, BusinessesState, null, BusinessesAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(fetchingUsers());

    const url = `${staffUrl}?marketplace_id=${businessId}`;
    try {
      const response: AxiosResponse<Array<BusinessStaffDto>> = await ApiClient.get(url, createTokenConfig());
      dispatch(fetchUsersSuccess(response.data.map(dto => convertBusinessStaff(dto, businessId))));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error fetching staff, marketplace id = ${businessId}`);
    }
  };
};

export const fetchTerminalsThunk = (
  businessId: number
): ThunkAction<void, BusinessesState, null, BusinessesAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(fetchingTerminals());

    const url = `${terminalUrl}/${businessId}`;
    try {
      const response: AxiosResponse<Array<TerminalDto>> = await ApiClient.get(url, createTokenConfig());
      dispatch(fetchTerminalsSuccess(response.data.map(dto => convertToTerminal(dto))));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error fetching terminals, marketplace id = ${businessId}`);
    }
  };
};

export const reset = (): BusinessesAction => {
  return {
    type: BUSINESSES_RESET_ACTION_TYPE,
  };
};

const gettingBusiness = (): BusinessesAction => {
  return {
    type: BUSINESSES_GET_BUSINESS_START_ACTION_TYPE,
  };
};

const getBusinessSuccess = (business: Business): BusinessesAction => {
  return {
    type: BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE,
    payload: {
      business,
    },
  };
};

const fetchingIssues = (): BusinessesAction => {
  return { type: BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE };
};

const fetchIssuesSuccess = (issues: Array<BusinessIssue>): BusinessesAction => {
  return {
    type: BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE,
    payload: {
      issues: [...issues],
    },
  };
};

const fetchingUsers = (): BusinessesAction => {
  return { type: BUSINESSES_FETCH_STAFF_START_ACTION_TYPE };
};

const fetchingTerminals = (): BusinessesAction => {
  return { type: BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE };
};


const fetchUsersSuccess = (staff: Array<BusinessStaff>): BusinessesAction => {
  return {
    type: BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE,
    payload: {
      staff: [...staff],
    },
  };
};

const fetchTerminalsSuccess = (terminals: Array<Terminal>): BusinessesAction => {
  return {
    type: BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE,
    payload: {
      terminals: [...terminals],
    },
  };
};

const error = (): BusinessesAction => {
  return {
    type: BUSINESSES_ERROR_ACTION_TYPE,
  };
};
