import { UsersState, UsersStatus } from "./UsersState";
import {
  USERS_RESET_ACTION_TYPE,
  USERS_GET_START_ACTION_TYPE,
  USERS_GET_SUCCESS_ACTION_TYPE,
  USERS_RESET_PASSWORD_START_ACTION_TYPE,
  USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  USERS_UPDATE_USER_START_ACTION_TYPE,
  USERS_UPDATE_USER_SUCCESS_ACTION_TYPE,
  USERS_CHANGE_STATUS_START_ACTION_TYPE,
  USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE,
  USERS_ADD_BUSINESS_START_ACTION_TYPE,
  USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE,
  USERS_REMOVE_BUSINESS_START_ACTION_TYPE,
  USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE,
  USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE,
  USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE,
  USERS_ERROR_ACTION_TYPE,
  UsersAction,
} from "actions/users/UsersActionsTypes";
import { Reducer } from "redux";

const initialState: UsersState = {
  user: undefined,
  appointments: undefined,
  status: UsersStatus.Initial,
  loading: false,
  resettingPassword: false,
  changingStatus: false,
  addingBusiness: false,
  removingBusiness: false,
  checkingAppointments: false,
};

export const usersReducer: Reducer<UsersState, UsersAction> = (state = initialState, action) => {
  switch (action.type) {
    case USERS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case USERS_GET_START_ACTION_TYPE:
      return {
        ...state,
        user: undefined,
        status: UsersStatus.Getting,
        loading: true,
      };
    case USERS_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        user: { ...action.payload.user },
        status: UsersStatus.GetSuccess,
        loading: false,
      };
    case USERS_RESET_PASSWORD_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.ResettingPassword,
        resettingPassword: true,
      };
    case USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.ResetPasswordSuccess,
        resettingPassword: false,
      };
    case USERS_UPDATE_USER_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.UpdatingUser,
      };
      case USERS_UPDATE_USER_SUCCESS_ACTION_TYPE:
        return {
          ...state,
          status: UsersStatus.UserUpdateSuccess,
        };
    case USERS_CHANGE_STATUS_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.ChangingStatus,
        changinStatus: true,
      };
    case USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        user: state.user?.userId === action.payload.userId ? { ...state.user, active: action.payload.active } : state.user,
        status: UsersStatus.ChangeStatusSuccess,
        changinStatus: false,
      };
    case USERS_ADD_BUSINESS_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.AddingBusiness,
        addingBusiness: true,
      };
    case USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.AddBusinessSuccess,
      };
    case USERS_REMOVE_BUSINESS_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.RemovingBusiness,
        addingBusiness: true,
      };
    case USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.RemoveBusinessSuccess,
        addingBusiness: false,
      };
    case USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.CheckingAppointments,
        appointments: undefined,
        checkingAppointments: true,
      };
    case USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.CheckAppointmentsSuccess,
        appointments: { ...action.payload.appointments },
        checkingAppointments: false,
      };
    case USERS_ERROR_ACTION_TYPE:
      return {
        ...state,
        user: undefined,
        appointments: undefined,
        status: UsersStatus.Error,
        loading: false,
        resettingPassword: false,
        changngStatus: false,
        addingBusiness: false,
        removingBusiness: false,
        checkingAppointments: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
