import React, { FunctionComponent, ChangeEvent, KeyboardEvent, useState } from "react";
import { Box, TextField as MUITextField, InputAdornment } from "@material-ui/core";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";

export interface NumberFieldProps {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: number | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: number | undefined, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
  onEnterPressed?: () => void;
  disabled?: boolean;
  scale?: number;
  precision?: number;
  alignRight?: boolean;
}

export const NumberField: FunctionComponent<NumberFieldProps> = (props) => {
  const classes = useFieldStyles();

  const [text, setText] = useState(props.value?.toString() || "");

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(Number(text), props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const [integer, decimals] = newValue.split(".");
    if (!isNaN(Number(newValue))) {
      if (
        (!props.scale || !decimals || decimals.length <= props.scale) &&
        !(props.scale === 0 && newValue.endsWith(".")) &&
        (!props.precision || (integer?.length || 0) + (decimals?.length || 0) <= props.precision)
      ) {
        setText(newValue);
      }
    }
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  let inputProps = undefined;

  if (props.startIcon) {
    inputProps = {
      startAdornment: <InputAdornment position="start">{props.startIcon}</InputAdornment>,
    };
  } else if (props.endIcon) {
    inputProps = {
      endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>,
    };
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      <Box>
        <MUITextField
          name={props.name}
          placeholder={props.placeholder}
          variant="outlined"
          value={text}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(classes.field, {
            [classes.startIcon]: props.startIcon,
            [classes.endIcon]: props.endIcon,
            [classes.emptyField]: text.length === 0,
            [classes.fieldError]: hasError,
            [classes.inputTextRight]: props.alignRight
          })}
          error={hasError}
          InputProps={inputProps}
          multiline={props.multiline}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
        />
      </Box>
    </Box>
  );
};

export default NumberField;
