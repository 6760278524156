import React, { FunctionComponent, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

interface Props {
  className?: string;
}

export const DateTime: FunctionComponent<Props>= (props) => {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Typography className={props.className}>{date.format("MMM D, h:mm a")}</Typography>
  );
};

export default DateTime;
