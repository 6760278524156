import React, { FunctionComponent } from "react";
import { Box, AppBar, Toolbar } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Logo } from "../Logo";
import RightHeader from "./RightHeader";
import { useLocation } from "react-router-dom";
import { HomePath, SuperadminsPath, InsightsPath, OrdersPath } from "routes/Paths";
import { Item } from "./HeaderItem";
import HeaderItems from "./HeaderItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "23px !important",
        paddingRight: "55px !important",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "37px !important",
        paddingRight: "89px !important",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "50px !important",
        paddingRight: "118px !important",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "75px !important",
        paddingRight: "179px !important",
      },
    },
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "120px"
      },
      [theme.breakpoints.only("md")]: {
        width: "194px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "259px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "393px"
      },
    },
    itemsContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
      },
      [theme.breakpoints.only("md")]: {
      },
      [theme.breakpoints.only("lg")]: {
      },
      [theme.breakpoints.only("xl")]: {
      },
    },
    rightContainer: {
      display: "flex",
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      justifyContent: "flex-end",
    },
  })
);

export const SignedInHeader: FunctionComponent = () => {
  const classes = useStyles();

  let location = useLocation();

  const items: Array<Item> = [
    { name: "Clients", path: HomePath },
    { name: "Terminals", path: OrdersPath },
    { name: "Super Admins", path: SuperadminsPath },
    { name: "Insights", path: InsightsPath },
  ];

  items.forEach((item) => (item.active = item.path === location.pathname));

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <Box className={classes.container}>
          <Box className={classes.logoContainer}>
            <Logo />
          </Box>
          <Box className={classes.itemsContainer}>
            <HeaderItems items={items} />
          </Box>
          <Box className={classes.rightContainer}>
            <RightHeader />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SignedInHeader;
