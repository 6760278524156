import { Action } from "redux";
import { Business, BusinessIssue, BusinessStaff } from "model/Business";
import { Terminal } from "model/TerminalOrder";

//Types
export const BUSINESSES_RESET_ACTION_TYPE = "BUSINESSES_RESET";
export const BUSINESSES_GET_BUSINESS_START_ACTION_TYPE = "BUSINESSES_GET_BUSINESS_START";
export const BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE = "BUSINESSES_GET_BUSINESS_SUCCESS";
export const BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE = "BUSINESSES_FETCH_ISSUES_START";
export const BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE = "BUSINESSES_FETCH_ISSUES_SUCCESS";
export const BUSINESSES_FETCH_STAFF_START_ACTION_TYPE = "BUSINESSES_FETCH_STAFF_START";
export const BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE = "BUSINESSES_FETCH_STAFF_SUCCESS";
export const BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE = "BUSINESSES_FETCH_TERMINAL_START";
export const BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE = "BUSINESSES_FETCH_TERMINAL_SUCCESS";
export const BUSINESSES_ERROR_ACTION_TYPE = "BUSINESSES_ERROR";

//Actions
interface BusinessResetAction extends Action<typeof BUSINESSES_RESET_ACTION_TYPE> { }

interface BusinessGetBusinessStartAction extends Action<typeof BUSINESSES_GET_BUSINESS_START_ACTION_TYPE> { }

interface BusinessGetBusinessSuccessAction extends Action<typeof BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE> {
  payload: {
    business: Business;
  }
}

interface BusinessFetchIssuesSuccessAction extends Action<typeof BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE> {
  payload: {
    issues: Array<BusinessIssue>;
  };
}

interface BusinessFetchIssuesStartAction extends Action<typeof BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE> { }

interface BusinessFetchIssuesSuccessAction extends Action<typeof BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE> {
  payload: {
    issues: Array<BusinessIssue>;
  };
}

interface BusinessFetchStaffStartAction extends Action<typeof BUSINESSES_FETCH_STAFF_START_ACTION_TYPE> { }

interface BusinessFetchStaffSuccessAction extends Action<typeof BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE> {
  payload: {
    staff: Array<BusinessStaff>;
  };
}

interface BusinessFetchTerminalStartAction extends Action<typeof BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE> { }

interface BusinessFetchTerminalSuccessAction extends Action<typeof BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE> {
  payload: {
    terminals: Array<Terminal>;
  };
}


interface BusinessErrorAction extends Action<typeof BUSINESSES_ERROR_ACTION_TYPE> { }

//BusinessAction
export type BusinessesAction =
  | BusinessResetAction
  | BusinessGetBusinessStartAction
  | BusinessGetBusinessSuccessAction
  | BusinessFetchIssuesStartAction
  | BusinessFetchIssuesSuccessAction
  | BusinessFetchStaffStartAction
  | BusinessFetchStaffSuccessAction
  | BusinessFetchTerminalStartAction
  | BusinessFetchTerminalSuccessAction
  | BusinessErrorAction;
