import { Auth } from "model/Auth";
import { Action } from "redux";

//Types
export const LOGIN_RESET_ACTION_TYPE = "LOGIN_RESET";
export const LOGIN_START_ACTION_TYPE = "LOGIN_START";
export const LOGIN_SUCCESS_ACTION_TYPE = "LOGIN_SUCCESS";
export const LOGIN_FAIL_ACTION_TYPE = "LOGIN_FAIL";
export const LOGOUT_ACTION_TYPE = "LOGOUT";
export const LOGIN_ERROR_ACTION_TYPE = "LOGIN_ERROR";

//Login
interface LoginStartAction extends Action<typeof LOGIN_START_ACTION_TYPE> {}

interface LoginSuccessAction extends Action<typeof LOGIN_SUCCESS_ACTION_TYPE> {
  payload: {
    auth: Auth;
  };
}

interface LoginFailAction extends Action<typeof LOGIN_FAIL_ACTION_TYPE> {}

interface LoginResetAction extends Action<typeof LOGIN_RESET_ACTION_TYPE> {}

interface LogoutAction extends Action<typeof LOGOUT_ACTION_TYPE> {}

interface LoginErrorAction extends Action<typeof LOGIN_ERROR_ACTION_TYPE> {}

//LoginAction
export type LoginAction =
  | LoginResetAction
  | LoginStartAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction
  | LoginErrorAction;
