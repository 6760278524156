import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Terminal } from "model/TerminalOrder";
import { wbp, reduceResolution } from "Theme";
import {
    Filter,
    SearchComponent,
} from "components/UI";
import { faList, faCheck } from "@fortawesome/pro-light-svg-icons";
import { Option } from "@spike/model";
import { useMasterData } from "hooks";
import { useNonInitialEffect } from "@versiondos/hooks";

interface FilterHeaderProps {
    terminals: Array<Terminal>;
    //
    onFilter: (terminals: Array<Terminal>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            textAlign: "center"
        },

        boxContentFilter: {
            background: "#F1F1F1",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(12)}px`,
                margin: `${reduceResolution(12)}px ${reduceResolution(84)}px`,
                padding: `${reduceResolution(12)}px ${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: "12px",
                margin: "12px 84px",
                padding: "12px 20px",
            },
        },

        boxSearch: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(500)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "500px",
            },
        },

        searchBox: {
        },

        boxFilter: {
            display: "flex",
        },

        boxFilterStatus: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(10)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "10px",
            },
        }
    })
);

export const FilterHeader: FunctionComponent<FilterHeaderProps> = (props) => {
    const classes = useStyles();
    const masterData = useMasterData();

    const [filterTerminals, setFilterTerminals] = useState<Array<Terminal>>(props.terminals);
    const [terminals] = useState<Array<Terminal>>(props.terminals);

    const [allStatus, setAllStatus] = useState<boolean>(true);
    const [allActive, setAllActive] = useState<boolean>(true);

    const [filterStatus, setFilterStatus] = useState<Array<string | undefined>>();
    const [filterActive, setFilterActive] = useState<Array<string | undefined>>();
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        props.onFilter(filterTerminals);
    }, [filterTerminals]);

    useNonInitialEffect(() => {
        changeFilteredTerminals(filterText(searchText));
    }, [searchText]);

    useNonInitialEffect(() => {
        setFilterTerminals(filterControl(terminals));
    }, [allStatus, allActive, filterStatus, filterActive]);


    const filterText = (text: string): Array<Terminal> => {
        let filteredOrdersAux: Array<Terminal>;

        if (text.length > 0) {
            filteredOrdersAux = filterTerminals.filter((terminals) => {
                const name = `${terminals.name!.toLowerCase()}`;
                return (
                    text.length === 0 || name.indexOf(text.toLowerCase()) > -1
                );
            });
        }

        if (text.length === 0) {
            filteredOrdersAux = filterControl(terminals);
        }

        return filteredOrdersAux!;
    };

    const filterControl = (filterTerminals: Array<Terminal>) => {
        let filter: Array<Terminal> = [];

        if (allActive && allStatus) filter = filterTerminals;
        if (allActive && !allStatus) filter = filterStatusHandler(filterTerminals, filterStatus!);
        if (!allActive && allStatus) filter = filterActiveHandler(filterTerminals, filterActive!);
        if (!allActive && !allStatus) {
            filter = filterStatusHandler(filterTerminals, filterStatus!);
            filter = filterActiveHandler(filter, filterActive!);
        };

        return filter;
    };

    const changeFilteredTerminals = (filterTerminals: Array<Terminal>) => {
        setFilterTerminals(filterTerminals);
    };

    const getStatus = () => {
        let statusAux: Array<Option<string>> = [];
        masterData.terminalStatuses && masterData.terminalStatuses.map((item) => {
            const itemStatus: Option<string> = {
                id: item.id!,
                name: item.name,
            }
            statusAux.push(itemStatus);
        })
        return statusAux;
    };

    const getActive = () => {
        let statusAux: Array<Option<string>> = [];

        let itemStatus: Option<string> = {
            id: "Enabled",
            name: "Enabled",
        }
        statusAux.push(itemStatus);

        itemStatus = {
            id: "Disabled",
            name: "Disabled",
        }
        statusAux.push(itemStatus);
        return statusAux;
    }

    const filterStatusHandler = (fTerminals: Array<Terminal>, statusIds: Array<string | undefined>) => {
        let filter = fTerminals.filter(
            (terminal) => statusIds!.includes(terminal.status));
        return filter;
    }

    const filterActiveHandler = (fTerminals: Array<Terminal>, statusIds: Array<string | undefined>) => {
        let filter = fTerminals.filter(
            (terminal) => statusIds.includes(terminal.active ? "Enabled" : "Disabled"));
        return filter;
    }

    //
    const selectFilterStatusHandler = (statusIds: Array<string | undefined>, allSelected: boolean) => {
        setAllStatus(allSelected);
        setFilterStatus(statusIds);
    };

    const selectFilterActiveHandler = (statusIds: Array<string | undefined>, allSelected: boolean) => {
        setAllActive(allSelected);
        setFilterActive(statusIds);
    };
    //
    const filterView = (
        <Box className={classes.boxContentFilter}>
            <Box className={classes.boxSearch}>
                <SearchComponent
                    placeholder={"Search by Name"}
                    className={classes.searchBox}
                    //
                    onSearch={setSearchText}
                />
            </Box>
            <Box className={classes.boxFilter}>
                <Box className={classes.boxFilterStatus}>
                    <Filter
                        icon={faList}
                        options={getStatus()}
                        label={"Status"}
                        //
                        onSelect={selectFilterStatusHandler}
                    />
                </Box>
                <Filter
                    icon={faCheck}
                    options={getActive()}
                    label={"Active"}
                    //
                    onSelect={selectFilterActiveHandler}
                />
            </Box>
        </Box>
    );

    return (
        <Box className={classes.container}>
            {filterView}
        </Box>
    );
};

export default FilterHeader;
