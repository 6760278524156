import { Action } from "redux";
import { Client, ClientsOverview } from "model/Client";

//Types
export const CLIENTS_RESET_ACTION_TYPE = "CLIENTS_RESET";
export const CLIENTS_FETCH_START_ACTION_TYPE = "CLIENTS_FETCH_START";
export const CLIENTS_FETCH_SUCCESS_ACTION_TYPE = "CLIENTS_FETCH_SUCCESS";
export const CLIENTS_SAVE_START_ACTION_TYPE = "CLIENTS_SAVE_START";
export const CLIENTS_SAVE_SUCCESS_ACTION_TYPE = "CLIENTS_SAVE_SUCCESS";
export const CLIENTS_GET_OVERVIEW_START_ACTION_TYPE = "CLIENTS_GET_OVERVIEW_START";
export const CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE = "CLIENTS_GET_OVERVIEW_SUCCESS";
export const CLIENTS_ERROR_ACTION_TYPE = "CLIENTS_ERROR";

//Actions
interface ClientsResetAction extends Action<typeof CLIENTS_RESET_ACTION_TYPE> {}

interface ClientsFetchStartAction extends Action<typeof CLIENTS_FETCH_START_ACTION_TYPE> {}

interface ClientsFetchSuccessAction extends Action<typeof CLIENTS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    clients: Array<Client>;
  };
}

interface ClientsSaveStartAction extends Action<typeof CLIENTS_SAVE_START_ACTION_TYPE> {}

interface ClientsSaveSuccessAction extends Action<typeof CLIENTS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    client: Client;
  };
}

interface ClientsGetOverviewStartAction extends Action<typeof CLIENTS_GET_OVERVIEW_START_ACTION_TYPE> {}

interface ClientsGetOverviewSuccessAction extends Action<typeof CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE> {
  payload: {
    overview: ClientsOverview;
  };
}

interface ClientsErrorAction extends Action<typeof CLIENTS_ERROR_ACTION_TYPE> {}

//ClientsAction
export type ClientsAction =
  | ClientsResetAction
  | ClientsFetchStartAction
  | ClientsFetchSuccessAction
  | ClientsSaveStartAction
  | ClientsSaveSuccessAction
  | ClientsGetOverviewStartAction
  | ClientsGetOverviewSuccessAction
  | ClientsErrorAction;
