import { NotificationsState, NotificationsStatus } from "./NotificationsState";
import {
  NOTIFICATIONS_RESET_ACTION_TYPE,
  NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE,
  NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE,
  NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE,
  NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE,
  NOTIFICATIONS_ERROR_ACTION_TYPE,
  NotificationsAction,
} from "actions/notifications/NotificationsActionsTypes";
import { Reducer } from "redux";

const initialState: NotificationsState = {
  status: NotificationsStatus.Initial,
  loading: false,
};

export const notificationsReducer: Reducer<NotificationsState, NotificationsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NOTIFICATIONS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE:
      return {
        ...state,
        status: NotificationsStatus.NotifyingSuspendedUser,
        loading: true,
      };
    case NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: NotificationsStatus.NotifySuspendedUserSuccess,
        loading: false,
      };
    case NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE:
      return {
        ...state,
        status: NotificationsStatus.NotifyingActivatedUser,
        loading: true,
      };
    case NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: NotificationsStatus.NotifyActivatedUserSuccess,
        loading: false,
      };
    case NOTIFICATIONS_ERROR_ACTION_TYPE:
      return {
        status: NotificationsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
