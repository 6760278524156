import { BusinessDto, BusinessIssueDto, BusinessStaffDto } from "./BusinessesDtos";
import { Business, BusinessIssue, BusinessStaff } from "model/Business";
import moment from "moment-timezone";
import { store } from "store"; 

export const convertBusiness = (dto: BusinessDto): Business => {
 return {
  id: dto.id,
  name: dto.business_name,
  city: dto.addresses.find(a => a.active)?.city || ""
 };
}

export const convertBusinessIssue = (dto: BusinessIssueDto): BusinessIssue => {
  return {
    id: 0,
    name: "",
    priority: {id: 1, name: ""},
    reporter: {
      id: 0,
      firstName: "",
      lastName: "",
      role: {id: 1, name: ""},
      accessLevel: {id: 1, name: ""},
      email: "",
      phone: "",
      active: true,
      userId: 0
    },
    date: moment(),
    status: {id: 1, name: ""}
  };
};

export const convertBusinessStaff = (dto: BusinessStaffDto, marketplaceId: number): BusinessStaff => {
  const masterdata = store.getState().masterdata.data!;
  const work = dto.works.find(w => w.marketplace_id === marketplaceId)!;
  return {
    id: dto.id,
    firstName: dto.first_name,
    lastName: dto.last_name,
    email: dto.email,
    phone: dto.phone,
    role: masterdata.roles.find(r => r.id === work.role_id)!,
    accessLevel: masterdata.accessLevels.find(al => al.id === work.access_level_id)!,
    active: dto.active,
    userId: dto.user_id
  };
};
