import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Table as TableMUI,
  TableContainer,
} from "@material-ui/core";
import clsx from "clsx";
import { Spinner } from "components/UI";

interface Props {
  head: JSX.Element;
  body: JSX.Element;
  loading?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      overflowX: "visible",
    },
  })
);

export const Table: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const table = (
    <TableContainer className={clsx(classes.container, props.className)}>
      <TableMUI>
        {props.head}
        {props.body}
      </TableMUI>
    </TableContainer>
  );
  return (props.loading ? <Spinner /> : table);
  
};

export default Table;
