import { Action } from "redux";
import { Order, Terminal, Payment } from "model/TerminalOrder";

//Types
export const ORDERS_RESET_ACTION_TYPE = "ORDERS_RESET";
export const ORDERS_FETCH_START_ACTION_TYPE = "ORDERS_FETCH_START";
export const ORDERS_FETCH_SUCCESS_ACTION_TYPE = "ORDERS_FETCH_SUCCESS";
export const ORDERS_ORDER_GET_START_ACTION_TYPE = "ORDERS_ORDER_GET_FETCH_START";
export const ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE = "ORDERS_ORDER_GET_SUCCESS";
export const ORDERS_TERMINAL_GET_START_ACTION_TYPE = "ORDERS_TERMINAL_GET_FETCH_START";
export const ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE = "ORDERS_TERMINAL_GET_SUCCESS";
export const ORDERS_SAVE_START_ACTION_TYPE = "ORDERS_SAVE_START";
export const ORDERS_SAVE_SUCCESS_ACTION_TYPE = "ORDERS_SAVE_SUCCESS";
export const ORDERS_TERMINAL_SAVE_START_ACTION_TYPE = "ORDERS_TERMINAL_SAVE_START";
export const ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE = "ORDERS_TERMINAL_SAVE_SUCCESS";
export const ORDERS_REFUND_SAVE_START_ACTION_TYPE = "ORDERS_REFUND_SAVE_START";
export const ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE = "ORDERS_REFUND_SAVE_SUCCESS";
export const ORDERS_ERROR_ACTION_TYPE = "ORDERS_ERROR";

//Actions
interface OrdersResetAction extends Action<typeof ORDERS_RESET_ACTION_TYPE> { }

interface OrdersFetchStartAction extends Action<typeof ORDERS_FETCH_START_ACTION_TYPE> { }

interface OrdersFetchSuccessAction extends Action<typeof ORDERS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    orders: Array<Order>;
  };
}

interface OrdersOrderGetStartAction extends Action<typeof ORDERS_ORDER_GET_START_ACTION_TYPE> { }
interface OrdersOrderGetSuccessAction extends Action<typeof ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    order: Order;
  };
}
//
interface OrdersTerminalGetStartAction extends Action<typeof ORDERS_TERMINAL_GET_START_ACTION_TYPE> { }
interface OrdersTerminalGetSuccessAction extends Action<typeof ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    terminal: Terminal;
  };
}


interface OrdersSaveStartAction extends Action<typeof ORDERS_SAVE_START_ACTION_TYPE> { }
interface OrdersTerminalSaveStartAction extends Action<typeof ORDERS_TERMINAL_SAVE_START_ACTION_TYPE> { }
interface OrdersRefundSaveStartAction extends Action<typeof ORDERS_REFUND_SAVE_START_ACTION_TYPE> { }

interface OrdersSaveSuccessAction extends Action<typeof ORDERS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    order: Order;
  }
}
interface OrdersTerminalSaveSuccessAction extends Action<typeof ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    terminal: Terminal;
  }
}

interface OrdersRefundSaveSuccessAction extends Action<typeof ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    payment: Payment;
  }
}

interface OrdersErrorAction extends Action<typeof ORDERS_ERROR_ACTION_TYPE> { }

export type OrdersAction =
  | OrdersResetAction
  | OrdersFetchStartAction
  | OrdersFetchSuccessAction
  | OrdersOrderGetStartAction
  | OrdersOrderGetSuccessAction
  | OrdersTerminalGetStartAction
  | OrdersTerminalGetSuccessAction
  | OrdersSaveStartAction
  | OrdersSaveSuccessAction
  | OrdersTerminalSaveStartAction
  | OrdersTerminalSaveSuccessAction
  | OrdersRefundSaveStartAction
  | OrdersRefundSaveSuccessAction
  | OrdersErrorAction;
