import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface SingleChipProps {
  color?: string;
  backgroundColor?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: SingleChipProps) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: props.backgroundColor,
      borderRadius: "27px",
      [theme.breakpoints.down(wbp)]: {
        padding: `0px ${reduceResolution(11)}px`,
        height: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0px 11px",
        height: "27px",
      },
    }),
    text: (props: SingleChipProps) => ({
      fontFamily: "Poppins",
      fontWeight: 500,
      color: props.color,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    })
  })
);

export const SingleChip: FunctionComponent<SingleChipProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.text}>{props.children}</Typography>
    </Box>
  );
};

export default SingleChip;
