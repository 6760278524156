import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Img } from "react-image";
import { Box, Typography } from "@material-ui/core";
import { HomePath } from "routes/Paths";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    adminContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    adminContent: {
      display: "flex",
      backgroundColor: "#EAB464",
      flexDirection: "column",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "4px",
        width: "16px",
        height: "7px",
        borderRadius: "1px",
        marginBottom: "4px"
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: "6px",
        width: "26px",
        height: "12px",
        borderRadius: "2px",
        marginBottom: "6px"
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: "8px",
        width: "35px",
        height: "16px",
        borderRadius: "3px",
        marginBottom: "7px"
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "12px",
        width: "53px",
        height: "24px",
        borderRadius: "5px",
        marginBottom: "9px"
      },
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        height: "20px",
      },
      [theme.breakpoints.only("md")]: {
        height: "26px",
      },
      [theme.breakpoints.only("lg")]: {
        height: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "42px",
      },
    },
    admin: {
      fontWeight: 600,
      color: "#fff",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
        lineHeight: "11px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
        lineHeight: "15px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        lineHeight: "23px"
      },
    }
  })
);

export const Logo: FunctionComponent = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Link href={HomePath}>
        <Img src="/images/logo/Logo.svg" className={classes.logo}/>
      </Link>
    </Box>
  );
};

export default Logo;
