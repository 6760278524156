import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@material-ui/core";
import { User, UserBusiness } from "model/User";
import { Client } from "model/Client";
import { Button, Checkbox, Spinner } from "components/UI";
import clsx from "clsx";

interface Props {
  type: string;
  client?: Client;
  user?: User;
  businessToRemove?: UserBusiness;
  from?: string;
  loadingChangeStatus?: boolean;
  loadingRemovingBusiness?: boolean;
  userIdSelected?: number;
  minHeightRemove?: boolean;
  onSkip?: () => void;
  onNotify?: (userName: boolean, businessOwner: boolean) => void;
  onNoRemove?: () => void;
  onRemove?: (business: UserBusiness) => void;
  onNoRemoveSuspend?: () => void;
  onRemoveSuspend?: (userId: number | undefined) => void;
  onNoSuspend?: () => void;
  onSuspend?: (userId: number | undefined, activeAppoinments: number) => void;
  onNoActivate?: () => void;
  onActivate?: (userId: number | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#E9F0ED",
      height: "auto",
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "21px",
          paddingTop: "10px",
          paddingRight: "21px",
          paddingBottom: "8px",
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "35px",
          paddingTop: "16px",
          paddingRight: "35px",
          paddingBottom: "12px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "46px",
          paddingTop: "22px",
          paddingRight: "46px",
          paddingBottom: "17px",
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "70px",
          paddingTop: "33px",
          paddingRight: "70px",
          paddingBottom: "25px",
        },
      },
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
        lineHeight: "8px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        lineHeight: "13px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "12px",
        lineHeight: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px"
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          width: "153px",
          minWidth: "153px",
          minHeight: "133px",
          borderRadius: "7px",
        },
        [theme.breakpoints.only("md")]: {
          width: "246px",
          minWidth: "246px",
          minHeight: "214px",
          borderRadius: "12px",
        },
        [theme.breakpoints.only("lg")]: {
          width: "327px",
          minWidth: "327px",
          minHeight: "286px",
          borderRadius: "16px",
        },
        [theme.breakpoints.only("xl")]: {
          width: "496px",
          minWidth: "496px",
          minHeight: "433px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        padding: "0px",
      }
    },
    containerSuspendActivate: {
      "& .MuiDialog-paper": {
        [theme.breakpoints.down("sm")]: {
          minHeight: "105px",
          borderRadius: "7px",
        },
        [theme.breakpoints.only("md")]: {
          minHeight: "170px",
        },
        [theme.breakpoints.only("lg")]: {
          minHeight: "226px",
        },
        [theme.breakpoints.only("xl")]: {
          minHeight: "343px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        padding: "0px",
      }
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "21px !important",
        paddingRight: "21px !important",
        paddingTop: "13px !important",
        paddingBottom: "12px !important",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "35px !important",
        paddingRight: "35px !important",
        paddingTop: "20px !important",
        paddingBottom: "20px !important",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "46px !important",
        paddingRight: "46px !important",
        paddingTop: "27px !important",
        paddingBottom: "26px !important",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "70px !important",
        paddingRight: "70px !important",
        paddingTop: "41px !important",
        paddingBottom: "40px !important",
      },
    },
    optionsContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px"
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "25px"
      },
    },
    check: {
      "& .MuiCheckbox-root": {
        paddingLeft: "0px"
      },
      "& .MuiTypography-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "9px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "12px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "18px",
        },
      },
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "5px"
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "16px"
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "28px !important",
        paddingRight: "28px !important",
        paddingBottom: "17px !important",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "45px !important",
        paddingRight: "45px !important",
        paddingBottom: "27px !important",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "60px !important",
        paddingRight: "60px !important",
        paddingBottom: "36px !important",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "91px !important",
        paddingRight: "91px !important",
        paddingBottom: "55px !important",
      },
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        width: "46px",
        height: "14px"
      },
      [theme.breakpoints.only("md")]: {
        width: "74px",
        height: "23px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "98px",
        height: "31px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "149px",
        height: "47px"
      },
    },
    remove: {
      background: "#F1F1F1",
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "16px",
          paddingRight: "14px",
          paddingBottom: "11px"
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "26px",
          paddingRight: "23px",
          paddingBottom: "18px"
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "34px",
          paddingRight: "31px",
          paddingBottom: "24px"
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "52px",
          paddingRight: "47px",
          paddingBottom: "36px"
        },
      },
    },
    suspend: {
      background: "#F1F1F1",
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "16px",
          paddingBottom: "9px",
          paddingTop: "9px",
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "25px",
          paddingBottom: "14px",
          paddingTop: "14px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "34px",
          paddingBottom: "19px",
          paddingTop: "19px",
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "51px",
          paddingBottom: "29px",
          paddingTop: "29px",
        },
      },
    },
    bodyRemoveContainer: {
      display: "flex",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "14px",
        paddingRight: "14px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "22px",
        paddingRight: "22px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "30px",
        paddingRight: "30px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "45px",
        paddingRight: "45px"
      },
    },
    bodySuspendActivateContainer: {
      display: "flex",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "12px",
        paddingRight: "12px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "20px",
        paddingRight: "20px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "26px",
        paddingRight: "26px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "40px",
        paddingRight: "40px"
      },
    },
    containerMinHeight: {
      "& .MuiDialog-paper": {
        [theme.breakpoints.down("sm")]: {
          minHeight: "105px",
        },
        [theme.breakpoints.only("md")]: {
          minHeight: "170px",
        },
        [theme.breakpoints.only("lg")]: {
          minHeight: "226px",
        },
        [theme.breakpoints.only("xl")]: {
          minHeight: "343px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        padding: "0px",
      }
    }
  })
);

export const ConfirmDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [userName, setUserName] = useState(false);
  const [businessOwner, setBusinessOwner] = useState(false);

  const buttonSkip: JSX.Element = (
    <Button
      label="Skip"
      color="orange"
      variant="secondary"
      onClick={() => props.onSkip && props.onSkip()}
      className={classes.button}
      size="medium"
    />
  );

  const buttonNotify: JSX.Element = (
    <Button
      label="Notify"
      color="orange"
      variant="primary"
      onClick={() => props.onNotify && props.onNotify(userName, businessOwner)}
      className={classes.button}
      size="small"
    />
  );

  const buttonNoRemove: JSX.Element = (
    <Button
      label="No"
      color="black"
      variant="secondary"
      onClick={() => props.onNoRemove && props.onNoRemove()}
      className={classes.button}
      size="medium"
    />
  );

  const buttonRemove: JSX.Element = (
    <Button
      label="Continue"
      color={undefined}
      variant="danger"
      onClick={() => props.onRemove && props.onRemove(props.businessToRemove!)}
      className={classes.button}
      size="small"
    />
  );

  const buttonNoRemoveSuspend: JSX.Element = (
    <Button
      label="No"
      color="black"
      variant="secondary"
      onClick={() => props.onNoRemoveSuspend && props.onNoRemoveSuspend()}
      className={classes.button}
      size="medium"
    />
  );

  const buttonRemoveSuspend: JSX.Element = (
    <Button
      label="Continue"
      color={undefined}
      variant="danger"
      onClick={() => props.onRemoveSuspend && props.onRemoveSuspend(props.userIdSelected)}
      className={classes.button}
      size="small"
    />
  );

  const buttonNoSuspend: JSX.Element = (
    <Button
      label="No"
      color="black"
      variant="secondary"
      onClick={() => props.onNoSuspend && props.onNoSuspend()}
      className={classes.button}
      size="medium"
    />
  );

  const buttonSuspend: JSX.Element = (
    <Button
      label="Continue"
      color={undefined}
      variant="danger"
      onClick={() => props.onSuspend && props.onSuspend(props.user?.userId, props.user?.activeAppoinments!)}
      className={classes.button}
      size="small"
    />
  );

  const buttonNoActivate: JSX.Element = (
    <Button
      label="No"
      color="black"
      variant="secondary"
      onClick={() => props.onNoActivate && props.onNoActivate()}
      className={classes.button}
      size="medium"
    />
  );

  const buttonActivate: JSX.Element = (
    <Button
      label="Continue"
      color={undefined}
      variant="success"
      onClick={() => props.onActivate && props.onActivate(props.user?.userId)}
      className={classes.button}
      size="small"
    />
  );

  return (<Box>
    <Dialog open={true} className={clsx(classes.container, {
      [classes.containerSuspendActivate]: props.type === "suspend" || props.type === "activate",
      [classes.containerMinHeight]: props.minHeightRemove
    })}>
      <DialogTitle className={clsx(classes.titleContainer, {
        [classes.remove]: props.type === "remove",
        [classes.suspend]: props.type === "suspend",
      })}>
        {props.children}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {props.type === "notify" && 
        <Box>
          <Typography className={classes.text}>
            Send email notifications about edits.
          </Typography>
          <Box className={classes.optionsContainer}>
            <Checkbox
              label="Notify user name"
              checked={userName}
              onChange={() => setUserName(!userName)}
              className={classes.check}
            />
            <Checkbox
              label="Notify business owner"
              checked={businessOwner}
              onChange={() => setBusinessOwner(!businessOwner)}
              className={classes.check}
            />
          </Box>
        </Box>}
        {props.type === "remove" &&  
        <Box className={classes.bodyRemoveContainer}>
          <Typography className={classes.text}>
            Do you want to continue removing <strong>{props.businessToRemove?.businessName}</strong>?
          </Typography>
        </Box>}
        {props.type === "suspend" &&  
        <Box className={classes.bodySuspendActivateContainer}>
          <Typography className={classes.text}>
            Do you want to continue with <strong>Suspending</strong> {props.user?.firstName} {props.user?.lastName}?
          </Typography>
        </Box>}
        {props.type === "activate" &&  
        <Box className={classes.bodySuspendActivateContainer}>
          <Typography className={classes.text}>
            Do you want to continue with <strong>Activating</strong> {props.user?.firstName} {props.user?.lastName}?
          </Typography>
        </Box>}
      </DialogContent>
      <DialogActions className={classes.buttonsContainer}>
        {props.loadingChangeStatus || props.loadingRemovingBusiness ? <Spinner /> : 
        <Fragment>
          {props.type === "notify" && buttonSkip}
          {props.type === "notify" && buttonNotify}
          {props.type === "remove" && props.userIdSelected === undefined && buttonNoRemove}
          {props.type === "remove" && props.userIdSelected !== undefined && buttonNoRemoveSuspend}
          {props.type === "remove" && props.userIdSelected === undefined && buttonRemove}
          {props.type === "remove" && props.userIdSelected !== undefined && buttonRemoveSuspend}
          {props.type === "activate" && buttonNoActivate}
          {props.type === "activate" && buttonActivate}
          {props.type === "suspend" && buttonNoSuspend}
          {props.type === "suspend" && buttonSuspend}
        </Fragment>}
      </DialogActions>
    </Dialog>
  </Box>);
};

export default ConfirmDialog;
