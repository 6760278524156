import { Masterdata } from "model/Masterdata";

export interface MasterdataState {
  data: Masterdata;
  status: MasterdataStatus;
  loading: boolean;
}

export enum MasterdataStatus {
  Initial,
  Getting,
  GetSuccess,
  Error,
}
