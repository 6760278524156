export interface NotificationsState {
  status: NotificationsStatus;
  loading: boolean;
}

export enum NotificationsStatus {
  Initial,
  NotifyingSuspendedUser,
  NotifySuspendedUserSuccess,
  NotifyingActivatedUser,
  NotifyActivatedUserSuccess,
  Error,
}
