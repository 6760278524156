import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import DateTime from "./DateTime";
import ProfileDropdown from "./ProfileDropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    dateTime: {
      whiteSpace: "nowrap",
      [theme.breakpoints.only("xs")]: {
        marginRight: "10px",
        fontSize: "10px",
      },
      [theme.breakpoints.only("sm")]: {
        marginRight: "15px",
        fontSize: "12px",
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "1.8vw",
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "2vw",
        fontSize: "15px",
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "2.7vw",
        fontSize: "18px",
      },
    },
    profile: {
    },
  })
);

export const RightHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <DateTime className={classes.dateTime} />
      <Box className={classes.profile}>
        <ProfileDropdown />
      </Box>
    </Box>
  );
};

export default RightHeader;
