import { useSelector } from "react-redux";
import { RootState } from "store";
import { Masterdata } from "model/Masterdata";

export const useMasterData = (): Masterdata => {
  const masterData = useSelector<RootState, Masterdata>(state => state.masterdata.data);
  return masterData;
};

export default useMasterData;
