import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { removeAlert } from "../../actions/alerts/AlertsActions";
import { RootState } from "../../store";
import { Message } from "../../reducers/alerts/AlertsState";

interface Props {
  messages: Array<Message>;
  removeAlert: (id: string) => void;
}

export const Alerts: FunctionComponent<Props> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.messages.forEach((message) => {
      enqueueSnackbar(message.message, {variant: message.severity});
      props.removeAlert(message.id);
    });
  }, [props.messages]);

  return null;
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  messages: state.alerts.messages,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  ...ownProps,
  removeAlert: (id: string) => {
    dispatch(removeAlert(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
