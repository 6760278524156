import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import { Order, statusOrder } from "model/TerminalOrder";
import { FunctionComponent, useState } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import React from "react";
import { reduceResolution, wbp } from "Theme";
import { TextField2, SelectField } from "components/UI";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import { useMasterData } from "hooks";
import { Option } from "model";
interface OrderDeliveryProps {
    order: Order;
    className?: string;
    //
    onChange: (order: Order) => void;
    onChangeStatus: (orderId: number, status: string) => void;
    onSave: (order: Order) => void;
    onShipOrder: (order: Order) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridDelivery: {
            borderBottom: "1px solid #DDDDDD",
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`,
                paddingBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "20px",
                paddingBottom: "20px",
            },
        },

        boxCourier: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: "20px",
            },
        },

        boxButtonSave: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(25)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: "25px",
            },
        },

        buttonShip: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(11)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: "11px",
            },
        },

        typoTitle: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(5)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "5px",
            },
        }
    })
);

export const OrderDelivery: FunctionComponent<OrderDeliveryProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();
    const masterData = useMasterData();

    const [editDelivery, setEditDelivery] = useState<boolean>(
        (isEmpty(props.order.courier) && isEmpty(props.order.trackingNumber)) ? false : true);

    const [orderStatusOption, setOrderStatusOption] = useState<Option<string | number>>(
        {
            id: masterData.orderStatuses.find((order) => order.id === props.order.status)!.id,
            name: masterData.orderStatuses.find((order) => order.id === props.order.status)!.name,
        }
    );

    const changeStatusBusinessHandler = (option: Option<string | number>) => {
        setOrderStatusOption(option);
        props.onChangeStatus(props.order.id, option.id.toString());
    };

    const changeBusinessHandler = (value: any, fieldName?: string) => {
        if (fieldName) {
            const updatedOrder = { ...props.order };
            set(updatedOrder, fieldName, value);
            props.onChange(updatedOrder);
        }
    };

    const saveDeliveryHandler = () => {
        setEditDelivery(true);
        props.onSave(props.order);
    };

    const editDeliveryHandler = () => {
        setEditDelivery(false);
    };

    const statusView = (
        <Box className={classes.boxCourier}>
            <SelectField
                placeholder={"Enter a status order"}
                label="Status"
                options={masterData.orderStatuses.map((status) => ({ id: status.id, name: status.name }))}
                selectedOption={orderStatusOption!}
                //
                onSelect={changeStatusBusinessHandler}
            />
        </Box>
    );

    const editedView = (
        <>
            <Box>
                <Box className={terminalCommonClasses.flexRow}>
                    <Typography className={clsx(terminalCommonClasses.typo15_500, terminalCommonClasses.typo_grey, classes.typoTitle)}>Courier: </Typography>
                    <Typography className={terminalCommonClasses.typo15_500}>{props.order.courier}</Typography>
                </Box>
            </Box>
            <Box className={classes.boxCourier}>
                <Box className={terminalCommonClasses.flexRow}>
                    <Typography className={clsx(terminalCommonClasses.typo15_500, terminalCommonClasses.typo_grey, classes.typoTitle)}>Tracking: </Typography>
                    <Typography className={terminalCommonClasses.typo15_500}>{props.order.trackingNumber}</Typography>
                </Box>
            </Box>
            {
                <Box className={classes.boxButtonSave}>
                    <Box>
                        <Button
                            label={"Edit"}
                            variant={"primary"}
                            color={"orange"}
                            onClick={editDeliveryHandler}
                        />
                    </Box>
                </Box>
            }
        </>
    );

    const editView = (
        <>
            <Box className={classes.boxCourier}>
                <TextField2
                    placeholder={"Enter a courier name"}
                    label="Courier"
                    name="courier"
                    value={props.order.courier ? props.order.courier : ""}
                    required={true}
                    //
                    onChange={changeBusinessHandler}
                />
            </Box>
            <Box className={classes.boxCourier}>
                <TextField2
                    placeholder={"Enter a tracking number"}
                    label="Tracking Number"
                    name="trackingNumber"
                    value={props.order.trackingNumber ? props.order.trackingNumber : ""}
                    onChange={changeBusinessHandler}
                    required={true}
                />
            </Box>

            <Button
                label={"Save"}
                variant={"primary"}
                color={"green"}
                onClick={saveDeliveryHandler}
            />
        </>
    );

    //

    return (
        <Box className={terminalCommonClasses.box100}>
            <Grid container className={classes.gridDelivery}>
                <Grid item xs={4}>
                    <Typography className={terminalCommonClasses.typo18_600}>Delivery Information</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                    {statusView}
                    {editDelivery && editedView}
                    {!editDelivery && editView}
                </Grid>
            </Grid>
        </Box >
    );
};

export default OrderDelivery;
