import { Business, BusinessIssue, BusinessStaff } from "model/Business";
import { Terminal } from "model/TerminalOrder";
export interface BusinessesState {
  business: Business | undefined;
  issues: Array<BusinessIssue>;
  staff: Array<BusinessStaff>;
  terminals: Array<Terminal>;
  status: BusinessesStatus;
  //
  loadingBusiness: boolean;
  loadingIssues: boolean;
  loadingUsers: boolean;
  loadingTerminal: boolean;
}

export enum BusinessesStatus {
  Initial,
  GettingBusiness,
  GetBusinessSuccess,
  FetchingIssues,
  FetchIssuesSuccess,
  FetchingStaff,
  FetchStaffSuccess,
  FetchingTerminal,
  FetchTerminalSuccess,
  Error,
}
