import React, { FunctionComponent } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { Terminal } from "model/TerminalOrder";
import TerminalRow from "./TerminalRow";

interface Props {
  terminal: Array<Terminal>;
  marketplaceId: number;
  //
  onShow: (terminalId: number) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.terminal.map((terminal, index) => (
        <TerminalRow
          key={terminal.id}
          terminal={terminal}
          marketplaceId={props.marketplaceId}
          //
          onShow={props.onShow}
        />
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
