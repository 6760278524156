import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { Img } from "react-image";

interface TerminalImageProps {
  id: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "100%",
    },
  })
);

export const TerminalImage: FunctionComponent<TerminalImageProps> = (props) => {
  const classes = useStyles();

  const url = `/images/terminals/${props.id.toLowerCase()}.png`;

  return (
    <Box>
      <Img className={classes.image} src={url} />
    </Box>
  );
};

export default TerminalImage;
