import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { activeThunk, fetchThunk, saveThunk } from "actions/superadmins/SuperadminsActions";
import { Table, TableMessage } from "components/UI";
import { useNonInitialEffect } from "@versiondos/hooks";
import { RootState } from "store";
import HeaderComponent from "./UI/HeaderComponent";
import TableHeader from "./UI/Table/TableHeader";
import TableBody from "./UI/Table/TableBody";
import SuperadminHeader from "./UI/SuperadminHeader";
import SuperadminEditComponent from "./UI/SuperadminEditComponent";
import { Superadmin } from "model/Superadmin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    headerContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "17px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingBottom: "25px",
      },
    },
    paginationContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "3px",
        paddingRight: "37px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "5px",
        paddingRight: "59px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "7px",
        paddingRight: "79px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "10px",
        paddingRight: "120px",
      },
    },
    table: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "25px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "33px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "50px",
      },
    }
  })
);

export const Superadmins: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const superadmins = useSelector<RootState, Array<Superadmin>>(state => state.superadmins.superadmins);
  const loading = useSelector<RootState, boolean>(state => state.superadmins.loading);
  const [filteredSuperadmins, setFilteredSuperadmins] = useState<Array<Superadmin>>(superadmins);
  const [searchText, setSearchText] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const [showSuperadminAddForm, setShowSuperadminAddForm] = useState(false);
  const [showSuperadminEditForm, setShowSuperadminEditForm] = useState(false);
  const [editedSuperadmin, setEditedSuperadminId] = useState<Superadmin>();

  const filter = (text: string): Array<Superadmin> => {
    const filteredSuperadminsAux = superadmins.filter((superadmin) => {
      const name = `${superadmin.firstName.toLowerCase()} ${superadmin.lastName.toLowerCase()}`;
      return (
        text.length === 0 || name.indexOf(text.toLowerCase()) > -1
      );
    });
    
    return filteredSuperadminsAux; 
  };

  const changeFilteredSuperadmins = (filteredSuperadmins: Array<Superadmin>) => {
    setFilteredSuperadmins(filteredSuperadmins);
    setIsEmpty(filteredSuperadmins.length === 0);
  };

  const handleActive = (superadminId: number, active: boolean) => {
    dispatch(activeThunk(superadminId, active));
  };

  const addSuperadminHandler = () => {
    setShowSuperadminAddForm(true);
    setShowSuperadminEditForm(false);
  };

  const editSuperadminHandler = (superadmin: Superadmin) => {
    setEditedSuperadminId(superadmin);
    setShowSuperadminEditForm(true);
    setShowSuperadminAddForm(false);
  };

  const handlebackForm = () => {
    setShowSuperadminAddForm(false);
    setShowSuperadminEditForm(false);
  };

  const handleSaveSuperadmin = (superadmin: Superadmin) => {
    dispatch(saveThunk(superadmin));
  };

  useNonInitialEffect(() => {
    changeFilteredSuperadmins(filter(searchText));
  }, [superadmins]);

  useNonInitialEffect(() => {
    changeFilteredSuperadmins(filter(searchText));
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchThunk());
  }, []);

  const header = (
    <HeaderComponent
      title="Super Admins"
      onSearch={setSearchText}
      onClickButton={addSuperadminHandler}
      searchPlaceholder="Search by first or last name"
      buttonLabel="Add Superadmin"
    />
  );

  const table = (
    <Table 
      head={<TableHeader />}
      body={<TableBody 
        superadmins={filteredSuperadmins} 
        activate={(superadminId, a) => handleActive(superadminId, a)} 
        onEditSuperadmin={(superadmin) => editSuperadminHandler(superadmin)}
      />}
      className={classes.table}
    />
  );

  const superadminsAddForm = (
    <SuperadminEditComponent
      title="Add Superadmin"
      onBack={handlebackForm}
      onSave={handleSaveSuperadmin}
    />
  );

  const superadminsEditForm = (
    <SuperadminEditComponent
      superadmin={editedSuperadmin}
      title="Edit Superadmin"
      onBack={handlebackForm}
      onSave={handleSaveSuperadmin}
    />
  );
    
  const empty = <TableMessage title="Not Found!" description="Superadmins not found." />;

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        {!showSuperadminAddForm && !showSuperadminEditForm &&
        <Box className={classes.headerContainer}>  
          {header}
        </Box>}
        {!showSuperadminAddForm && !showSuperadminEditForm && (loading || !isEmpty ? table : empty)}
        {showSuperadminAddForm && superadminsAddForm}
        {showSuperadminEditForm && superadminsEditForm}
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Superadmins;
