import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { Terminal } from "model/TerminalOrder";
import { wbp, reduceResolution } from "Theme";
import { TerminalStatus } from "components/Terminals/UI/TerminalStatus";
import TerminalActive from "components/Terminals/UI/TerminalActive";
interface TerminalRowProps {
  terminal: Terminal;
  marketplaceId: number;
  //
  onShow: (terminal: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline",
      },
    },
    cell: {
      paddingLeft: "0px",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        paddingTop: `${reduceResolution(20)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },

    cellName: {
      paddingLeft: "0px",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(100)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "100px",
      },
    },

    cellSerial: {
      width: "25%",
    },

    buttonContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "3px",
      },
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#DBDAD7",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(109)}px`,
        height: `${reduceResolution(25)}px`,
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "109px",
        height: "25px",
        fontSize: "14px",
      },
    },
  })
);

export const TerminalRow: FunctionComponent<TerminalRowProps> = (props) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.cellName)} onClick={() => props.onShow(props.terminal.id)}>
        {props.terminal.name}
      </TableCell>
      <TableCell className={clsx(classes.cell)} onClick={() => props.onShow(props.terminal.id)}>
        {props.terminal.terminalModelId.search('Cuddles') > 0 ? "" : "Cuddles "}
        {props.terminal.terminalModelId.toUpperCase()}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cellSerial)} onClick={() => props.onShow(props.terminal.id)}>
        {props.terminal.serial}
      </TableCell>
      <TableCell className={clsx(classes.cell)} onClick={() => props.onShow(props.terminal.id)}>
        {props.terminal.address ? props.terminal.address.city : ""}
      </TableCell>
      <TableCell className={clsx(classes.cell)} onClick={() => props.onShow(props.terminal.id)}>
        <TerminalStatus terminalStatus={props.terminal.status} />
      </TableCell>
      <TableCell className={clsx(classes.cell, { [classes.buttonContainer]: props.terminal.active })}>
        <TerminalActive terminalStatus={props.terminal.active} />
      </TableCell>
    </TableRow>
  );
};

export default TerminalRow;
