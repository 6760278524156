import { OrdersState, OrdersStatus } from "./OrdersState";
import {
  ORDERS_RESET_ACTION_TYPE,
  ORDERS_FETCH_START_ACTION_TYPE,
  ORDERS_FETCH_SUCCESS_ACTION_TYPE,
  ORDERS_ORDER_GET_START_ACTION_TYPE,
  ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE,
  ORDERS_TERMINAL_GET_START_ACTION_TYPE,
  ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE,
  ORDERS_SAVE_START_ACTION_TYPE,
  ORDERS_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_TERMINAL_SAVE_START_ACTION_TYPE,
  ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_REFUND_SAVE_START_ACTION_TYPE,
  ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_ERROR_ACTION_TYPE,
  OrdersAction,
} from "actions/orders/OrdersActionsTypes";
import { Reducer } from "redux";

const initialState: OrdersState = {
  orders: [],
  order: undefined,
  payment: undefined,
  terminal: undefined,
  status: OrdersStatus.Initial,
  loading: false,
};

export const ordersReducer: Reducer<OrdersState, OrdersAction> = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case ORDERS_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.Fetching,
        loading: true,
      };
    case ORDERS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        orders: [...action.payload.orders],
        status: OrdersStatus.FetchSuccess,
        loading: false,
      };
    case ORDERS_ORDER_GET_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.gettingOrder,
        loading: true,
      };
    case ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        order: action.payload.order,
        status: OrdersStatus.getOrderSucess,
        loading: false,
      };
    case ORDERS_TERMINAL_GET_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.gettingTerminal,
        loading: true,
      };
    case ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        terminal: action.payload.terminal,
        status: OrdersStatus.getTerminalSucess,
        loading: false,
      };
    case ORDERS_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.Saving,
        loading: true,
      };
    case ORDERS_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.SaveSuccess,
        loading: false,
      };
    case ORDERS_TERMINAL_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.SavingTerminal,
        loading: true,
      };
    case ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.SaveTerminalSuccess,
        loading: false,
      };
    case ORDERS_REFUND_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.SavingRefund,
        loading: true,
      };
    case ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        payment: action.payload.payment,
        status: OrdersStatus.SaveRefundSuccess,
        loading: false,
      };
    case ORDERS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: OrdersStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default ordersReducer;
