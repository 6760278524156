import { createStore, Store, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { RootAction } from "actions/ActionTypes";
import { LoginState } from "reducers/login/LoginState";
import loginReducer from "reducers/login/LoginReducer";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import alertsReducer from "reducers/alerts/AlertsReducer";
import { AlertsState } from "reducers/alerts/AlertsState";
import { ClientsState } from "reducers/clients/ClientsState";
import { BusinessesState } from "reducers/businesses/BusinessesState";
import { SuperadminsState } from "reducers/superadmins/SuperadminsState";
import { UsersState } from "reducers/users/UsersState";
import { OrdersState } from "reducers/orders/OrdersState";
import { MasterdataState } from "reducers/masterdata/MasterdataState";
import { NotificationsState } from "reducers/notifications/NotificationsState";
import { composeWithDevTools } from 'redux-devtools-extension';
import clientsReducer from "reducers/clients/ClientsReducer";
import businessesReducer from "reducers/businesses/BusinessesReducer";
import superadminsReducer from "reducers/superadmins/SuperadminsReducer";
import usersReducer from "reducers/users/UsersReducer";
import ordersReducer from "reducers/orders/OrdersReducer";
import masterdataReducer from "reducers/masterdata/MasterdataReducer";
import notificationsReducer from "reducers/notifications/NotificationsReducer";

export const history = createBrowserHistory();

export interface RootState {
  readonly login: LoginState;
  readonly alerts: AlertsState;
  readonly clients: ClientsState;
  readonly superadmins: SuperadminsState;
  readonly businesses: BusinessesState;
  readonly users: UsersState;
  readonly masterdata: MasterdataState;
  readonly notifications: NotificationsState;
  readonly orders: OrdersState;
}

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    alerts: alertsReducer,
    clients: clientsReducer,
    superadmins: superadminsReducer,
    businesses: businessesReducer,
    users: usersReducer,
    masterdata: masterdataReducer,
    notifications: notificationsReducer,
    orders: ordersReducer,
  });

const middleware = applyMiddleware(
  thunk,
  routerMiddleware(history)
);

const composeEnhancers = composeWithDevTools({});

export const store: Store<RootState, RootAction> = createStore(
  createRootReducer(history),
  composeEnhancers(middleware)
);

export default store;
