import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& .MuiTableRow-root" : { 
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "8px",
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "12px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "17px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "75px",
        },
      }
    },
    cell: {
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "5px",
        paddingTop: "5px",
        fontSize: "5px",
        paddingLeft: "28px"
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "8px",
        paddingTop: "8px",
        fontSize: "8px",
        paddingLeft: "45px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "11px",
        paddingTop: "11px",
        fontSize: "11px",
        paddingLeft: "60px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "17px",
        paddingTop: "17px",
        fontSize: "16px",
        paddingLeft: "90px"
      },
    },
    email: {
      width: "29%",
    },
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.table}>
      <TableRow>
        <TableCell className={classes.cell}>
          First Name
        </TableCell>
        <TableCell className={classes.cell}>
          Last Name
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.email)}>
          Email
        </TableCell>
        <TableCell className={classes.cell}>
          Status
        </TableCell>
        <TableCell className={classes.cell}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
