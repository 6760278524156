import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography, Avatar } from "@material-ui/core";
import clsx from "clsx";
import { BusinessStaff } from "model/Business";
import { Button } from "components/UI";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { formatPhoneNumber } from "utils/PhoneUtils";
import ChipMUI from "@material-ui/core/Chip";
import { impersonateThunk } from "actions/impersonation/ImpersonationActions";

interface Props {
  staff: BusinessStaff;
  marketplaceId: number;
  onShow: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline",
      },
    },
    cell: {
      paddingLeft: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        paddingTop: "17px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    staffName: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "41px",
        fontSize: "9px",
        lineHeight: "7px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "65px",
        fontSize: "11px",
        lineHeight: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "87px",
        fontSize: "14px",
        lineHeight: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "132px",
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "7px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "row",
    },
    avatarContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "2px",
        height: "7px !important",
        width: "7px !important",
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "4px",
        height: "11px !important",
        width: "11px !important",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "5px",
        height: "15px !important",
        width: "15px !important",
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "8px",
        height: "22px !important",
        width: "22px !important",
      },
    },
    reporterName: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    loginButton: {
      "& .MuiButton-startIcon": {
        [theme.breakpoints.down("sm")]: {
          marginRight: "1px",
        },
        [theme.breakpoints.only("md")]: {
          marginRight: "1px",
        },
        [theme.breakpoints.only("lg")]: {
          marginRight: "2px",
        },
        [theme.breakpoints.only("xl")]: {
          marginRight: "4px",
        },
      },
      "& .MuiButton-label": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "8px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "11px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "31px",
        height: "15px",
      },
      [theme.breakpoints.only("md")]: {
        width: "50px",
        height: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "66px",
        height: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        height: "49px",
      },
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "3px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "3px",
      },
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#DBDAD7",
      [theme.breakpoints.down("sm")]: {
        width: "33px",
        height: "8px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "70px",
        height: "12px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "84px",
        height: "17px",
        fontSize: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "109px",
        height: "25px",
        fontSize: "14px",
      },
    },
  })
);

export const StaffRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const impersonateHandler = (userId: number, marketplaceId: number) => {
    dispatch(impersonateThunk(userId, marketplaceId));
  };

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.staffName)} onClick={() => props.onShow(props.staff.id)}>
        <Box className={classes.avatarContainer}>
          <Box className={classes.avatarContent}>
            <Avatar src="" className={classes.avatar} />
          </Box>
          <Typography noWrap={true} className={classes.reporterName}>
            {props.staff.firstName} {props.staff.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)} onClick={() => props.onShow(props.staff.id)}>
        {props.staff.role && props.staff.role.name}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)} onClick={() => props.onShow(props.staff.id)}>
        {props.staff.accessLevel && props.staff.accessLevel.name}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)} onClick={() => props.onShow(props.staff.id)}>
        {props.staff.email}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.text)} onClick={() => props.onShow(props.staff.id)}>
        {formatPhoneNumber(props.staff.phone)}
      </TableCell>
      <TableCell className={clsx(classes.cell, { [classes.buttonContainer]: props.staff.active })}>
        {props.staff.active ? (
          <Button
            startIcon={faUser}
            label="Login"
            onClick={() => impersonateHandler(props.staff.userId, props.marketplaceId)}
            variant="primary"
            size="medium"
            className={classes.loginButton}
          />
        ) : (
          <ChipMUI
            label="Suspended"
            className={classes.chip}
            clickable={false}
            onClick={() => props.onShow(props.staff.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default StaffRow;
