import React, { FunctionComponent } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { User } from "model/User";
import { Button } from "components/UI";
import { ButtonSuspend } from "components/Users/UI/ButtonSuspend";

interface Props {
  user?: User;
  selected: boolean;
  loadingResetPassword: boolean;
  onResetPassword: (userId: number | undefined) => void;
  onSave: () => void;
  onActivateUser: (active: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center"
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        marginTop: "18px",
        marginBottom: "8px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "29px",
        marginBottom: "13px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "38px",
        marginBottom: "17px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "58px",
        marginBottom: "26px"
      },
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid #F1F1F1",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "15px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "48px",
      },
    },
    buttonContent: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7px",
        width: "70px",
        height: "14px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "12px",
        width: "113px",
        height: "23px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "16px",
        width: "151px",
        height: "31px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "24px",
        width: "229px",
        height: "47px"
      },
    },
    title: {
      color: "#919191",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        marginBottom: "3px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
        marginBottom: "4px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "10px",
        marginBottom: "5px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        marginBottom: "8px"
      },
    },
    data: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        marginBottom: "10px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        marginBottom: "16px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        marginBottom: "21px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        marginBottom: "32px"
      },
    }
  })
);

export const UserComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const buttonReset: JSX.Element = (
    <Box className={classes.buttonContent}>
      <Button
        label="Reset Password"
        color="black"
        variant="secondary"
        onClick={() => props.onResetPassword(props.user?.userId)}
        loading={props.loadingResetPassword}
      />
    </Box>
  );

  const buttonActivate: JSX.Element = (
    <Box className={classes.buttonContent}>
      <ButtonSuspend
        label={props.user?.active ? "Suspend User" : "Activate User"}
        type={props.user?.active ?  "suspend" : "activate"}
        variant="secondary"
        onClick={() => props.onActivateUser(props.user?.active! ? true : false)}
      />
    </Box>
  );

  const buttonUpdate: JSX.Element = (
    <Box className={classes.buttonContent}>
      <Button
        label="Update"
        color="black"
        variant="secondary"
        onClick={() => props.onSave()}
        loading={props.loadingResetPassword}
      />
    </Box>
  );


  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box className={classes.dataContainer}>
          <Typography className={classes.title}>FULL NAME</Typography>
          <Typography className={classes.data}>{props.user?.firstName} {props.user?.lastName}</Typography>
          <Typography className={classes.title}>EMAIL</Typography>
          <Typography className={classes.data}>{props.user?.email}</Typography>
          <Typography className={classes.title}>PHONE NUMBER</Typography>
          <Typography className={classes.data}>{props.user?.phone}</Typography>
        </Box>
        <Box className={classes.buttonsContainer}>
          {buttonReset}
          {buttonActivate}
          {buttonUpdate}
        </Box>
      </Box>
    </Box >
  );
};

export default UserComponent;
