import { SuperadminsState, SuperadminsStatus } from "./SuperadminsState";
import {
  SUPERADMINS_RESET_ACTION_TYPE,
  SUPERADMINS_FETCH_START_ACTION_TYPE,
  SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE,
  SUPERADMINS_SAVE_START_ACTION_TYPE,
  SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE,
  SUPERADMINS_ERROR_ACTION_TYPE,
  SuperadminsAction,
} from "actions/superadmins/SuperadminsActionsTypes";
import { Reducer } from "redux";
import { CardActions } from "@material-ui/core";

const initialState: SuperadminsState = {
  superadmins: [],
  status: SuperadminsStatus.Initial,
  loading: false,
};

export const superadminsReducer: Reducer<SuperadminsState, SuperadminsAction> = (state = initialState, action) => {
  switch (action.type) {
    case SUPERADMINS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case SUPERADMINS_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: SuperadminsStatus.Fectching,
        loading: true,
      };
    case SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        superadmins: [...action.payload.superadmins],
        status: SuperadminsStatus.FetchSuccess,
        loading: false,
      };
    case SUPERADMINS_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: SuperadminsStatus.Saving,
        loading: true,
      };
    case SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE:
      const newSuperadmin = !state.superadmins.some((superadmin) => superadmin.id === action.payload.superadmin.id);

      return {
        ...state,
        superadmins: newSuperadmin
          ? [...state.superadmins, action.payload.superadmin]
          : state.superadmins.map((superadmin) =>
              superadmin.id === action.payload.superadmin.id ? { ...action.payload.superadmin } : { ...superadmin }
            ),
        status: SuperadminsStatus.SaveSuccess,
        loading: false,
      };
    case SUPERADMINS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: SuperadminsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default superadminsReducer;
