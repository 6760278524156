import { Moment } from "moment-timezone";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faHandHoldingUsd,
  faCreditCard,
  faBan,
  faDollarSign,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faGooglePay,
  faApplePay,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";

export interface marketplacesOrders {
  orders: Array<Order>;
};

export interface Order {
  active: boolean,
  adminPayments: Array<Payment>,
  courier: string,
  createAt: Moment,
  deliveryAddress: string;
  deliveryCity: string;
  deliveryCountry: string;
  deliveryName: string;
  deliveryPhone: string;
  deliveryState: string;
  deliverySuite: string;
  deliveryZipcode: string;
  id: number;
  number: string;
  marketplace?: Marketplace;
  marketplaceId: number;
  notes?: string;
  price: string;
  status: string;
  terminals: Array<Terminal>;
  trackingNumber: string;
  uuid: string;
};

export interface Marketplace {
  email: string;
  phone: string;
}
export interface Payment {
  adminRefunds?: Array<Refund>;
  id: number;
  paymentMethodId: string;
  cardDigits: string;
  clientName: string;
};
export interface Terminal {
  active: boolean;
  address: Address;
  addressId: number;
  businessProfileId: number;
  createdAt: Moment;
  id: number;
  marketplaceId: number;
  model: string;
  name: string;
  poi: string;
  serial: string;
  status: string;
  terminalModelId: string;
  terminalOrderId: number;
  updatedAt: Moment;
  uuid: string;
};

export interface Address {
  city: string;
}
export interface Refund {
  adminInvoiceId?: number;
  adminPaymentId: number;
  amount: string;
  createAt?: Moment;
  id?: number;
  pspReference?: string;
  reason: string;
  statusId?: string;
  updateAt?: Moment;
  uuid?: string;
};

export interface TerminalModel {
  id: string,
  name: string,
  description: string,
  price: string,
  type: string,
};

export const paymentMethodIcons: Map<String, IconDefinition> = new Map([
  ["cash", faHandHoldingUsd],
  ["credit_card", faCreditCard],
  ["free_of_charge", faBan],
  ["other", faDollarSign],
  ["google_pay", faGooglePay],
  ["apple_pay", faApplePay],
  ["mc", faCcMastercard],
  ["amex", faCcAmex],
  ["visa", faCcVisa],
]);

export const paymentMethodIconsColor: Map<String, string> = new Map([
  ["cash", "#7A7A7A"],
  ["credit_card", "#7A7A7A"],
  ["free_of_charge", "#000000"],
  ["other", "#7A7A7A"],
  ["google_pay", "#000000"],
  ["apple_pay", "#000000"],
  ["mc", "#F79E1B"],
  ["amex", "#7A7A7A"],
  ["visa", "#1B4F80"],
]);

export enum statusOrder {
  NEW = "new",
  ORDER_PLACED = "open",
  IN_PROGRESS = "in_progress",
  SHIPPED = "out_for_delivery",
  RECEIVED = "received",
  CANCELLED = "cancelled",
};

export enum statusTerminal {
  NEW = "new",
  READY = "ready",
  IN_USE = "in_use",
  SUPPORT = "support",
  DELETED = "deleted",
  DECOMISSIONED = "decomissioned",
};
