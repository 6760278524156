import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Routes from "routes/Routes";
import { Container, CssBaseline, Box } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { cuddlesTheme } from "Theme";
import { ConnectedRouter } from "connected-react-router";
import { history } from "store";
import { SnackbarProvider } from "notistack";
import Alerts from "components/Alerts";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Header from "components/Header";
import { useAuth } from "hooks";
import { getThunk as getMasterdataThunk } from "actions/masterdata/MasterdataActions";
import { MasterdataStatus } from "reducers/masterdata/MasterdataState";
import { Spinner } from "components/UI";
import { useLocation } from "react-router";
import { isPrivatePath } from "routes/Paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    header: {
      display: "flex",
    },
    content: {
      display: "flex",
      flexGrow: 1,
    },
    spinner: {
      marginTop: "100px",
    },
  })
);

const App: FunctionComponent = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const auth = useAuth();
  const masterdataLoaded = useSelector<RootState, MasterdataStatus>((state) => state.masterdata.status) === MasterdataStatus.GetSuccess;
  const isPublic =  window.location.pathname.indexOf("login") > -1 || window.location.pathname.indexOf("logout") > -1;

  useEffect(()=>{
    if(auth.signedIn && !masterdataLoaded) {
      dispatch(getMasterdataThunk());
    }
  }, []);

  return (
    <MuiThemeProvider theme={cuddlesTheme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={10}>
        <ConnectedRouter history={history}>
          {auth.signedIn && !isPublic && !masterdataLoaded && <Spinner/>}
          {(!auth.signedIn || isPublic || masterdataLoaded) && <Container maxWidth={false} disableGutters={true} className={classes.container}>
            <Box className={classes.header}>
              <Header />
            </Box>
            <Box className={classes.content}>
              <Routes />
              <Alerts />
            </Box>
          </Container>}
        </ConnectedRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default App;
