import React, { FunctionComponent, Fragment, useState, MouseEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Avatar, Typography, ClickAwayListener } from "@material-ui/core";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks";
import { Auth } from "model/Auth";
import { LogoutPath } from "routes/Paths";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      backgroundColor: "white",
    },
    avatar: {
      cursor: "pointer",
      border: "1px solid white",
      zIndex: 10,
      [theme.breakpoints.down("md")]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "32px",
        height: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "49px",
        height: "49px",
      },
      "&:hover": {
        border: "1px solid black",
      },
    },
    border: {
      border: "1px solid black",
    },
    dropdown: {
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      top: "50%",
      zIndex: 5,
      border: "1px solid black",
      backgroundColor: "white",
      boxShadow: "0p 8px 10px rgba(0, 0, 0, 0.08)",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        left: "-53px",
        width: "130px",
        borderRadius: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        left: "-70px",
        width: "173px",
        borderRadius: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        left: "-106px",
        width: "262px",
        borderRadius: "24px",
      },
    },
    headerContainer: {
      backgroundColor: "#F7F7F7",
      width: "100%",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        paddingTop: "21px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "28px",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "42px",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      },
    },
    name: {
      textAlign: "center",
      fontWeight: 600,
      width: "100%",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        lineHeight: "15px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        lineHeight: "19px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    email: {
      textAlign: "center",
      fontWeight: 400,
      width: "100%",
      [theme.breakpoints.down("md")]: {
        fontSize: "8px",
        lineHeight: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "10px",
        lineHeight: "19px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        lineHeight: "27px",
      },
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      [theme.breakpoints.down("md")]: {
        paddingTop: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "9px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "14px",
      },
    },
    footerContainer: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "15px",
        paddingBottom: "15px",
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
      },
    },
    logout: {
      textAlign: "center",
      width: "100%",
      color: "#EAB464",
      fontWeight: 500,
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "9px",
        lineHeight: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "10px",
        lineHeight: "19px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        lineHeight: "27px",
      },
      "&:hover": {
        fontWeight: 600,
        textDecoration: "underline",
      },
    },
  })
);

export const ProfileDropdown: FunctionComponent = () => {
  const classes = useStyles();

  const auth: Auth = useAuth();
  const name: string | undefined = auth.user && auth.user.firstName;
  const email: string | undefined = auth.user && auth.user.email;
  
  const avatar: string | undefined = auth.user && auth.user.avatar;

  const [showDropdown, setShowDropdown] = useState(false);

  const history = useHistory();

  const clickAvatarHandler = (event: MouseEvent<HTMLElement>) => {
    setShowDropdown((prev) => !prev);
  };

  const clickAwayHandler = () => {
    setShowDropdown(false);
  };

  const clickLogoutHandler = () => {
    setShowDropdown(false);
    history.push(LogoutPath);
  };

  const header = (
    <Box className={classes.headerContainer}>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.email}>{email}</Typography>
    </Box>
  );

  const body = (
    <Box className={classes.bodyContainer}>

    </Box>
  );

  const footer = (
    <Box className={classes.footerContainer}>
      <Typography className={classes.logout} onClick={clickLogoutHandler}>
        Log out
      </Typography>
    </Box>
  );

  const dropdown = (
    <Box className={classes.dropdown}>
      {header}
      {body}
      {footer}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Box className={classes.container}>
        <Avatar
          alt={name}
          src={avatar}
          className={clsx(classes.avatar, { [classes.border]: showDropdown })}
          onClick={clickAvatarHandler}
        />
        {showDropdown && dropdown}
      </Box>
    </ClickAwayListener>
  );
};

export default ProfileDropdown;
