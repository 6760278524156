import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import OrdersComponent from "components/Orders";

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      justifyContent: "center",
      background: "white",
      height: "100%",
      width: "100%",
    },
  })
);

export const Orders: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <OrdersComponent />
    </Box>
  );
};

export default Orders;
