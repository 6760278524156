import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FieldError } from "@spike/model";
import { useFieldStyles } from "./FieldStyles";

export interface LabelFieldProps {
  label?: string | JSX.Element;
  required?: boolean;
  errors?: Array<FieldError>;
  name?: string;
}

export const LabelField: FunctionComponent<LabelFieldProps> = (props) => {
  const classes = useFieldStyles();

  const hasError = props.errors?.some((error) => error.fieldName === props.name);
  const label = hasError ? props.errors?.find((error) => error.fieldName === props.name)!.errorMessage : props.label;

  return props.label ? (
    <Box className={classes.labelContainer}>
      <Typography className={clsx(classes.label, { [classes.labelError]: hasError })}>{label}</Typography>
      {props.required && !hasError &&<Typography className={classes.required}>*</Typography>}
    </Box>
  ) : null;
};

export default LabelField;
