import _ from "lodash";

export const isPhoneNumberValid = (phoneNumber: string) => {
  return (phoneNumber && phoneNumber.replace(" ", "").replace("(", "").replace(")", "").replace("-", "").trim().length === 10);
}

export const isEmailValid = (email: string) => {
  const regExp = /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
  return (email && email.match(regExp) !== null);
}

export const validateRequired = (fieldName: string, formData: any, errorFieldName?: string) => {
  const attributes = fieldName.split(".");
  const [lastAttribute] = fieldName.split(".").map(a => _.lowerCase(a)).reverse();
  const capitalizedLastAttribute = lastAttribute.split(" ").map(w => _.capitalize(w)).join(" ");

  let data: any = { ...formData };

  attributes.forEach((attribute) => {
    data = data[attribute];
  });

  return !data || data.length === 0
    ? { fieldName, errorMessage: `${errorFieldName || capitalizedLastAttribute} is required` }
    : undefined;
};
