import React, { FunctionComponent, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {
  LoginPath,
  LogoutPath,
  SuperadminsPath,
  BusinessPath,
  HomePath,
  InsightsPath,
  OrdersPath,
} from "./Paths";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Superadmins from "pages/Superadmins";
import Home from "pages/Home";
import Business from "pages/Business";
import Insights from "pages/Insights";
import Orders from "pages/Orders";

export const Routes: FunctionComponent = () => {
  return (
    <Fragment>
      <Switch>

        <Route path={LoginPath} component={Login} />
        <Route path={LogoutPath} component={Logout} />

        <PrivateRoute path={InsightsPath} component={Insights} />
        <PrivateRoute path={OrdersPath} component={Orders} />
        <PrivateRoute path={SuperadminsPath} component={Superadmins} />
        <PrivateRoute path={BusinessPath} component={Business} />
        <PrivateRoute path={HomePath} component={Home} />

        <Redirect to={HomePath} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
