import { ClientsState, ClientsStatus } from "./ClientsState";
import {
  CLIENTS_RESET_ACTION_TYPE,
  CLIENTS_FETCH_START_ACTION_TYPE,
  CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
  CLIENTS_SAVE_START_ACTION_TYPE,
  CLIENTS_SAVE_SUCCESS_ACTION_TYPE,
  CLIENTS_GET_OVERVIEW_START_ACTION_TYPE,
  CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE,
  CLIENTS_ERROR_ACTION_TYPE,
  ClientsAction,
} from "actions/clients/ClientsActionsTypes";
import { Reducer } from "redux";

const initialState: ClientsState = {
  clients: [],
  overview: undefined,
  status: ClientsStatus.Initial,
  loading: false,
};

export const clientsReducer: Reducer<ClientsState, ClientsAction> = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case CLIENTS_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: ClientsStatus.Fectching,
        loading: true,
      };
    case CLIENTS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        clients: [...action.payload.clients],
        status: ClientsStatus.FetchSuccess,
        loading: false,
      };
    case CLIENTS_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: ClientsStatus.Saving,
        loading: true,
      };
    case CLIENTS_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        clients: state.clients.map(client => client.id === action.payload.client.id ? action.payload.client : client),
        status: ClientsStatus.SaveSuccess,
        loading: false,
      };
    case CLIENTS_GET_OVERVIEW_START_ACTION_TYPE:
      return {
        ...state,
        status: ClientsStatus.GettingOverview,
        loading: true,
      };
    case CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        overview: { ...action.payload.overview },
        status: ClientsStatus.GetOverviewSuccess,
        loading: false,
      };
    case CLIENTS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ClientsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default clientsReducer;
