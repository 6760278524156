import {
  MasterdataAction,
  MASTERDATA_RESET_ACTION_TYPE,
  MASTERDATA_GET_SUCCESS_ACTION_TYPE,
  MASTERDATA_GET_START_ACTION_TYPE,
  MASTERDATA_ERROR_ACTION_TYPE,
} from "./MasterdataActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { MasterdataState } from "reducers/masterdata/MasterdataState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { Masterdata } from "model/Masterdata";
import { alertErrorHandler } from "actions/ActionUtils";
import { MasterdataDto } from "./MasterdataDtos";
import { convert } from "./MasterdataConverter";

const masterdataUrl = "admin/master_data";

export const getThunk = (): ThunkAction<void, MasterdataState, null, MasterdataAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(getting());

    try {
      const response: AxiosResponse<MasterdataDto> = await ApiClient.get(masterdataUrl, createTokenConfig());
      dispatch(getSuccess(response.data));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error getting masterdata.`);
    }
  };
};

export const reset = (): MasterdataAction => {
  return {
    type: MASTERDATA_RESET_ACTION_TYPE,
  };
};

const getting = (): MasterdataAction => {
  return {
    type: MASTERDATA_GET_START_ACTION_TYPE,
  };
};

export const getSuccess = (masterdataDto: MasterdataDto): MasterdataAction => {
  return {
    type: MASTERDATA_GET_SUCCESS_ACTION_TYPE,
    payload: {
      masterdata: convert(masterdataDto),
    },
  };
};

const error = (): MasterdataAction => {
  return {
    type: MASTERDATA_ERROR_ACTION_TYPE,
  };
};
