import {
  SuperadminsAction,
  SUPERADMINS_RESET_ACTION_TYPE,
  SUPERADMINS_FETCH_START_ACTION_TYPE,
  SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE,
  SUPERADMINS_SAVE_START_ACTION_TYPE,
  SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE,
  SUPERADMINS_ERROR_ACTION_TYPE,
} from "./SuperadminsActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { SuperadminsState } from "reducers/superadmins/SuperadminsState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { alertErrorHandler } from "actions/ActionUtils";
import { Superadmin } from "model/Superadmin";
import { SuperadminDto } from "./SuperadminsDtos";
import { convertSuperadmin } from "./SuperadminsConverter";

const superadminsUrl = "super_admins";

export const fetchThunk = (): ThunkAction<void, SuperadminsState, null, SuperadminsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(fetching());
    try {
      const response: AxiosResponse<Array<SuperadminDto>> = await ApiClient.get(superadminsUrl, createTokenConfig());
      dispatch(fetchSuccess(response.data.map((dto) => convertSuperadmin(dto))));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error fetching superadmins.");
    }
  };
};

export const activeThunk = (
  superadminId: number,
  active: boolean
): ThunkAction<void, SuperadminsState, null, SuperadminsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saving());

    const url = `${superadminsUrl}/${superadminId}`;
    const body = {
      super_admin: {
        active,
      },
    };

    try {
      const response: AxiosResponse<SuperadminDto> = await ApiClient.patch(url, body, createTokenConfig());
      dispatch(saveSuccess(convertSuperadmin(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(
        apiError,
        dispatch,
        `Error ${active ? "activating" : "inactivating"} superadmin id = ${superadminId}`
      );
    }
  };
};

export const saveThunk = (
  superadmin: Superadmin
): ThunkAction<void, SuperadminsState, null, SuperadminsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saving());

    const url = superadmin.id ? `${superadminsUrl}/${superadmin.id}` : superadminsUrl;
    const body = {
      super_admin: {
        first_name: superadmin.firstName,
        last_name: superadmin.lastName,
        email: superadmin.email,
        active: superadmin.status.id === 1,
        password: superadmin.password ? superadmin.password : undefined
      },
    };
    const apiMethod = superadmin.id ? ApiClient.patch : ApiClient.post;

    try {
      const response: AxiosResponse<SuperadminDto> = await apiMethod(url, body, createTokenConfig());
      dispatch(saveSuccess(convertSuperadmin(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error saving superadmin ${superadmin.firstName} ${superadmin.lastName}`);
    }
  };
};

export const reset = (): SuperadminsAction => {
  return {
    type: SUPERADMINS_RESET_ACTION_TYPE,
  };
};

const fetching = (): SuperadminsAction => {
  return {
    type: SUPERADMINS_FETCH_START_ACTION_TYPE,
  };
};

const fetchSuccess = (superadmins: Array<Superadmin>): SuperadminsAction => {
  return {
    type: SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      superadmins,
    },
  };
};

const saving = (): SuperadminsAction => {
  return {
    type: SUPERADMINS_SAVE_START_ACTION_TYPE,
  };
};

const saveSuccess = (superadmin: Superadmin): SuperadminsAction => {
  return {
    type: SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      superadmin,
    },
  };
};

const error = (): SuperadminsAction => {
  return {
    type: SUPERADMINS_ERROR_ACTION_TYPE,
  };
};
