import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAuth } from "hooks";

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      justifyContent: "center",
      background: "white",
      height: "100%",
      width: "100%",
    },
    iframe: {
      width: "100%",
      height: "100%",
      border: "none",
    },
  })
);

export const Insights: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const auth = useAuth();

  const insightsUrl= `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_WEB_BASE_URL}admin/insights?access_token=${auth.token}`;

  return (
    <Box className={classes.container}>
      <iframe title="Insights" className={classes.iframe} src={insightsUrl}></iframe>
    </Box>
  );
};

export default Insights;

