import { showErrorAlert } from "actions/alerts/AlertsActions";

import { reset as resetAlerts } from "actions/alerts/AlertsActions";
import { reset as resetSuperadmins } from "actions/alerts/AlertsActions";
import { reset as resetBusinesses } from "actions/businesses/BusinessesActions";
import { reset as resetClients } from "actions/clients/ClientsActions";
import { reset as resetMasterdata } from "actions/masterdata/MasterdataActions";

import { Dispatch } from "redux";

const UNPROCESSABLE_ENTITY = 422;

export const alertSuccessHandler = (apiError: any, dispatch: any, genericErrorMessage: string) => {
  if (apiError.response?.status === UNPROCESSABLE_ENTITY && apiError.response?.data) {
    for (let field in apiError.response.data) {
      dispatch(showErrorAlert(`${field} ${apiError.response.data[field]}`));
    }
  } else {
    dispatch(showErrorAlert(genericErrorMessage));
  }
}

export const alertErrorHandler = (apiError: any, dispatch: any, genericErrorMessage: string) => {
  if (apiError.response?.status === UNPROCESSABLE_ENTITY && apiError.response?.data) {
    for (let field in apiError.response.data) {
      dispatch(showErrorAlert(`${field} ${apiError.response.data[field]}`));
    }
  } else {
    dispatch(showErrorAlert(genericErrorMessage));
  }
}

export const alertErrorWihtoutFieldHandler = (apiError: any, dispatch: any, genericErrorMessage: string) => {
  if (apiError.response?.status === UNPROCESSABLE_ENTITY && apiError.response?.data) {
    for (let field in apiError.response.data) {
      dispatch(showErrorAlert(`${apiError.response.data[field]}`));
    }
  } else {
    dispatch(showErrorAlert(genericErrorMessage));
  }
}

export const cleanAll = (dispatch: Dispatch<any>) => {
  dispatch(resetAlerts());
  dispatch(resetMasterdata());
  dispatch(resetSuperadmins());
  dispatch(resetBusinesses());
  dispatch(resetClients());
}
