import React, { FunctionComponent, Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Button } from "components/UI";

export interface Props {
  open: boolean;
  question: JSX.Element;
  actions?: JSX.Element;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        border: "1px solid #222222",
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("lg")]: {
          width: "361px",
          minHeight: "208px",
          borderRadius: "15px",
          paddingBottom: "52px",
          paddingTop: "4px",
          paddingRight: "6px"
        },
        [theme.breakpoints.up("xl")]: {
          width: "452px",
          minHeight: "258px",
          borderRadius: "20px",
          paddingBottom: "70px",
          paddingTop: "10px",
          paddingRight: "15px"
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        [theme.breakpoints.down("lg")]: {
          paddingBottom: "18px",
          paddingLeft: "50px",
          paddingRight: "50px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingBottom: "24px",
          paddingLeft: "67px",
          paddingRight: "67px",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "center",
      },
    },
    cancelButton: {
      color: "#EA6464",
      borderColor: "#EA6464",
      [theme.breakpoints.down("lg")]: {
        marginRight: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "16px",
      },
      "&:hover": {
        backgroundColor: "#FFF0F0"
      }
    },
    confirmButton: {
      [theme.breakpoints.down("lg")]: {
        marginLeft: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: "16px",
      },
    },
    iconClose: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "20px",
      },
      float: "right",
      "&:hover": {
        cursor: "pointer"
      }
    },
    contentContainer: {
      display: "flex",
      justifyContent: "center"
    }
  })
);

export const ConfirmDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const cancelHandler = () => {
    props.onCancel && props.onCancel();
  };

  const confirmHandler = () => {
    props.onConfirm && props.onConfirm();
  };

  return (
    <Dialog onClose={cancelHandler}open={props.open} className={classes.dialog}>
      <DialogTitle>
        <FontAwesomeIcon icon={faTimes} className={classes.iconClose} onClick={cancelHandler}/>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>{props.question}</DialogContent>
      <DialogActions>
        {props.actions ? (
          props.actions
        ) : (
          <Fragment>
            <Button
              label={props.cancelButtonLabel}
              onClick={cancelHandler}
              variant="secondary"
              size="medium"
              className={classes.cancelButton}
            />
            <Button
              label={props.confirmButtonLabel}
              onClick={confirmHandler}
              variant="danger"
              size="medium"
              className={classes.confirmButton}
            />
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
