import { ResponseUserDto, UserWorkDto } from "./UsersDtos";
import { User, UserBusiness } from "model/User";
import { store } from "store";

export const convertUser = (dto: ResponseUserDto): User => {
  return {
    userId: dto.user.id,
    staffId: dto.staff_id,
    firstName: dto.user.first_name,
    lastName: dto.user.last_name,
    email: dto.user.email,
    phone: dto.user.phone,
    businesses: dto.works.map(w => convertWork(w)),
    hasAppoinments: dto.has_appointments,
    activeAppoinments: dto.active_appointments,
    active: dto.user.active
  };
};


const convertWork = (dto: UserWorkDto): UserBusiness => {
  const masterdata = store.getState().masterdata.data!;

  return {
    workId: dto.id,
    businessId: dto.marketplace_id,
    businessName: dto.marketplace.business_name,
    businessCategories: masterdata.businessAreas.filter(ba => dto.marketplace.business_area_list.includes(ba.id)),
    role: masterdata.roles.find(r => r.id === dto.role_id)!,
    accessLevel: masterdata.accessLevels.find(al => al.id === dto.access_level_id)!,
    active: dto.active
  }
}