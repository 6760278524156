import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LoginForm from "components/Login";
import { cleanAll } from "actions/ActionUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: "1 1 auto",
      justifyContent: "center",
      background: "#fff"
    },
  })
);

export const Login: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    cleanAll(dispatch);
  }, []);

  return (
    <Box className={classes.container}>
      <LoginForm />
    </Box>
  );
};

export default Login;
