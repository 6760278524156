import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },
  })
);

export const BusinessCalendar: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      Calendar
    </Box>
  );
};

export default BusinessCalendar;
