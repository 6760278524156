import React, { FunctionComponent } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { BusinessStaff } from "model/Business";
import StaffRow from "./StaffRow";

interface Props {
  staff: Array<BusinessStaff>;
  marketplaceId: number;
  onShow: (staffId: number) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.staff.map((member, index) => (
        <StaffRow 
          key={member.id} 
          staff={member}
          marketplaceId={props.marketplaceId}
          onShow={props.onShow}
        /> 
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
