import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { Order, Terminal } from "model/TerminalOrder";
import { OrderStatus } from "../UI/OrderStatus";
import { wbp, reduceResolution } from "Theme";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import moment from "moment-timezone";

interface TableOrderRowProps {
  order: Order;
  //
  onEditOrder: (order: Order) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      textAlign: "left",
    },
    cellOrder: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(70)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "80px",
        width: "14%",
      },
    },
    cellStatus: {
      width: "11%",
    },
    email: {
      width: "29%",
    },
    cellDisabled: {
      color: "#D3D3D3"
    },
  })
);

export const TableOrderRow: FunctionComponent<TableOrderRowProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const timeZone = 'America/New_York';
  const createAt = moment(props.order.createAt!);
  const createAtFormat = createAt.tz(timeZone) ? createAt.tz(timeZone).format("MMM DD, HH:mm A").toString() : "";

  const payment = props.order.adminPayments[0];

  const checkItems = (terminals: Array<Terminal>) => {
    return <>{terminals.length > 1 ? `${terminals.length} Items` : `${terminals.length} Item`}</>
  };

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.cellOrder, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {props.order.number}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {payment ? payment.clientName : ""}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {createAtFormat}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {checkItems(props.order.terminals)}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {`$ ${Number(props.order.price)}.00`}
      </TableCell>
      <TableCell className={clsx(classes.cellStatus, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        <OrderStatus orderStatus={props.order.status} />
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {props.order.deliveryCity}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {props.order.courier && props.order.courier.length > 0 ? props.order.courier : "Pending"}
      </TableCell>
      <TableCell className={clsx(classes.cell, terminalCommonClasses.typo14_400)} onClick={() => props.onEditOrder(props.order)}>
        {props.order.trackingNumber && props.order.trackingNumber.length > 0 ? props.order.trackingNumber : "Pending"}
      </TableCell>
    </TableRow>
  );
};

export default TableOrderRow;
