import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
} from "react";
import { Box, InputAdornment, OutlinedInput } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";

interface Props {
  placeholder: string;
  className?: string;
  onSearch: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "15px",
        width: "93px",
      },
      [theme.breakpoints.only("md")]: {
        height: "24px",
        width: "150px",
      },
      [theme.breakpoints.only("lg")]: {
        height: "32px",
        width: "200px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "49px",
        width: "303px",
      },
    },
    iconContainer: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "9px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "14px",
      },
    },
    searchField: {
      height: "100%",
      width: "100%",
      borderRadius: "100px",
      backgroundColor: "#fff",
      "& .Mui-focused": {
        borderColor: "#fff !important"
      },
      "& .MuiSelect-select:focus": {
        background: "none !important",
      },
      "& .MuiInputLabel-outlined.MuiFormLabel-filled": {
        color: "#7A7A7A",
      }
    },
    pointer: {
      cursor: "pointer",
    },
    fonts: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
      },
    },
  })
);

export const SearchComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSearchText(event.currentTarget.value);
  };

  const searchBox = (
    <OutlinedInput
      placeholder={props.placeholder}
      value={searchText}
      className={clsx(classes.searchField, classes.pointer, classes.fonts)}
      onChange={changeHandler}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon
            icon={faSearch}
            className={classes.fonts}
          />
        </InputAdornment>
      }
    />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      {searchBox}
    </Box>
  );
};

export default SearchComponent;
