import {
  AlertAction,
  RESET_ALERT_ACTION_TYPE,
  ADD_ALERT_ACTION_TYPE,
  REMOVE_ALERT_ACTION_TYPE,
  InfoSeverity,
  SuccessSeverity,
  WarningSeverity,
  ErrorSeverity,
} from "./AlertsActionsTypes";
import {v4 as uuid } from "uuid";

export const reset = (): AlertAction => {
  return {
    type: RESET_ALERT_ACTION_TYPE
  };
};

export const showInfoAlert = (message: string): AlertAction => {
  return {
    type: ADD_ALERT_ACTION_TYPE,
    payload: {
      id: uuid(),
      severity: InfoSeverity,
      message,
    },
  };
};

export const showSuccessAlert = (message: string): AlertAction => {
  return {
    type: ADD_ALERT_ACTION_TYPE,
    payload: {
      id: uuid(),
      severity: SuccessSeverity,
      message,
    },
  };
};

export const showWarningAlert = (message: string): AlertAction => {
  return {
    type: ADD_ALERT_ACTION_TYPE,
    payload: {
      id: uuid(),
      severity: WarningSeverity,
      message,
    },
  };
};

export const showErrorAlert = (message: string): AlertAction => {
  return {
    type: ADD_ALERT_ACTION_TYPE,
    payload: {
      id: uuid(),
      severity: ErrorSeverity,
      message
    },
  };
};

export const removeAlert = (id: string): AlertAction => {
  return {
    type: REMOVE_ALERT_ACTION_TYPE,
    payload: {
      id
    },
  };
};
