import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
} from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchThunk, getOverviewThunk, saveThunk } from "actions/clients/ClientsActions";
import { Table, TableMessage } from "components/UI";
import { Client, ClientsOverview, SortFields, SortOrder } from "model/Client";
import { useNonInitialEffect } from "@versiondos/hooks";
import { RootState } from "store";
import HeaderComponent from "./UI/HeaderComponent";
import TableHeader from "./UI/Table/TableHeader";
import TableBody from "./UI/Table/TableBody";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    headerContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "17px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingBottom: "25px",
      },
    },
    paginationContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "3px",
        paddingRight: "37px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "5px",
        paddingRight: "59px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "7px",
        paddingRight: "79px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "10px",
        paddingRight: "120px",
      },
    },
  })
);

export const Clients: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const clients = useSelector<RootState, Array<Client>>(state => state.clients.clients);
  const overview = useSelector<RootState, ClientsOverview | undefined>(state => state.clients.overview);
  const loading = useSelector<RootState, boolean>(state => state.clients.loading);

  const [filteredClients, setFilteredClients] = useState<Array<Client>>([]);

  const [searchText, setSearchText] = useState("");

  const [isEmpty, setIsEmpty] = useState(false);

  const filter = (text: string): Array<Client> => {
    let filteredClients: Array<Client> = clients;

    if (text.length > 0) {
      filteredClients = clients.filter((client) => {
        const name = client.name.toLowerCase();
        return name.indexOf(text.toLowerCase()) > -1;
      });
    }

    return filteredClients;
  };

  const sort = (field: SortFields | undefined, order: SortOrder) => {
    if (field !== undefined) {
      let filteredClientsAux = [...filteredClients];
      filteredClientsAux.sort((client, otherClient) => {
        if (field === "terminals") {
          return (client.terminals - otherClient.terminals) * (order === SortOrder.ASCENDANT ? 1 : -1);
        } else if (field === "businessName") {
          if (client.name.toLowerCase() > otherClient.name.toLowerCase()) {
            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else if (client.name.toLowerCase() < otherClient.name.toLowerCase()) {
            return -1 * (order === SortOrder.ASCENDANT ? 1 : -1);
          } else {
            return 0;
          }
        } else {
          return 1;
        }
      });
      setFilteredClients(filteredClientsAux);
    }
  }

  const changeFilteredClients = (filteredClients: Array<Client>) => {
    setFilteredClients(filteredClients);
    setIsEmpty(filteredClients.length === 0);
  };

  const handleShowClient = (clientId: number) => {
    window.location.href = `/${clientId}/business`
  };

  const handleActive = (clientId: number, active: boolean) => {
    dispatch(saveThunk(clientId, active));
  };

  useNonInitialEffect(() => {
    changeFilteredClients(filter(searchText));
  }, [clients]);

  useNonInitialEffect(() => {
    changeFilteredClients(filter(searchText));
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchThunk());
    dispatch(getOverviewThunk());
  }, []);

  const header = (
    <HeaderComponent
      title="Clients"
      overview={overview}
      onSearch={setSearchText}
    />
  );

  const table = (
    <Table
      head={<TableHeader onSort={(field, order) => sort(field, order)} />}
      body={<TableBody
        clients={filteredClients}
        activate={(clientId, a) => handleActive(clientId, a)}
        onShowBusiness={handleShowClient}
      />}
    />
  );

  const empty = <TableMessage title="Not Found!" description="Clients not found." />;

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        <Box className={classes.headerContainer}>
          {header}
        </Box>
        {loading || !isEmpty ? table : empty}
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Clients;
