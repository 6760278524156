import {
  ClientsAction,
  CLIENTS_RESET_ACTION_TYPE,
  CLIENTS_FETCH_START_ACTION_TYPE,
  CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
  CLIENTS_SAVE_START_ACTION_TYPE,
  CLIENTS_SAVE_SUCCESS_ACTION_TYPE,
  CLIENTS_GET_OVERVIEW_START_ACTION_TYPE,
  CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE,
  CLIENTS_ERROR_ACTION_TYPE,
} from "./ClientsActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { ClientsState } from "reducers/clients/ClientsState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { Client, ClientsOverview } from "model/Client";
import { ClientsResponseDto, ClientDto, ClientsOverviewDto } from "./ClientsDtos";
import { alertErrorHandler } from "actions/ActionUtils";
import { convertClient, convertOverview  } from "./ClientsConverter";

const clientsUrl = "admin/marketplaces";
const overviewUrl = "admin/marketplaces/summary";

export const fetchThunk = (): ThunkAction<void, ClientsState, null, ClientsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(fetching());
    try {
      const response: AxiosResponse<ClientsResponseDto> = await ApiClient.get(clientsUrl, createTokenConfig());
      dispatch(fetchSuccess(response.data.marketplaces.map(dto => convertClient(dto))));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error fetching clients.");
    }
  };
};

export const saveThunk = (clientId: number, active: boolean): ThunkAction<void, ClientsState, null, ClientsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saving());

    const url = `${clientsUrl}/${clientId}`;
    const body = {marketplace: {active}};

    try {
      const response: AxiosResponse<ClientDto> = await ApiClient.patch(url, body,createTokenConfig());
      dispatch(saveSuccess(convertClient(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error saving client Id = ${clientId}`);
    }
  };
}

export const getOverviewThunk = (): ThunkAction<void, ClientsState, null, ClientsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(getOverview());
    try {
      const response: AxiosResponse<ClientsOverviewDto> = await ApiClient.get(overviewUrl, createTokenConfig());
      dispatch(getOverviewSuccess(convertOverview(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error getting clients overview.");
    }
  };
};


export const reset = (): ClientsAction => {
  return {
    type: CLIENTS_RESET_ACTION_TYPE,
  };
};

const fetching = (): ClientsAction => {
  return {
    type: CLIENTS_FETCH_START_ACTION_TYPE,
  };
};

const fetchSuccess = (clients: Array<Client>): ClientsAction => {
  return {
    type: CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      clients
    }
  };
};

const saving = (): ClientsAction => {
  return {
    type: CLIENTS_SAVE_START_ACTION_TYPE,
  };
};

const saveSuccess = (client: Client): ClientsAction => {
  return {
    type: CLIENTS_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      client
    }
  };
};

const getOverview = (): ClientsAction => {
  return {
    type: CLIENTS_GET_OVERVIEW_START_ACTION_TYPE,
  };
};

const getOverviewSuccess = (overview: ClientsOverview): ClientsAction => {
  return {
    type: CLIENTS_GET_OVERVIEW_SUCCESS_ACTION_TYPE,
    payload: {
      overview
    }
  };
};


const error = (): ClientsAction => {
  return {
    type: CLIENTS_ERROR_ACTION_TYPE,
  };
};
