import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import { Order, statusOrder } from "model/TerminalOrder";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import React from "react";
import { reduceResolution, wbp } from "Theme";
import moment from "moment-timezone";

interface OrderRefundsProps {
    order: Order;
    className?: string;
    //
    showRefund: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridButton: {
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "flex-end",
        },
        gridRefunds: {
            borderBottom: "1px solid #D4D4D4",
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(25)}px 0`,
            },
            [theme.breakpoints.up(wbp)]: {
                padding: "25px 0",
            },
        },
        gridDescription: {
            textAlign: "center",
        },
        gridAmount: {
            textAlign: "right",
        },
        boxTotal: {
            background: "#F4F3F0",
            width: "fit-content",
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(11)}px ${reduceResolution(18)}px`,
                borderRadius: `${reduceResolution(8)}px`,
                marginTop: `${reduceResolution(25)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: "8px",
                padding: "11px 18px",
                marginTop: "25px",
            },
        },

        typoAmount: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(5)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "5px",
            },
        },
    })
);

export const OrderRefunds: FunctionComponent<OrderRefundsProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();

    const timeZone = 'America/New_York';

    const payment = props.order.adminPayments[0];
    const adminRefunds = payment ? payment.adminRefunds : undefined;
    const totalRefunds = adminRefunds ? adminRefunds.reduce((total, refund) => total + Number(refund.amount), 0) : 0;

    const makeRefundHandler = () => {
        props.showRefund();
    };

    const listRefundsView = (
        adminRefunds && adminRefunds.map((refund) => {
            let createAt = moment(refund.createAt);
            let createAtFormat = createAt.tz(timeZone) ?
                createAt.tz(timeZone).format("ddd, MMM d, Y").toString()
                : "";

            return (
                <Grid key={refund.id} container className={classes.gridRefunds}>
                    <Grid item xs={4}>
                        <Typography className={terminalCommonClasses.typo16_400}>
                            {createAtFormat}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.gridDescription}>
                        <Typography className={terminalCommonClasses.typo16_400}>{
                            Number(refund.amount) === Number(props.order.price) ?
                                "Full Refund"
                                :
                                "Partial Refund"}</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.gridAmount}>
                        <Typography className={terminalCommonClasses.typo16_600}>
                            ${Number(refund.amount)}.00
                        </Typography>
                    </Grid>
                </Grid>
            );
        })
    );

    return (
        <Box className={terminalCommonClasses.box100}>
            <Grid container>
                <Grid item xs={6} className={terminalCommonClasses.flexColumn}>
                    <Typography className={clsx(terminalCommonClasses.typo18_600)}>
                        Refunds
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={5} className={classes.gridButton}>
                    {
                        props.order.status !== statusOrder.NEW &&
                        props.order.status !== statusOrder.SHIPPED &&
                        props.order.status !== statusOrder.CANCELLED &&
                        totalRefunds < Number(props.order.price) &&
                        <Button
                            label={"Make a Refund"}
                            variant={"primary"}
                            color={"black"}
                            onClick={makeRefundHandler}
                        />
                    }
                </Grid>
            </Grid>

            {adminRefunds && adminRefunds.length > 0 ?
                <Box>
                    {listRefundsView}
                    <Box className={clsx(terminalCommonClasses.flexRow, classes.boxTotal)}>
                        <Typography className={clsx(terminalCommonClasses.typo15_600)}>Total Refund:</Typography>
                        <Typography className={clsx(terminalCommonClasses.typo15_500, classes.typoAmount)}>${totalRefunds}.00</Typography>
                    </Box>
                </Box>
                :
                <Typography className={clsx(terminalCommonClasses.typo16_400)}>
                    This order has no refunds.
                </Typography>
            }
        </Box>
    );
};

export default OrderRefunds;
