import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

export interface TitleProps {
  text: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
      },
    },
  })
);

export const Title: FunctionComponent<TitleProps> = (props) => {
  const classes = useStyles();

  return <Typography className={clsx(classes.title, props.className)}>{props.text}</Typography>;
};

export default Title;
