export interface Auth {
  user?: User;
  signedIn: boolean;
  token?: string;
}

export interface User {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
}

export const logoutAuth: Auth = {
  user: undefined,
  signedIn: false,
  token: undefined
}