import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableHeaderItem from "./TableHeaderItem";
import { SortFields, SortOrder } from "model/Business";

interface Props {
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "9px",
        paddingTop: "9px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "14px",
        paddingTop: "14px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "19px",
        paddingTop: "19px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "29px",
        paddingTop: "29px",
        fontSize: "16px",
      },
    },
    staffName: {
      width: "30%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "41px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "65px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "87px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "132px",
      },
    },
    role: {
      width: "16%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
    },
    access: {
      width: "13%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
    },
    email: {
      width: "22%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
    },
    phone: {
      width: "17%",
    },
    impersonate: {
      width: "10%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "75px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "122px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "162px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "245px",
      },
    },
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields | undefined, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.cell, classes.staffName)}>
          <TableHeaderItem
            label="Name"
            sortField={SortFields.STAFF_NAME}
            sortOrder={sortField === SortFields.STAFF_NAME ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.role)}>
          <TableHeaderItem
            label="Role"
            sortField={SortFields.ROLE}
            sortOrder={sortField === SortFields.ROLE ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.access)}>
          <TableHeaderItem
            label="Access"
            sortField={SortFields.ACCESS_LEVEL}
            sortOrder={sortField === SortFields.ACCESS_LEVEL ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.email)}>
          <TableHeaderItem
            label="Email"
            sortField={SortFields.EMAIL}
            sortOrder={sortField === SortFields.EMAIL ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.phone)}>
          Phone
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.impersonate)}>
          Impersonate
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
