import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { wbp, reduceResolution } from "Theme";
interface Props {
  label: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(34)}px`,
        borderRadius: `${reduceResolution(34)}px`,
        paddingLeft: `${reduceResolution(25)}px`,
        paddingRight: `${reduceResolution(25)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "34px",
        borderRadius: "34px",
        paddingLeft: "25px",
        paddingRight: "25px",
      },
    },
    selectedContainer: {
      background: "#92B4A7",
    },
    label: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
    },
    selectedLabel: {
      color:"#ffffff",
    }
  })
);

export const SelectionBarItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const containerStyle = clsx({
    [classes.container]: true,
    [classes.selectedContainer]: props.selected,
  }, props.className);

  const labelStyle = clsx({
    [classes.label]: true,
    [classes.selectedLabel]: props.selected,
  });

  return (
    <Box className={containerStyle} onClick={props.onClick}>
      <Typography className={labelStyle}>{props.label}</Typography>
    </Box>
  );
};

export default SelectionBarItem;
