import { SuperadminDto } from "./SuperadminsDtos";
import { Superadmin } from "model/Superadmin";

export const convertSuperadmin = (dto: SuperadminDto): Superadmin => {
  return {
    id: dto.id,
    firstName: dto.first_name,
    lastName: dto.last_name,
    email: dto.email,
    avatar: dto.image,
    status: dto.active ? { id: 1, name: "Active" } : { id: 2, name: "Inactive" },
  };
};
