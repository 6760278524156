import { Auth, logoutAuth } from "model/Auth";

const authKey: string = "authAdmin";

export const saveInStorage = (auth: Auth, local: boolean): void => {
  
  if(local) {
    localStorage.setItem(authKey, JSON.stringify(auth));
  } else {
    localStorage.removeItem(authKey);
  }
  
  sessionStorage.setItem(authKey, JSON.stringify(auth));
};

export const getFromStorage = (): Auth => {
  let storedAuth: string | null = sessionStorage.getItem(authKey);
  
  if(storedAuth === null) {
    storedAuth = localStorage.getItem(authKey);
    if(storedAuth !== null) {
      sessionStorage.setItem(authKey, storedAuth);
    }
  }
  
  if (storedAuth === null) {
    return { ...logoutAuth };
  } else {
    return JSON.parse(storedAuth);
  }
};

export const removeFromStorage = (): void => {
  sessionStorage.removeItem(authKey);
  localStorage.removeItem(authKey);
};
