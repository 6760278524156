import { faHandHoldingUsd } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useNonInitialEffect from "@versiondos/hooks";
import clsx from "clsx";
import { useMasterData } from "hooks";
import { Order, paymentMethodIcons, paymentMethodIconsColor, Refund, statusOrder, TerminalModel } from "model/TerminalOrder";
import moment from "moment";
import React, { Fragment, FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrdersStatus } from "reducers/orders/OrdersState";
import { RootState } from "store";
import { reduceResolution, wbp } from "Theme";
import { formatPhoneNumber } from "utils/PhoneUtils";
import { useCommonStyles } from "../CommonStyles";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { TerminalImage } from "../UI/TerminalImage";
import OrderDelivery from "./OrderDelivery";
import OrderDetailHeader from "./OrderDetailHeader";
import OrderRefunds from "./OrderRefunds";
import OrderRefundsPopUp from "./OrderRefundsPopUp";
import {
  changeOrderStatusThunk,
  getOrderThunk,
  fetchThunk,
  saveRefundThunk,
  saveTerminalOrderThunk
} from "actions/orders/OrdersActions";
import set from "lodash/set";
import OrderCancelPopUp from "./OrderCancelPopUp";

interface OrderDetailProps {
  order: Order,
  className?: string;
  //
  onClose?: () => void;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(0)}px ${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0 30px",
      },
    },
    baseContainer: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(0)}px ${reduceResolution(70)}px 0 ${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0 70px 0 30px",
      },
    },
    textOverflow: {
    },


    boxContent: {
      background: "#FFF",
    },

    contentContainer: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(25)}px 0 ${reduceResolution(25)}px ${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "25px 0 25px 40px",
      },
    },

    typoItems: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
        paddingTop: "20px",
      },
    },

    descriptionModel: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(220)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "220px",
      },
    },

    typoPaymentInfo: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },

    typoSubtotal: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "15px",
      },
    },

    boxDeliveryName: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(15)}px`,
        paddingBottom: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    gridInfo: {
      background: "#fff",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(18)}px`,
        padding: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "18px",
        padding: "35px",
      },
    },

    gridRefunds: {
      background: "#fff",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(18)}px`,
        padding: `${reduceResolution(35)}px`,
        marginTop: `${reduceResolution(46)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "18px",
        padding: "35px",
        marginTop: "46px",
      },
    },

    gridContact: {
      borderBottom: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },

    gridDelivery: {
      borderBottom: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },

    boxItems: {
      width: "100%",
    },

    gridTotal: {
      background: "#fff",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(18)}px`,
        padding: `${reduceResolution(19)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "18px",
        padding: "19px",
      },
    },

    typoQty: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
      },
    },

    gridItemSecond: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
      },
    },

    gridCenter: {
      textAlign: "center",
    },

    paymentTypeIcon: {
      paddingRight: "5px",
    },
  })
);

export const OrderDetail: FunctionComponent<OrderDetailProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const masterData = useMasterData();
  const modelsTerminal: Array<TerminalModel> = masterData.terminalsModels;

  const terminalCommonClasses = useTerminalCommonStyles();
  const dispatch = useDispatch();

  const orderStatus = useSelector<RootState, OrdersStatus>(state => state.orders.status);
  const order = useSelector<RootState, Order | undefined>(state => state.orders.order);

  const payment = props.order.adminPayments[0];

  const [editedOrder, setEditedOrder] = useState<Order>(props.order);
  const [showCancelPopUp, setShowCancelPopUp] = useState<boolean>(false);
  const [showRefundsPopUp, setShowRefundsPopUp] = useState<boolean>(false);

  useNonInitialEffect(() => {
    if (OrdersStatus.SaveSuccess === orderStatus) {
      dispatch(fetchThunk());
    };

    if (OrdersStatus.SaveRefundSuccess === orderStatus) {
      setShowRefundsPopUp(false);
      dispatch(getOrderThunk(editedOrder.id));
      dispatch(fetchThunk());
    };

    if (OrdersStatus.getOrderSucess === orderStatus) {
      order && setEditedOrder(order);
    };

  }, [orderStatus]);


  const backHandler = () => {
    props.onClose && props.onClose();
  };

  const checkIconPaymentMethod = () => {
    if (editedOrder.adminPayments
      && editedOrder.adminPayments.length > 0) {
      return <FontAwesomeIcon
        icon={paymentMethodIcons.get(editedOrder.adminPayments[0].paymentMethodId) || faHandHoldingUsd}
        size="lg"
        color={paymentMethodIconsColor.get(editedOrder.adminPayments[0].paymentMethodId) || "#FFF"}
        className={classes.paymentTypeIcon}
      />
    }
    else return <></>
  };

  const checkPaymentMethod = () => {
    let payment = "Pending";

    if (editedOrder.adminPayments && editedOrder.adminPayments.length > 0) {
      payment = editedOrder.adminPayments[0].cardDigits;
    };

    return payment;
  };

  const shipOrderHandler = (order: Order) => {
    dispatch(changeOrderStatusThunk(order.id, statusOrder.SHIPPED));
    changeBusinessHandler(statusOrder.SHIPPED, "status");
  };

  const updateOrderHandler = (order: Order) => {
    setEditedOrder(order);
  };

  const updateStatusHandler = (orderId: number, status: string) => {
    changeBusinessHandler(status, "status");
    dispatch(changeOrderStatusThunk(orderId, status));
  };

  const saveOrderHandler = (order: Order) => {
    dispatch(saveTerminalOrderThunk(editedOrder));
  };

  const closeRefundsHandler = () => {
    setShowRefundsPopUp(false);
  };

  const partialRefundsHandler = (refund: Refund) => {
    dispatch(saveRefundThunk(refund))
  };

  const fullRefundsHandler = (order: Order) => {
    setShowRefundsPopUp(false);
    cancelOrderHandler(order);
  };

  const showRefundsPopUpHandler = () => {
    setShowRefundsPopUp(true);
  }

  const changeBusinessHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedOrder = { ...editedOrder };
      set(updatedOrder, fieldName, value);
      setEditedOrder(updatedOrder);
      return updatedOrder;
    }
    else {
      return editedOrder;
    }
  };

  const openCancelOrderHandler = () => {
    setShowCancelPopUp(true);
  };

  const cancelOrderHandler = (order: Order) => {
    dispatch(changeOrderStatusThunk(order.id, statusOrder.CANCELLED));
    changeBusinessHandler(statusOrder.CANCELLED, "status");
    setShowCancelPopUp(false);
  };
  //

  const gridInfo = (
    <Grid container className={classes.gridContact}>
      <Grid item xs={4}>
        <Typography className={terminalCommonClasses.typo18_600}>Billing Information</Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={7}>
        {
          payment ?
            <Box><Typography className={terminalCommonClasses.typo16_500}>{payment.clientName}</Typography>
              <Typography className={terminalCommonClasses.typo14_400}>{payment.clientName}</Typography>
              <Typography className={clsx(terminalCommonClasses.typo14_400, classes.textOverflow)}>{editedOrder.marketplace && editedOrder.marketplace.email}</Typography>
              <Typography className={clsx(terminalCommonClasses.typo14_400, classes.textOverflow)}>{editedOrder.marketplace && editedOrder.marketplace.phone}</Typography>
            </Box>
            :
            <></>
        }
      </Grid>
    </Grid>
  );

  const gridItems = (
    <Box className={classes.boxItems}>
      <Typography className={clsx(terminalCommonClasses.typo18_600, classes.typoItems)}>Items</Typography>

      {
        modelsTerminal.map((model, index) => {
          let qty = editedOrder.terminals.filter((terminal) => model.id.toUpperCase() === terminal.terminalModelId.toUpperCase()).reduce((total, terminal) => total + 1, 0);
          if (qty > 0) {
            return <Grid container className={clsx({ [classes.gridItemSecond]: index > 0 })}>
              <Grid item xs={1}>
                {model.id.toUpperCase() ? <TerminalImage id={model.id} /> : <></>}
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={6}>
                <Typography className={terminalCommonClasses.typo15_400_orange}>{model.type}</Typography>
                <Typography className={terminalCommonClasses.typo16_600}>{model.name}</Typography>
                <Typography className={clsx(terminalCommonClasses.typo15_400, classes.descriptionModel)}>
                  {model.description}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridCenter}>
                <Typography className={terminalCommonClasses.typo14_600}>{index === 0 ? "Qty" : ""}</Typography>
                <Typography className={clsx(terminalCommonClasses.typo14_600, classes.typoQty)}>{qty}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridCenter}>
                <Typography className={terminalCommonClasses.typo14_600}>{index === 0 ? "Price" : ""}</Typography>
                <Typography className={clsx(terminalCommonClasses.typo16_600, classes.typoQty)}>${model.price}</Typography>
              </Grid>
            </Grid>
          }
          else {
            return <></>
          }
        })
      }
    </Box>
  );

  const content = (
    <Box className={classes.contentContainer}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container className={classes.gridInfo}>
            {gridInfo}
            <OrderDelivery
              order={editedOrder}
              //
              onChange={updateOrderHandler}
              onChangeStatus={updateStatusHandler}
              onSave={saveOrderHandler}
              onShipOrder={shipOrderHandler}
            />
            {gridItems}
          </Grid>

          <Grid container className={classes.gridRefunds}>
            <OrderRefunds
              order={editedOrder}
              //
              showRefund={showRefundsPopUpHandler}
            />
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Typography className={terminalCommonClasses.typo18_600}>Shipping Address</Typography>
          <Box className={classes.boxDeliveryName}>
            <Typography className={clsx(terminalCommonClasses.typo16_600, classes.textOverflow)}>{editedOrder.deliveryName ? editedOrder.deliveryName : "-"}</Typography>
            <Typography className={clsx(terminalCommonClasses.typo15_400, classes.textOverflow)}>{editedOrder.deliveryPhone ? formatPhoneNumber(editedOrder.deliveryPhone) : "-"}</Typography>
          </Box>

          <Typography className={terminalCommonClasses.typo15_400}>{editedOrder.deliveryAddress}</Typography>
          <Typography className={terminalCommonClasses.typo15_400}>{`${editedOrder.deliveryState}, ${editedOrder.deliveryZipcode}`}</Typography>
          <Typography className={terminalCommonClasses.typo15_400}>{editedOrder.deliveryCountry}</Typography>

          <Box className={classes.typoPaymentInfo}>
            <Typography className={clsx(terminalCommonClasses.typo18_600)}>Payment Info</Typography>
            <Typography className={clsx(terminalCommonClasses.typo16_400)}>
              {checkIconPaymentMethod()}
              {checkPaymentMethod()}
            </Typography>
          </Box>

          <Grid container className={classes.gridTotal}>
            <Grid item xs={8}>
              <Typography className={clsx(terminalCommonClasses.typo14_500, classes.typoSubtotal)}>Subtotal</Typography>
              <Typography className={clsx(terminalCommonClasses.typo14_500, classes.typoSubtotal)}>Shipping</Typography>
              <Typography className={terminalCommonClasses.typo16_600}>Order Total</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={clsx(terminalCommonClasses.typo14_400, classes.typoSubtotal)}>{`$ ${Number(editedOrder.price).toFixed(2)}`}</Typography>
              <Typography className={clsx(terminalCommonClasses.typo14_600_green, classes.typoSubtotal)}>Free</Typography>
              <Typography className={terminalCommonClasses.typo14_600}>{`$ ${Number(editedOrder.price).toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const refundsPopUpView = (
    <OrderRefundsPopUp
      order={editedOrder}
      //
      onClose={closeRefundsHandler}
      onSavePartial={partialRefundsHandler}
      onSaveFull={fullRefundsHandler}
    />
  );

  const cancelPopUpView = (
    <OrderCancelPopUp
      onClose={() => setShowCancelPopUp(false)}
      onConfirmCancel={() => cancelOrderHandler(editedOrder)}
    />
  );

  return (
    <Fragment>
      <Box className={clsx(commonClasses.container, props.className, classes.container)}>
        <Box className={classes.baseContainer}>
          <OrderDetailHeader
            order={editedOrder}
            //
            onBack={backHandler}
            onCancel={openCancelOrderHandler}
          />
        </Box>
        <Box className={classes.boxContent}>
          <Box className={clsx(classes.baseContainer)}>
            {content}
          </Box>
        </Box>
      </Box>

      {showRefundsPopUp && refundsPopUpView}
      {showCancelPopUp && cancelPopUpView}
    </Fragment >
  );
};

export default OrderDetail;
