import React, { FunctionComponent, ReactNode } from "react";
import { Button as MUIButton, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import clsx from "clsx";
import { commonStyles } from "Theme";
import { reduceResolution, wbp } from "Theme";

export interface Props {
  label?: string | ReactNode;
  variant?: "primary" | "secondary" | "danger" | "success" | "warning";
  size?: "small" | "medium" | "large";
  color?: "orange" | "green" | "black" | "secondaryGreen" | "red";
  onClick?: (event: any) => void;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: IconProp;
  startElement?: ReactNode;
  endIcon?: IconProp;
  endElement?: ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(14)}px ${reduceResolution(36)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "14px 36px",
      },

      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        lineHeight: 1.6,
        [theme.breakpoints.down(wbp)]: {
          fontSize: `${reduceResolution(16)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "16px",
        },
      },
    },
    medium: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(7)}px ${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "7px 16px",
      },

      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down(wbp)]: {
          fontSize: `${reduceResolution(13)}px`,
          lineHeight: `${reduceResolution(22)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "13px",
          lineHeight: "22px",
        },
      },
    },
    small: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(6)}px ${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "6px 16px",
      },
      "& .MuiButton-label": {
        fontFamily: "Poppins",
        fontWeight: 600,
        [theme.breakpoints.down(wbp)]: {
          fontSize: `${reduceResolution(12)}px`,
          lineHeight: `${reduceResolution(22)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "12px",
          lineHeight: "22px",
        },
      },
    },
    largeIcon: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(16)}px`,
        height: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "16px",
        height: "16px",
      },
    },
    mediumIcon: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(11)}px`,
        height: `${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "11px",
        height: "11px",
      },
    },
    smallIcon: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(11)}px`,
        height: `${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "11px",
        height: "11px",
      },
    },
    orange: {
      color: "#FFFFFF",
      backgroundColor: "#EAB464",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#C18733",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#FAEFDF",
      },
    },
    secondaryOrange: {
      color: "#EAB464",
      backgroundColor: "#FFFFFF",
      border: "2px solid #EAB464",
      "&:hover": {
        backgroundColor: "#FAEFDF",
      },
      "&:active": {
        color: "#C18733",
        backgroundColor: "#F2D2A2",
        border: "2px solid #C18733",
      },
      "&.Mui-disabled": {
        color: "#FAEFDF",
        backgroundColor: "#FFFFFF",
        border: "2px solid #FAEFDF",
      },
    },
    green: {
      color: "#FFFFFF",
      backgroundColor: "#92B4A7",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#5E8677",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#E9F0ED",
      },
    },
    secondaryRed: {
      color: "#EF4F57",
      border: "2px solid #EF4F57",
      "&:hover": {
        backgroundColor: "#FBB9BC",
      },
    },

    secondaryGreen: {
      color: "#5E8677",
      backgroundColor: "#FFFFFF",
      border: "2px solid #5E8677",
      "&:hover": {
        backgroundColor: "#E9F0ED",
      },
      "&:active": {
        backgroundColor: "#BDD2CA",
      },
      "&.Mui-disabled": {
        color: "#E9F0ED",
        backgroundColor: "#FFFFFF",
        border: "2px solid #E9F0ED",
      },
    },
    black: {
      color: "#FFFFFF",
      backgroundColor: "#222222",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#565656",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#D3D3D3",
      },
    },
    secondaryBlack: {
      color: "#222222",
      backgroundColor: "#FFFFFF",
      border: "2px solid #000000",
      "&:hover": {
        backgroundColor: "#DBDAD7",
      },
      "&:active": {
        backgroundColor: "#BCB8AE",
      },
      "&.Mui-disabled": {
        color: "#DBDAD7",
        backgroundColor: "#FFFFFF",
        border: "2px solid #DBDAD7",
      },
    },
    secondaryDanger: {
      color: "#EF4F57",
      backgroundColor: "#FFF",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#CC444B",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#FBB9BC",
      },
    },
    success: {
      color: "#FFFFFF",
      backgroundColor: "#61CE8D",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#51A372",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#C0EBD1",
      },
    },
    warning: {
      color: "#FFFFFF",
      backgroundColor: "#FFDF6B",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#ECC915",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#FFF8DD",
      },
    },
    danger: {
      color: "#FFFFFF",
      backgroundColor: "#EF4F57",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#CC444B",
      },
      "&.Mui-disabled": {
        color: "#FFFFFF",
        backgroundColor: "#D3D3D3",
      },
    },
    orangeLoading: {
      backgroundColor: "#C18733",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#C18733",
      },
    },
    greenLoading: {
      backgroundColor: "#5E8677",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#5E8677",
      },
    },
    blackLoading: {
      backgroundColor: "#000000",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#000000",
      },
    },
    secondaryOrangeLoading: {
      backgroundColor: "#F2D2A2",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#F2D2A2",
      },
    },
    secondaryGreenLoading: {
      backgroundColor: "#BDD2CA",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#BDD2CA",
      },
    },
    secondaryBlackLoading: {
      backgroundColor: "#BCB8AE",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#BCB8AE",
      },
    },
    dangerLoading: {
      backgroundColor: "#CC444B",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#CC444B",
      },
    },
    successLoading: {
      backgroundColor: "#51A372",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#51A372",
      },
    },
    warningLoading: {
      backgroundColor: "#ECC915",
      "& .MuiButton-label": {
        color: "rgba(255,255,255,0)",
      },
      "&:hover": {
        color: "rgba(255,255,255,0)",
        backgroundColor: "#ECC915",
      },
    },
    spinnerContainer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      width: "100%",
      height: "100%",
    },
  })
);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Button: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const commonClasses = commonStyles();

  const clickHandler = (event: any) => {
    props.onClick && !props.loading && !props.disabled && props.onClick(event);
  };

  const startIconElement = props.startIcon && (
    <FontAwesomeIcon
      icon={props.startIcon!}
      className={clsx({
        [classes.largeIcon]: props.size === undefined || props.size === "large",
        [classes.mediumIcon]: props.size === "medium",
        [classes.smallIcon]: props.size === "small",
      })}
    />
  );

  const endIconElement = props.endIcon && (
    <FontAwesomeIcon
      icon={props.endIcon!}
      className={clsx({
        [classes.largeIcon]: props.size === undefined || props.size === "large",
        [classes.mediumIcon]: props.size === "medium",
        [classes.smallIcon]: props.size === "small",
      })}
    />
  );

  const getSpinnerColor = (variant: string, color: string): string => {
    if (variant === "secondary" && color === "green") {
      return "#5E8677";
    } else if (variant === "secondary" && color === "black") {
      return "#222222";
    } else if (variant === "secondary") {
      return "#C18733";
    } else {
      return "#FFFFFF";
    }
  };

  const spinner = (
    <Box className={classes.spinnerContainer}>
      <PulseLoader
        color={getSpinnerColor(props.variant || "primary", props.color || "orange")}
        loading={props.loading}
        css={override}
        size={5}
      />
    </Box>
  );

  return (
    <MUIButton
      variant={props.variant === "secondary" ? "outlined" : "contained"}
      disableElevation={true}
      disableFocusRipple={true}
      disableRipple={true}
      disabled={props.disabled}
      disableTouchRipple={true}
      onClick={clickHandler}
      startIcon={props.startElement || startIconElement}
      endIcon={props.endElement || endIconElement}
      className={clsx(
        commonClasses.button,
        {
          [classes.large]: props.size === undefined || props.size === "large",
          [classes.medium]: props.size === "medium",
          [classes.small]: props.size === "small",
          [classes.orange]:
            (props.variant === undefined || props.variant === "primary") &&
            (props.color === undefined || props.color === "orange"),
          [classes.green]: (props.variant === undefined || props.variant === "primary") && props.color === "green",
          [classes.black]: (props.variant === undefined || props.variant === "primary") && props.color === "black",
          [classes.success]: props.variant === "success",
          [classes.warning]: props.variant === "warning",
          [classes.danger]: props.variant === "danger",
          [classes.secondaryOrange]:
            props.variant === "secondary" && (props.color === undefined || props.color === "orange"),
          [classes.secondaryGreen]: props.variant === "secondary" && props.color === "secondaryGreen",
          [classes.secondaryBlack]: props.variant === "secondary" && props.color === "black",
          [classes.secondaryRed]: props.variant === "secondary" && props.color === "red",
          [classes.orangeLoading]:
            props.loading &&
            (props.variant === undefined || props.variant === "primary") &&
            (props.color === undefined || props.color === "orange"),
          [classes.greenLoading]:
            props.loading && (props.variant === undefined || props.variant === "primary") && props.color === "green",
          [classes.blackLoading]:
            props.loading && (props.variant === undefined || props.variant === "primary") && props.color === "black",
          [classes.secondaryOrangeLoading]:
            props.loading && props.variant === "secondary" && (props.color === undefined || props.color === "orange"),
          [classes.secondaryGreenLoading]: props.loading && props.variant === "secondary" && props.color === "green",
          [classes.secondaryBlackLoading]: props.loading && props.variant === "secondary" && props.color === "black",
          [classes.dangerLoading]: props.loading && props.variant === "danger",
          [classes.successLoading]: props.loading && props.variant === "success",
          [classes.warningLoading]: props.loading && props.variant === "warning",
        },
        props.className
      )}
    >
      <span>{props.label}</span>
      {props.loading && spinner}
    </MUIButton>
  );
};

export default Button;
