import { LoginResponseDto } from "./LoginDtos";
import { Auth } from "model/Auth";

export const convert = (dto: LoginResponseDto, signedIn: boolean): Auth => {
  return {
    user: {
      id: dto.user.id,
      uuid: dto.user.uuid,
      firstName: dto.user.first_name,
      lastName: dto.user.last_name,
      avatar: dto.user.image,
      email: dto.user.email,
    },
    signedIn,
    token: dto.user.token,
  }
}