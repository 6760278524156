import { User, UserBusinessAppointments } from "model/User";

export interface UsersState {
  user: User | undefined;
  appointments: UserBusinessAppointments | undefined;  
  status: UsersStatus;
  loading: boolean;
  resettingPassword: boolean;
  changingStatus: boolean;
  addingBusiness: boolean;
  removingBusiness: boolean;
  checkingAppointments: boolean;
}

export enum UsersStatus {
  Initial,
  Getting,
  GetSuccess,
  UpdatingUser,
  UserUpdateSuccess,
  ResettingPassword,
  ResetPasswordSuccess,
  ChangingStatus,
  ChangeStatusSuccess,
  AddingBusiness,
  AddBusinessSuccess,
  RemovingBusiness,
  RemoveBusinessSuccess,
  CheckingAppointments,
  CheckAppointmentsSuccess,
  Error,
}
