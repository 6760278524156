import { Superadmin } from "model/Superadmin";

export interface SuperadminsState {
  superadmins: Array<Superadmin>;
  status: SuperadminsStatus;
  loading: boolean;
}

export enum SuperadminsStatus {
  Initial,
  Fectching,
  FetchSuccess,
  Saving,
  SaveSuccess,
  Error,
}
