import { Action } from "redux";
import { Superadmin } from "model/Superadmin";

//Types
export const SUPERADMINS_RESET_ACTION_TYPE = "SUPERADMINS_RESET";
export const SUPERADMINS_FETCH_START_ACTION_TYPE = "SUPERADMINS_FETCH_START";
export const SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE = "SUPERADMINS_FETCH_SUCCESS";
export const SUPERADMINS_SAVE_START_ACTION_TYPE = "SUPERADMINS_SAVE_START";
export const SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE = "SUPERADMINS_SAVE_SUCCESS";
export const SUPERADMINS_ERROR_ACTION_TYPE = "SUPERADMINS_ERROR";

//Actions
interface SuperadminsResetAction extends Action<typeof SUPERADMINS_RESET_ACTION_TYPE> {}

interface SuperadminsFetchStartAction extends Action<typeof SUPERADMINS_FETCH_START_ACTION_TYPE> {}

interface SuperadminsFetchSuccessAction extends Action<typeof SUPERADMINS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    superadmins: Array<Superadmin>;
  };
}

interface SuperadminsSaveStartAction extends Action<typeof SUPERADMINS_SAVE_START_ACTION_TYPE> {}

interface SuperadminsSaveSuccessAction extends Action<typeof SUPERADMINS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    superadmin: Superadmin;
  }
}

interface SuperadminsErrorAction extends Action<typeof SUPERADMINS_ERROR_ACTION_TYPE> {}

export type SuperadminsAction =
  | SuperadminsResetAction
  | SuperadminsFetchStartAction
  | SuperadminsFetchSuccessAction
  | SuperadminsSaveStartAction
  | SuperadminsSaveSuccessAction
  | SuperadminsErrorAction;
