import React, { FunctionComponent } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import LeftHeader from "./LeftHeader";
import { User } from "model/User";
import { Button } from "components/UI";

interface Props {
  user?: User;
  businessName?: string;
  className?: string;
  onAddBusiness: () => void;
  onClickBusinessName: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      borderBottom: "1px solid #F1F1F1",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "26px",
        paddingLeft: "26px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "42px",
        paddingLeft: "42px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "56px",
        paddingLeft: "56px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "85px",
        paddingLeft: "85px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    leftHeaderContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingRight: "20px",
      height: "100%",
    },
    butonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    buttonContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  })
);

export const HeaderComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const leftHeader = (
    <LeftHeader
      user={props.user}
      businessName={props.businessName}
      onClickBusinessName={props.onClickBusinessName}
    />
  );

  const button: JSX.Element = (
    <Box className={classes.buttonContent}>
      <Button
        label="+ Add Business"
        color="black"
        onClick={() => props.onAddBusiness()}
      />
    </Box>
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.headerContainer}>
        <Grid container>
          <Grid item xs={10}>
            <Box className={classes.leftHeaderContainer}>
              {leftHeader}
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.butonContainer}>
            {button}
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
};

export default HeaderComponent;
