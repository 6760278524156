import React, { FunctionComponent } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { User, UserBusiness } from "model/User";
import { BusinessRow } from "./BusinessRow";
import { Option } from "model";

interface Props {
  user?: User;
  onImpersonate: (businessId: number) => void;
  onChangeRole: (role: Option<number> | undefined, workId: number, currentAccess: Option<number> | undefined) => void;
  onChangeAccess: (access: Option<number> | undefined, workId: number, currentRole: Option<number> | undefined) => void;
  onRemove: (business: UserBusiness) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.user?.businesses.filter(b => b.active).map((business) => (
        <BusinessRow 
          key={business.workId} 
          business={business} 
          onImpersonate={props.onImpersonate}
          onChangeRole={props.onChangeRole}
          onChangeAccess={props.onChangeAccess}
          onRemove={props.onRemove}
        /> 
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
