import {
  UsersAction,
  USERS_RESET_ACTION_TYPE,
  USERS_GET_START_ACTION_TYPE,
  USERS_GET_SUCCESS_ACTION_TYPE,
  USERS_RESET_PASSWORD_START_ACTION_TYPE,
  USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  USERS_CHANGE_STATUS_START_ACTION_TYPE,
  USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE,
  USERS_ADD_BUSINESS_START_ACTION_TYPE,
  USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE,
  USERS_REMOVE_BUSINESS_START_ACTION_TYPE,
  USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE,
  USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE,
  USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE,
  USERS_ERROR_ACTION_TYPE,
  USERS_UPDATE_USER_SUCCESS_ACTION_TYPE,
  USERS_UPDATE_USER_START_ACTION_TYPE,
} from "./UsersActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { UsersState } from "reducers/users/UsersState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { User, UserBusiness, UserBusinessAppointments } from "model/User";
import { ResponseUserBusinessAppointmentsDto, ResponseUserDto } from "./UsersDtos";
import { alertErrorHandler } from "actions/ActionUtils";
import { showSuccessAlert } from "actions/alerts/AlertsActions";
import { convertUser } from "./UsersConverter";

const staffsUrl = "admin/staffs";
const resetPasswordUrl = "admin/reset_password";
const usersUrl = "admin/users";
const worksUrl = "admin/works";
const checkAppointmentsUrl = "admin/active_appointments";

export const getThunk = (staffId: number): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(getting());

    const url = `${staffsUrl}/${staffId}`;

    try {
      const response: AxiosResponse<ResponseUserDto> = await ApiClient.get(url, createTokenConfig());
      dispatch(getSuccess(convertUser(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error getting staff id = ${staffId}`);
    }
  };
};

export const resetPasswordThunk = (userId: number): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(resettingsPassword());
    const url = `${resetPasswordUrl}/${userId}`;
    try {
      await ApiClient.post(url, null, createTokenConfig());
      dispatch(showSuccessAlert("Reset password email sent."));
      dispatch(resetPasswordSuccess());
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error resetting user id = ${userId} password.`);
    }
  };
};

export const changeStatusThunk = (
  userId: number,
  active: boolean
): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(changingStatus());
    const url = `${usersUrl}/${userId}`;
    const body = {
      user: {
        active
      }
    };

    try {
      const response: AxiosResponse = await ApiClient.patch(url, body, createTokenConfig());
      dispatch(changeStatusSuccess(userId, active));
      dispatch(getThunk(response.data.staff_id));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error ${active ? "activating" : "inactivating"} user id = ${userId}.`);
    }
  };
};

export const checkAppointmentsThunk = (staffId: number, businessId: number): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    const url = `${checkAppointmentsUrl}?staff_id=${staffId}&marketplace_id=${businessId}`;
    dispatch(checkingAppointments());
    try {
      const response: AxiosResponse<ResponseUserBusinessAppointmentsDto> = await ApiClient.get(url, createTokenConfig());
      dispatch(checkAppointmentsSuccess({
        staffId,
        businessId,
        hasAppointments: response.data.has_appointments,
        activeAppointments: response.data.active_appointments,
      }));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error checking appointments for staff id = ${staffId} and business id ${businessId}.`);
    }
  };
};

export const removeBusinessThunk = (staffId: number, business: UserBusiness): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(removingBusiness());
    const appointmentsUrl = `${checkAppointmentsUrl}?staff_id=${staffId}&marketplace_id=${business.businessId}`;
    const updateStaffUrl = `${staffsUrl}/${staffId}/update`;
    const removeWorkUrl = `${worksUrl}/${business.workId}`;
    try {

      const appointmentsResponse: AxiosResponse<ResponseUserBusinessAppointmentsDto> = await ApiClient.get(appointmentsUrl, createTokenConfig());

      if (appointmentsResponse.data.has_appointments) {
        const body = {
          staff: {
            works_attributes: [
              {
                id: business.workId,
                staff_id: staffId,
                marketplace_id: business.businessId,
                active: false
              },
            ],
          },
        };
        await ApiClient.patch(updateStaffUrl, body, createTokenConfig());
      } else {
        await ApiClient.delete(removeWorkUrl, createTokenConfig());
      }
      dispatch(removeBusinessSuccess());
      dispatch(getThunk(staffId));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error removing business.`);
    }
  };
};

export const addBusinessThunk = (
  staffId: number,
  businessId: number,
  roleId: number,
  accessLevelId: number
): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(addingBusiness());

    const getStaffUrl = `${staffsUrl}/${staffId}`;
    const updateStaffUrl = `${staffsUrl}/${staffId}/update`;

    try {
      const response: AxiosResponse<ResponseUserDto> = await ApiClient.get(getStaffUrl, createTokenConfig());
      const user = convertUser(response.data);
      const hasActiveWork = user.businesses.some((b) => b.businessId === businessId && b.active);
      const hasInactiveWork = user.businesses.some((b) => b.businessId === businessId && !b.active);

      if (!hasInactiveWork && !hasActiveWork) {
        const body = {
          staff: {
            works_attributes: [
              {
                staff_id: staffId,
                marketplace_id: businessId,
                role_id: roleId,
                access_level_id: accessLevelId,
                calendar_attributes: {
                  marketplace_id: businessId,
                  range:
                    '{"monday": null, "tuesday": null, "wednesday": null, "thursday": null, "friday": null, "saturday": null, "sunday": null}',
                },
              },
            ],
          },
        };
        await ApiClient.patch(updateStaffUrl, body, createTokenConfig());
      } else if (hasInactiveWork) {
        const inactiveBusiness = user.businesses.find((b) => b.businessId === businessId)!;
        const body = {
          staff: {
            works_attributes: [
              {
                id: inactiveBusiness.workId,
                staff_id: staffId,
                marketplace_id: businessId,
                role_id: roleId,
                access_level_id: accessLevelId,
                active: true,
                calendar_attributes: {
                  marketplace_id: businessId,
                  range:
                    '{"monday": null, "tuesday": null, "wednesday": null, "thursday": null, "friday": null, "saturday": null, "sunday": null}',
                },
              },
            ],
          },
        };
        await ApiClient.patch(updateStaffUrl, body, createTokenConfig());
      }
      dispatch(addBusinessSuccess());
      dispatch(getThunk(staffId));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error adding business staff id = ${staffId} business Id = ${businessId}`);
    }
  };
};

export const updateUserThunk = (workId: number, staffId: number, roleId: number, accessLevelId: number): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(updateUserStart());
    const url = `${worksUrl}/${workId}`;
    const body = {
      "work":
        {
          "access_level_id": accessLevelId,
          "role_id": roleId
        }
      }
    try {
      await ApiClient.patch(url, body, createTokenConfig());
      dispatch(showSuccessAlert("Staff updated."));
      dispatch(updateUserSuccess());
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error updating staff id = ${staffId}`);
    }
  };
}

export const impersonateUserThunk = (
  userId: number,
  businessId: number
): ThunkAction<void, UsersState, null, UsersAction | AlertAction> => {
  return async (dispatch) => { };
};

export const reset = (): UsersAction => {
  return {
    type: USERS_RESET_ACTION_TYPE,
  };
};

const getting = (): UsersAction => {
  return {
    type: USERS_GET_START_ACTION_TYPE,
  };
};

const getSuccess = (user: User): UsersAction => {
  return {
    type: USERS_GET_SUCCESS_ACTION_TYPE,
    payload: {
      user,
    },
  };
};

const resettingsPassword = (): UsersAction => {
  return {
    type: USERS_RESET_PASSWORD_START_ACTION_TYPE,
  };
};

const resetPasswordSuccess = (): UsersAction => {
  return {
    type: USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  };
};

const updateUserStart = (): UsersAction => {
  return {
    type: USERS_UPDATE_USER_START_ACTION_TYPE,
  };
};


const updateUserSuccess = (): UsersAction => {
  return {
    type: USERS_UPDATE_USER_SUCCESS_ACTION_TYPE,
  };
};


const changingStatus = (): UsersAction => {
  return {
    type: USERS_CHANGE_STATUS_START_ACTION_TYPE,
  };
};

const changeStatusSuccess = (userId: number, active: boolean): UsersAction => {
  return {
    type: USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE,
    payload: {
      userId,
      active,
    },
  };
};

const addingBusiness = (): UsersAction => {
  return {
    type: USERS_ADD_BUSINESS_START_ACTION_TYPE,
  };
};

const addBusinessSuccess = (): UsersAction => {
  return {
    type: USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE,
  };
};

const removingBusiness = (): UsersAction => {
  return {
    type: USERS_REMOVE_BUSINESS_START_ACTION_TYPE,
  };
};

const removeBusinessSuccess = (): UsersAction => {
  return {
    type: USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE,
  };
};

const checkingAppointments = (): UsersAction => {
  return {
    type: USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE,
  };
};

const checkAppointmentsSuccess = (appointments: UserBusinessAppointments): UsersAction => {
  return {
    type: USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE,
    payload: {
      appointments
    }
  };
};

const error = (): UsersAction => {
  return {
    type: USERS_ERROR_ACTION_TYPE,
  };
};
