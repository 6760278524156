import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { SingleChip } from "components/UI";
import React, { FunctionComponent } from "react";

interface TerminalActiveProps {
  terminalStatus: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: "#5E8677",
      textTransform: "capitalize",
      "& p": {
        color: "#fff",
      },
      width: "fit-content",
    },

    disabled: {
      backgroundColor: "#F4F3F0",
      "& p": {
        color: "#000",
      },
    },
  })
);

export const TerminalActive: FunctionComponent<TerminalActiveProps> = (props) => {
  const classes = useStyles();

  return (
    <SingleChip className={clsx(classes.default, { [classes.disabled]: !props.terminalStatus })}>
      {props.terminalStatus ? "Enabled" : "Disabled"}
    </SingleChip>
  );
};

export default TerminalActive;
