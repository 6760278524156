import React, { FunctionComponent } from "react";
import { useLocation } from "react-router";
import { useAuth } from "hooks";
import { Auth } from "model/Auth";
import { SignedInHeader } from "./SignedIn/SignedInHeader";
import { isPrivatePath } from "routes/Paths";

export const Header: FunctionComponent = () => {
  const auth: Auth = useAuth();
  const location = useLocation();

  return (isPrivatePath(location.pathname) && auth.signedIn) ? (<SignedInHeader/>) : null;
};

export default Header;
