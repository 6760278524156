import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface Props {
  icon: IconProp;
  onClick: () => void;
  text: string;
  color?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    icon: {
      [theme.breakpoints.down("lg")]: {
        marginRight: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "10px",
      },
    },
    title: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    },
  })
);

export const ToolbarItem: FunctionComponent<Props> = ({
  icon,
  onClick,
  text,
  color,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.item} onClick={onClick}>
      <FontAwesomeIcon color={color} icon={icon} className={classes.icon} />
      <Typography style={{color}} className={classes.title}>{text}</Typography>
    </Box>
  );
};

export default ToolbarItem;