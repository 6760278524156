import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box, Typography,
} from "@material-ui/core";
import { HomePath } from "routes/Paths";
import { User } from "model/User";
import { Link } from "components/UI";
import { Terminal } from "model/TerminalOrder";
import { reduceResolution, wbp } from "Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
interface Props {
  user?: User;
  businessName?: string;
  terminal: Terminal;
  //
  onClickBusinessName: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "18px",
      },
    },
    content: {
      display: "flex",
    },

    contentBread: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    },

    breadcrumb: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(0)}px`,
        fontSize: `${reduceResolution(14)}px`,
        lineHeight: `${reduceResolution(15)}px`,
        paddingLeft: `${reduceResolution(7)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "0px",
        paddingLeft: "7px",
        fontSize: "14px",
        lineHeight: "15px"
      },
    },

    breadcrumbSeparator: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(1)}px`,
        fontSize: `${reduceResolution(12)}px`,
        lineHeight: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "1px",
        fontSize: "12px",
        lineHeight: "15px"
      },
    },

    title: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(7)}px`,
        fontSize: `${reduceResolution(24)}px`,
        lineHeight: `${reduceResolution(36)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "7px",
        fontSize: "24px",
        lineHeight: "36px"
      },
    },

    link: {
      textDecoration: "none",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(-2)}px`,
        fontSize: `${reduceResolution(14)}px`,
        height: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "-2px",
        fontSize: "14px",
        lineHeight: "14px"
      },
    },
    backContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "50px",
      },
    },
    backIcon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "27px",
      },
    },
  })
);

export const LeftHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const backButton = (
    <Box className={classes.backContainer} onClick={() => props.onClickBusinessName()}>
      <FontAwesomeIcon
        className={classes.backIcon}
        icon={faArrowLeft}
        size="4x"
      />
    </Box >
  );

  return (
    <Box className={classes.container}>
      {backButton}
      <Box className={classes.titleContainer}>
        <Box className={classes.contentBread}>
          <Link
            label="Clients"
            linkTo={HomePath}
            size="small"
            color="black"
            className={classes.link}
          />
          <Typography className={classes.breadcrumbSeparator}>
            /
          </Typography>
          <Link
            label={props.businessName}
            onClick={() => props.onClickBusinessName()}
            size="small"
            color="black"
            className={classes.link}
          />
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.title}>{props.terminal.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftHeader;
