import React from "react";
import { Box, ClickAwayListener, Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError } from "@spike/model";
import clsx from "clsx";
import { Button, PriceField } from "components/UI";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import { Order, Refund } from "model/TerminalOrder";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface OrderRefundsPopUpProps {
    order: Order;
    //
    onClose: () => void;
    onSavePartial: (refund: Refund) => void;
    onSaveFull: (order: Order) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            "& .MuiDialog-paper": {
                boxShadow: "0px 10px 40px #00000029",
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(500)}px`,
                    borderRadius: `${reduceResolution(21)}px`,
                    height: `${reduceResolution(550)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    width: "500px",
                    borderRadius: "21px",
                    height: "550px",
                },
            },

            "& .MuiDialogContent-root": {
                overflowY: "hidden",
                paddingTop: 0,
            }
        },

        boxContainer: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(40)}px`,
                paddingRight: `${reduceResolution(40)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "40px",
                paddingRight: "40px",
            },
        },

        boxSpinner: {
            display: "flex",
            height: "100%",
        },

        dialogContainer: {
            width: "100%",
            "& .MuiDialog-paper": {
                overflowY: "hidden"
            },
            display: "flex",
            flexDirection: "column",
            padding: 0,
        },

        boxButtons: {
            borderTop: "1px solid #DDDDDD",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
                paddingTop: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: "20px",
                paddingTop: "20px",
            },
        },
        boxButtonsFlex: {
            width: "100%",
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(30)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "30px",
            },
        },
        buttonsContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },

        button: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(145)}px`,
                height: `${reduceResolution(47)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "145px",
                height: "47px",
            },
        },

        buttonCancel: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(8)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: "8px",
            },
        },

        question: {
            textAlign: "left",
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(50)}px`,
                paddingBottom: `${reduceResolution(5)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: "30px",
                paddingTop: "50px",
                paddingBottom: "5px",
            },
        },

        boxMessage: {
            justifyContent: "flex-start",
        },

        message: {
            textAlign: "center",
            paddingRight: "3px",
        },

        radioRefunds: {
            display: "flex",
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#222'
            },
        },

        gridRadio: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(15)}px`,
                paddingBottom: `${reduceResolution(15)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "15px",
                paddingBottom: "15px",
            },
        },

        gridRadioFull: {
            borderBottom: "1px solid #D4D4D4",
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "20px",
            },
        },

        gridPartialPrice: {
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
        },

        typoError: {
            color: "#EA6464",
            display: "flex",
            justifyContent: "flex-end",
        },

        boxTotal: {
            background: "#F4F3F0",
            width: "fit-content",
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(11)}px ${reduceResolution(18)}px`,
                borderRadius: `${reduceResolution(8)}px`,
                marginTop: `${reduceResolution(15)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: "8px",
                padding: "11px 18px",
                marginTop: "15px",
            },
        },

        typoAmount: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(5)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "5px",
            },
        },

        priceField: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(130)}px`,
                padding: "0 !important",
            },
            [theme.breakpoints.up(wbp)]: {
                width: "130px",
            },
            "&.MuiOutlinedInput-input": {
                padding: "0px !important",
            }
        },
    })
);

export const OrderRefundsPopUp: FunctionComponent<OrderRefundsPopUpProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();

    const payment = props.order.adminPayments[0];
    const adminRefunds = payment ? payment.adminRefunds : undefined;
    const totalRefunds = adminRefunds ? adminRefunds.reduce((total, refund) => total + Number(refund.amount), 0) : 0;

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [partialPriceValue, setPartialPriceValue] = useState<number>(0);
    const [radioSelected, setRadioSelected] = useState<boolean>(false);
    const [valueRefund, setValueRefund] = useState<string>();

    const validate = (value: string | undefined): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if ((valueRefund === "partial") && (partialPriceValue === 0)) {
            errors.push({ fieldName: "partialPriceValue", errorMessage: "Complete partial refund amount." });
        };

        if ((valueRefund === "partial") && (partialPriceValue > Number(props.order.price))) {
            errors.push({ fieldName: "partialPriceValue", errorMessage: "Amount must by lower or equal of full refund." });
        };

        let total = Number(props.order.price) - (totalRefunds + partialPriceValue);

        if ((valueRefund === "partial") && (total < 0)) {
            errors.push({ fieldName: "partialPriceValue", errorMessage: "Amount must by lower or equal of full refund." });
        };


        return [...errors];
    };

    const handleConfirmSelection = () => {
        let errors;
        errors = validate(valueRefund);
        setErrors(errors);

        if (errors.length === 0) {
            if (valueRefund === "full") {
                props.onSaveFull(props.order);
                return
            }

            if (valueRefund !== "full") {
                if (partialPriceValue === Number(props.order.price)) {
                    props.onSaveFull(props.order);
                }

                if (partialPriceValue !== Number(props.order.price)) {
                    let refund: Refund = {
                        adminPaymentId: props.order.adminPayments[0].id,
                        amount: partialPriceValue.toString(),
                        reason: "",
                    };
                    props.onSavePartial(refund);
                }
                return
            }
        }
    };

    const handlerCancel = () => {
        props.onClose();
    };

    const handlerRefund = (value: string) => {
        setRadioSelected(true);
        setValueRefund(value);

        if (value === "full") setErrors([]);
    };

    const priceRefundHandler = (value: any, fieldName?: string) => {
        if (fieldName) {
            setPartialPriceValue(value);
        }
    };

    const contentView = (
        <>
            <Box className={classes.boxContainer}>
                <Typography className={clsx(terminalCommonClasses.typo24_600, classes.question)}>Make a Refund</Typography>
                <Box className={clsx(terminalCommonClasses.flexRow, classes.boxMessage)}>
                    <Typography className={clsx(terminalCommonClasses.typo16_400, classes.message)}>{`You will make a refund `}</Typography>
                    <Typography className={clsx(terminalCommonClasses.typo16_600, classes.message)}>{` order #${props.order.id}`}</Typography>
                </Box>
                <Box className={clsx(terminalCommonClasses.flexRow, classes.boxTotal)}>
                    <Typography className={clsx(terminalCommonClasses.typo15_600)}>Total Refund:</Typography>
                    <Typography className={clsx(terminalCommonClasses.typo15_500, classes.typoAmount)}>${totalRefunds}.00</Typography>
                </Box>
                <RadioGroup
                    aria-label="refunds"
                    value={valueRefund}
                    name="refunds"
                    className={classes.radioRefunds}
                    onChange={(event, value) => handlerRefund(value)}>

                    <Grid container className={clsx(
                        terminalCommonClasses.flexRowCenter,
                        classes.gridRadio,
                        classes.gridRadioFull)}>
                        <Grid item xs={10}>
                            <FormControlLabel
                                value={"full"}
                                control={< Radio />}
                                key={1}
                                label={
                                    <React.Fragment>
                                        <Typography
                                            className={terminalCommonClasses.typo16_500}>
                                            Full Refund
                                        </Typography>
                                    </React.Fragment>
                                } />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo16_500,
                                    terminalCommonClasses.typo_grey)}>
                                {`$ ${Number(props.order.price) - totalRefunds}.00`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={clsx(
                        terminalCommonClasses.flexRow,
                        classes.gridRadio)}>
                        <Grid item xs={8}>
                            <FormControlLabel
                                value={"partial"}
                                control={< Radio />}
                                key={1}
                                label={
                                    <React.Fragment>
                                        <Typography
                                            className={terminalCommonClasses.typo16_500}>
                                            Partial Refund
                                        </Typography>
                                    </React.Fragment>
                                } />
                        </Grid>
                        <Grid item xs={4} className={classes.gridPartialPrice}>
                            {
                                valueRefund === "partial" && (
                                    <PriceField
                                        name="partialPriceValue"
                                        value={partialPriceValue}
                                        required={true}
                                        errors={errors}
                                        className={classes.priceField}
                                        //
                                        onChange={priceRefundHandler}
                                    />)
                            }
                        </Grid>
                    </Grid>
                </RadioGroup>
                <Typography className={classes.typoError}>{errors && errors.length > 0 && errors[0].errorMessage}</Typography>
            </Box>
            <Box className={classes.boxButtons}>
                <Box className={classes.boxButtonsFlex}>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            label="Discard"
                            loading={false}
                            onClick={handlerCancel}
                            variant="primary"
                            size="medium"
                            color={"black"}
                            className={clsx(classes.button)}
                        />
                        <Button
                            label="Generate"
                            loading={false}
                            onClick={handleConfirmSelection}
                            variant="primary"
                            color="green"
                            size="medium"
                            disabled={!radioSelected}
                            className={clsx(classes.button, classes.buttonCancel)}
                        />
                    </Box>
                </Box>
            </Box>
        </>);

    return (
        <Box>
            <Dialog open={true} className={clsx(classes.container)}>
                <ClickAwayListener onClickAway={handlerCancel}>
                    <DialogContent className={classes.dialogContainer}>
                        {contentView}
                    </DialogContent>
                </ClickAwayListener>
            </Dialog>
        </Box>);
};

export default OrderRefundsPopUp;
