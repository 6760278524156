import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "components/UI";
import { Order, statusOrder } from "model/TerminalOrder";
import moment from "moment-timezone";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import Title from "../UI/Header/Title";
import { OrderStatus } from "../UI/OrderStatus";
import clsx from "clsx";

interface OrderDetailHeaderProps {
    order: Order;
    className?: string;
    //
    onBack: () => void;
    onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxHeader: {
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(20)}px 0`,
            },
            [theme.breakpoints.up(wbp)]: {
                padding: "20px 0",
            },
        },

        left: {
            display: "flex",
            justifyContent: "flex-start",
        },

        boxBack: {
            display: "flex",
            alignItems: "center",
        },

        backIcon: {
            "&:hover": {
                cursor: "pointer",
            }
        },

        boxOrderTitle: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "20px",
            },
        },


        typoPlaced: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(5)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "5px",
            },
        },
        boxOrderStatus: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(5)}px`,

            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "5px",
            },
        },

        right: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
        },

    })
);

export const OrderDetailHeader: FunctionComponent<OrderDetailHeaderProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();

    const timeZone = 'America/New_York';
    const createAt = moment(props.order.createAt!);
    const createAtFormat = createAt.tz(timeZone) ? createAt.tz(timeZone).format("MMM DD, HH:mm A").toString() : "";

    const showCancelHandler = () => {
        props.onCancel();
    };
    //

    return (
        <Box className={classes.boxHeader}>
            <Box className={classes.left}>
                <Box className={classes.boxBack} onClick={() => props.onBack()}>
                    <FontAwesomeIcon
                        className={classes.backIcon}
                        icon={faArrowLeft}
                        size="lg"
                    />
                </Box>
                <Box className={classes.boxOrderTitle}>
                    <Title text={`Order #${props.order.number}`} />
                    <Box className={terminalCommonClasses.flexRow}>
                        <Typography className={clsx(terminalCommonClasses.typo15_500, classes.typoPlaced)}>Placed:</Typography>
                        <Typography className={terminalCommonClasses.typo15_400}>{createAtFormat}</Typography>
                    </Box>
                    <Box className={classes.boxOrderStatus}>
                        <OrderStatus orderStatus={props.order.status} />
                    </Box>
                </Box>
            </Box>
            <Box className={classes.right}>
                {
                    props.order.status !== statusOrder.CANCELLED &&
                    props.order.status !== statusOrder.SHIPPED &&
                    props.order.status !== statusOrder.RECEIVED &&
                    <Button label={"Cancel Order"} variant={"danger"} onClick={showCancelHandler} />
                }
            </Box>
        </Box>
    );
};

export default OrderDetailHeader;
