import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Client } from "model/Client";
import ChipMUI from "@material-ui/core/Chip";
import { Button } from "components/UI";

interface Props {
  client: Client;
  clientSelected?: number;
  loadingAddingBusiness: boolean;
  onAdd: (client: Client) => void; 
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      paddingLeft: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "9px",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "11px",
        paddingTop: "17px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "16px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    category: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "42px"
      },
    },
    businessName: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "25px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "33px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "50px",
      },
    },
    users: {
      width: "13%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "4px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "6px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "8px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "12px"
      },
    },
    status: {
      width: "9%",
      color: "#5E8677",
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
        height: "8px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "60px",
        height: "13px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "75px",
        height: "18px",
        fontSize: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        height: "27px",
        fontSize: "14px",
      },
    },
    multipleCategory: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "16px",
      },
    },
    dental: {
      backgroundColor: "#A6BAC8"
    },
    grooming: {
      backgroundColor: "#E9F0ED"
    },
    boarding: {
      backgroundColor: "#E4DDD5"
    },
    daycare: {
      backgroundColor: "#FAEFDF"
    },
    statusDisabled: {
      color: "#BCB8AE"
    },
    disabled: {
      color: "#D3D3D3"
    },
    disabledContainer: {
      backgroundColor: "#D3D3D3"
    },
    buttonContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    button: {
      "&.MuiButton-root": {
        fontWeight: 400
      },
      [theme.breakpoints.down("sm")]: {
        width: "25px",
        height: "12px",
        fontSize: "7px"
      },
      [theme.breakpoints.only("md")]: {
        width: "40px",
        height: "19px",
        fontSize: "9px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "53px",
        height: "30px",
        fontSize: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "81px",
        height: "39px",
        fontSize: "16px"
      }
    }
  })
);

export const NewBusinessRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const button: JSX.Element = (
    <Box className={classes.buttonContent}>
      <Button
        label="+ Add"
        color="black"
        onClick={() => props.onAdd(props.client)}
        className={classes.button}
        size="small"
        loading={props.loadingAddingBusiness && props.clientSelected === props.client.id}
      />
    </Box>
  );

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.businessName)}>
        {props.client.name}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.category)}>
        {props.client.categories.map((categ) => {
          return <Box>
              <ChipMUI
                label={categ.name}
                className={
                  clsx(classes.chip, {
                    [classes.dental]: categ.id === "dental", 
                    [classes.grooming]: categ.id === "grooming", 
                    [classes.boarding]: categ.id === "boarding", 
                    [classes.daycare]: categ.id === "daycare",
                    [classes.disabledContainer]: props.client.status.id === 2,
                    [classes.multipleCategory]: props.client.categories.length > 1
                  })
                }
                clickable={false}
              />
            </Box>})}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.users, {[classes.disabled]: props.client.status.id === 2})}>
        {props.client.usersQty}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.status, {[classes.statusDisabled]: props.client.status.id === 2})}>
        {props.client.status.name.toUpperCase()}
      </TableCell>
      <TableCell className={classes.cell}>
        {button}
      </TableCell>
    </TableRow>
  );
};

export default NewBusinessRow;