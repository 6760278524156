import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError, Option } from "@spike/model";
import clsx from "clsx";
import React, { FunctionComponent, useState } from "react";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";

import { wbp, reduceResolution } from "Theme";

export interface SelectFieldProps {
  label?: string | JSX.Element;
  name?: string;
  selectedOption?: Option<string | number>;
  placeholder?: string;
  options: Array<Option<string | number>>;
  errors?: Array<FieldError>;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  size?: "md" | "lg";
  //
  onSelect: (option: Option<string | number>, name?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      position: "relative",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(43)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "35px",
      },
    },
    collapsedContainer: {
      height: "100%",
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #222222 !important",
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: "30px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "35px",
      },
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(35)}px`,
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "35px",
        fontSize: "16px",
        lineHeight: "27px",
      },
    },
    headerMD: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(15)}px`,
        fontSize: `${reduceResolution(14)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "15px",
        fontSize: "14px",
        lineHeight: "27px",
      },
    },
    listContainer: {
      borderTop: "1px solid #222222",
      overflowY: "auto",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(12)}px`,
        maxHeight: `${reduceResolution(200)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "12px",
        maxHeight: "200px",
      },
    },
    placeholder: {
      color: "#7A7A7A",
    },
    border: {
      border: "1px solid #222222",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(33)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "33px",
      },
    },
    textContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "35px",
      },
    },
    chevronContainerMD: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "15px",
      },
    },
    itemContainer: {
      cursor: "pointer",
      borderRadius: "20px",
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(15)}px`,
        marginRight: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: "15px",
        marginRight: "15px",
      },

      "&:hover": {
        background: "#F8F8F8",
      },
    },

    item: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(4)}px ${reduceResolution(18)}px`,
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "4px 18px",
        fontSize: "16px",
        lineHeight: "27px",
      },
    },

    itemMD: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(4)}px 8px`,
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "4px 8px",
        fontSize: "13px",
      },
    },

    optionNotSelected: {
      border: "1px solid #D3D3D3 !important",
    },
  })
);

export const SelectField: FunctionComponent<SelectFieldProps> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const hasError = props.errors?.some((errorField) => errorField.fieldName === props.name);

  const clickCollapseHandler = () => {
    setIsCollapsed((prev) => props.disabled || !prev);
  };

  const changeHandler = (selectedId: string | number) => {
    props.onSelect(props.options.find((option) => option.id === selectedId)!, props.name);
  };

  const header = (
    <Box className={classes.headerContainer}>
      <Box className={classes.textContainer}>
        <Typography
          className={clsx(
            { [classes.header]: !props.size },
            { [classes.headerMD]: props.size === "md" },
            { [classes.placeholder]: !props.selectedOption }
          )}>
          {props.selectedOption ? props.selectedOption.name : props.placeholder}
        </Typography>
      </Box>
      <Box
        className={clsx(
          { [classes.chevronContainer]: !props.size },
          { [classes.chevronContainerMD]: props.size === "md" },
        )}
      >
        <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
      </Box>
    </Box>
  );

  const list = (
    <Box className={classes.listContainer}>
      {props.options.map((option) => (
        <Box key={option.id} className={classes.itemContainer} onClick={() => changeHandler(option.id)}>
          <Typography
            className={clsx(
              { [classes.itemMD]: !props.size },
              { [classes.itemMD]: props.size === "md" },
            )}
          >
            {option.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, {
        [classes.optionNotSelected]: !props.selectedOption && !hasError,
        [fieldClasses.errorBorder]: hasError,
        [fieldClasses.disabled]: props.disabled
      })}
      onClick={clickCollapseHandler}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box
      className={clsx(classes.border, classes.expandedContainer)}
      onClick={clickCollapseHandler}
    >
      {header}
      {list}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={() => setIsCollapsed(true)}>
      <Box className={clsx(fieldClasses.container, props.className)}>
        <LabelField {...props} />
        <Box className={classes.dropdownContainer}>{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectField;
