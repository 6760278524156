import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { LoginPath } from "./Paths";
import { useAuth } from "hooks/useAuth";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  const auth = useAuth();
  
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        auth.signedIn ? (
          <Component {...routeProps} {...routeProps.match.params}/>
        ) : (
          <Redirect
            to={{
              pathname: LoginPath,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
