import { Option } from "@spike/model";
import { Moment } from "moment-timezone";

export interface Business {
  id: number;
  name: string;
  city: string;
}

export interface BusinessIssue {
  id: number;
  name: string;
  priority: Option<number>;
  reporter: BusinessStaff;
  date: Moment;
  status: Option<number>;
}

export interface BusinessStaff {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  role: Option<number>;
  accessLevel: Option<number>;
  email: string;
  phone: string;
  active: boolean;
}

export const IssuePriorities: Array<Option<number>> = [
  { id: 1, name: "Lowest" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" }
];

export const IssueStatuses: Array<Option<number>> = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Working" },
  { id: 3, name: "Done" }
]

export enum SortFields {
  ISSUE_NAME = "issueName",
  STAFF_NAME = "staffName",
  TERMINAL_NAME = "terminalName",
  ROLE = "role",
  ACCESS_LEVEL = "accessLevel",
  EMAIL = "email",
  PRIORITY = "priority",
  REPORTER = "reporter",
  STATUS = "status"
}

export enum SortOrder {
  ASCENDANT = "ascendant",
  DESCENDANT = "descendant",
}
