import React, { FunctionComponent, useState, useEffect } from "react";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Box, Typography,
} from "@material-ui/core";
import { isEmailValid } from "utils/ValidationUtils";
import { resetThunk, loginThunk } from "actions/login/LoginActions";
import { LoginStatus } from "reducers/login/LoginState";
import { Button, Checkbox, Link, PasswordField, TextField } from "components/UI";
import { Img } from "react-image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    containerLogo: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "26px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "43px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "57px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "86px"
      },
    },
    adminContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    adminContent: {
      display: "flex",
      backgroundColor: "#EAB464",
      flexDirection: "column",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "4px",
        width: "28px",
        height: "10px",
        borderRadius: "1px",
        marginBottom: "3px"
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: "6px",
        width: "45px",
        height: "16px",
        borderRadius: "2px",
        marginBottom: "4px"
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: "8px",
        width: "60px",
        height: "22px",
        borderRadius: "3px",
        marginBottom: "6px"
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "12px",
        width: "91px",
        height: "33px",
        borderRadius: "5px",
        marginBottom: "9px"
      },
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: "79px",
        height: "24px"
      },
      [theme.breakpoints.only("md")]: {
        width: "128px",
        height: "39px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "170px",
        height: "51px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "258px",
        height: "78px"
      },
    },
    admin: {
      fontWeight: 600,
      color: "#fff",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "6px",
        lineHeight: "10px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "20px",
        lineHeight: "33px"
      },
    },
    contentContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "43px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "70px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "93px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "141px",
      },
    },
    passwordContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "48px",
      },
    },
    checkboxContainer: {
      "& .MuiTypography-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "4px !important"
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "6px !important"
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "9px !important"
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "13px !important",
        },
      },
      "& .MuiCheckbox-root": {
        paddingRight: "0px",
      },
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "4px !important",
          marginRight: "2px"
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "6px !important",
          marginRight: "2px"
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "9px !important",
          marginRight: "3px"
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "13px !important",
          marginRight: "4px"
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "6px",
        marginLeft: "6px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "9px",
        marginLeft: "10px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "13px",
        marginLeft: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "19px",
        marginLeft: "21px",
      },
    },
    actionsContainer: {
      flexDirection: "column",
      paddingTop: 0,
   
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "21px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "34px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "46px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "69px",
      },
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        height: "17px",
        width: "37px"
      },
      [theme.breakpoints.only("md")]: {
        height: "27px",
        width: "60px"
      },
      [theme.breakpoints.only("lg")]: {
        height: "36px",
        width: "80px"
      },
      [theme.breakpoints.only("xl")]: {
        height: "55px",
        width: "121px"
      },
    }
  })
);

export const LoginForm: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const loginFailed = useSelector<RootState, boolean>( state => state.login.status === LoginStatus.LoginFail);
  
  const loading = useSelector<RootState, boolean>( state => state.login.loading );

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [rememberMe, setRememberMe] = useState(true);
 
  useEffect(() => {
    dispatch(resetThunk());
  }, []);

  useEffect(() => {
    setPasswordError(loginFailed);
    setPasswordErrorMessage(loginFailed ? "Password is incorrect" : "");
  }, [loginFailed]);

  const login = (): void => {
    if (validate()) {
      dispatch(loginThunk(email, password, rememberMe));
    }
  };

  const validate = (): boolean => {
    let email: boolean = validateEmail();
    let password: boolean = validatePassword();

    return email && password;
  }

  const validateEmail = (): boolean => {
    if (email.length === 0) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
      return false;
    } else if (!isEmailValid(email)) {
      setEmailError(true);
      setEmailErrorMessage("Email invalid format");
      return false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
      return true;
    }
  }

  const validatePassword = (): boolean => {
    if (password.length === 0) {
      setPasswordError(true);
      setPasswordErrorMessage("Password is required");
      return false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
      return true;
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box className={classes.containerLogo}>
          <Img src="/images/logo/Logo.svg" className={classes.logo}/>
        </Box>
        <Box>
          <TextField
            label="Email"
            value={email}
            onChange={setEmail}
            error={emailError}
            errorMessage={emailErrorMessage}
            onEnterPressed={login}
          />
          <Box className={classes.passwordContainer}>
            <PasswordField
              label="Password"
              value={password}
              onChange={setPassword}
              error={passwordError}
              errorMessage={passwordErrorMessage}
              onEnterPressed={login}
            />
          </Box>
          <Box className={classes.checkboxContainer}>
            <Checkbox label="Remember Me" checked={rememberMe} onChange={setRememberMe} />
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionsContainer}>
        <Box className={classes.buttonContainer}>
          <Button label="Login" onClick={login} loading={loading} className={classes.button}/>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
