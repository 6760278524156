import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchThunk } from "actions/orders/OrdersActions";
import { Table, TableMessage } from "components/UI";
import { useNonInitialEffect } from "@versiondos/hooks";
import { RootState } from "store";
import HeaderComponent from "./UI/HeaderComponent";
import TableHeader from "./Table/TableHeader";
import TableBody from "./Table/TableBody";
import { Order } from "model/TerminalOrder";
import { reduceResolution, wbp } from "Theme";
import OrderDetail from "./Detail/OrderDetail";
import { SelectionBar } from "components/UI";
import { Option } from "@spike/model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    headerContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "17px",
      },
    },

    filterContainer: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px ${reduceResolution(60)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 50px",
      },
    },

    table: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "50px",
      },
    },

    selectionBar: {
      backgroundColor: "#F8F8F8",
      width: "fit-content",
    },
  })
);

export const Unpaid = { id: "new", name: "Unpaid" };
export const Open = { id: "open", name: "Order Placed" };
export const Delivered = { id: "in_progress", name: "In Progress" };
export const Shipped = { id: "out_for_delivery", name: "Shipped" };
export const Cancelled = { id: "cancelled", name: "Cancelled" };
export const Received = { id: "received", name: "Received" };
export const All = { id: "all", name: "All" };

export const filterOrders: Array<Option<string>> = [Open, Unpaid, Delivered, Shipped, Cancelled, Received, All];

export const Orders: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const orders = useSelector<RootState, Array<Order>>(state => state.orders.orders);
  const loading = useSelector<RootState, boolean>(state => state.orders.loading);

  const [filteredOrders, setFilteredOrders] = useState<Array<Order>>(orders);
  const [searchText, setSearchText] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [showOrdersAddForm, setShowOrdersAddForm] = useState(false);
  const [showOrdersEditForm, setShowOrdersEditForm] = useState(false);
  const [editedOrder, setEditedOrderId] = useState<Order>();
  const [filterOrderId, setFilterOrderId] = useState<string>(All.id);

  const searchClientName = (order: Order) => {
    if (order.adminPayments
      && order.adminPayments[0]
    ) { return order.adminPayments[0].clientName.toLowerCase(); }
    else return ""
  };

  const filter = (text: string): Array<Order> => {
    const filteredOrdersAux = orders.filter((orders) => {
      const id = `${orders.id!.toString()}`;
      const marketplace = searchClientName(orders);
      const tracking = `${orders.trackingNumber}`;

      let name;

      marketplace ? name = `${id} ${marketplace}` : name = `${id} `;
      tracking ? name = `${name} ${tracking.toLowerCase()}` : name = `${name} `;

      return (
        text.length === 0 || name.indexOf(text.toLowerCase()) > -1
      );
    });

    return filteredOrdersAux;
  };

  const changeFilteredOrders = (filteredOrders: Array<Order>) => {
    setFilteredOrders(filteredOrders);
    setIsEmpty(filteredOrders.length === 0);
  };

  const addOrdersHandler = () => {
    setShowOrdersAddForm(true);
    setShowOrdersEditForm(false);
  };

  const editOrdersHandler = (order: Order) => {
    setEditedOrderId(order);
    setShowOrdersEditForm(true);
    setShowOrdersAddForm(false);
  };

  const handlebackForm = () => {
    setShowOrdersAddForm(false);
    setShowOrdersEditForm(false);
  };

  useNonInitialEffect(() => {
    changeFilteredOrders(filter(searchText));
  }, [orders]);

  useNonInitialEffect(() => {
    changeFilteredOrders(filter(searchText));
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchThunk());
  }, []);

  const changeFilterOrderHandler = (id: string) => {
    setFilterOrderId(id);
  };

  const header = (
    <HeaderComponent
      title="Terminals Orders"
      onSearch={setSearchText}
      onClickButton={addOrdersHandler}
      searchPlaceholder="Search by Order, Client, Tracking #"
    />
  );

  const table = (
    <Table
      head={<TableHeader />}
      body={<TableBody
        orders={
          filterOrderId === All.id ? filteredOrders :
            filteredOrders.filter((order) => order.status === filterOrderId)}
        //
        onEditOrder={(order: Order) => editOrdersHandler(order)}
      />}
      className={classes.table}
    />
  );


  const filterView = (
    <SelectionBar
      options={[...filterOrders]}
      selectedId={filterOrderId}
      selectedTotalRecords={
        filterOrderId === All.id ?
          filteredOrders.length : filteredOrders.filter((order) => order.status === filterOrderId).length
      }
      className={classes.selectionBar}
      //
      onSelect={changeFilterOrderHandler}
    />
  );

  const ordersEditForm = (
    <OrderDetail
      order={editedOrder!}
      //
      onClose={handlebackForm}
    />
  );

  const empty = <TableMessage title="Not Found!" description="Terminal Orders not found." />;

  return (
    <Box className={classes.container}>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        {!showOrdersAddForm && !showOrdersEditForm &&
          <>
            <Box className={classes.headerContainer}>
              {header}
            </Box>
            <Box className={classes.filterContainer}>
              {filterView}
            </Box>
          </>
        }


        {!showOrdersAddForm && !showOrdersEditForm && (loading || !isEmpty ? table : empty)}
        {showOrdersEditForm && ordersEditForm}
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Orders;
