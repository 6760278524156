import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Superadmin } from "model/Superadmin";
import SuperadminHeader  from "./SuperadminHeader";
import { Button, PasswordField, TextField } from "components/UI";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "model";
import { SuperadminsStatus } from "reducers/superadmins/SuperadminsState";
import _ from "lodash";

interface Props {
  superadmin?: Superadmin;
  title: string;
  onBack: () => void;
  onSave: (superadmin: Superadmin) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    contentContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    content: {
      width: "25%"
    },
    field: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "25px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "33px",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "50px",
      },
    },
    buttonContainer: {

    }
  })
);

export const SuperadminEditComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const loading = useSelector<RootState, boolean>(state => state.superadmins.loading);
  const status = useSelector<RootState, SuperadminsStatus>(state => state.superadmins.status);
  const [saved, setSaved] = useState(false);

  const superadminInitial: Superadmin = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    avatar: "",
    status: {
      id: 1,
      name: "active"
    }
  }

  const [superadmin, setSuperadmin] = useState(props.superadmin ? {...props.superadmin} : superadminInitial);
  const [errors, setErrors] = useState<Array<FieldError>>([]);

  useNonInitialEffect(() => {
    setSaved(_.isEqual(props.superadmin, superadmin));
  },[superadmin]);

  useNonInitialEffect(() => {
    if(status === SuperadminsStatus.SaveSuccess) {
      setSaved(true);
    }
  },[status]);

  const cleanErrors = (fieldName: string) => {
    setErrors((prev) => prev.filter((e) => e.fieldName !== fieldName));
  };

  const validate = () => {
    const errors: Array<FieldError> = [];

    if(_.isEmpty(superadmin.firstName)) {
      errors.push({fieldName: "firstName", errorMessage: "First Name is required"});
    }
    if(_.isEmpty(superadmin.lastName)) {
      errors.push({fieldName: "lastName", errorMessage: "Last Name is required"});
    }
    if(_.isEmpty(superadmin.email)) {
      errors.push({fieldName: "email", errorMessage: "Email is required"});
    }

    setErrors([...errors]);

    return errors;
  };

  const changeValueSuperadminHandler = (value: string, fieldName: string) => {
    cleanErrors(fieldName);

    const attributes = fieldName.split(".");

    setSuperadmin((prev: any) => {
      if (attributes.length === 1) {
        prev[attributes[0]] = value;
      } else if (attributes.length === 2) {
        prev[attributes[0]][attributes[1]] = value;
      }
      return prev;
    });
  };

  const clickButtonHandler = () => {
    const validationErrors = validate();
    if (validationErrors.length === 0) {
      props.onSave({...superadmin});
    }
  };

  const changePasswordHandler = (password: string) => {
    setSuperadmin((prev) => {
      return {...prev, password: password};
    });
  };

  const changeConfirmPasswordHandler = (password: string) => {
    setSuperadmin((prev) => {
      return {...prev, confirmPassword: password};
    });
  };

  const button = (<Button label={saved ? "Saved!" : "Save"} onClick={clickButtonHandler} loading={loading} />);

  return (
    <Box className={classes.container}>
      <SuperadminHeader 
        title={props.title}
        onBack={props.onBack}
      />
      <Box className={classes.contentContainer}>
        <Box className={classes.content}>
          <TextField
            label="First Name*"
            name="firstName"
            value={superadmin.firstName ? superadmin.firstName : ""}
            onChange={changeValueSuperadminHandler}
            className={classes.field}
            errors={errors}
          />
          <TextField
            label="Last Name*"
            name="lastName"
            value={superadmin.lastName ? superadmin.lastName : ""}
            onChange={changeValueSuperadminHandler}
            className={classes.field}
            errors={errors}
          />
          <TextField 
            label="Email*" 
            name="email" 
            value={superadmin.email} 
            onChange={changeValueSuperadminHandler} 
            className={classes.field}
            errors={errors} 
          />
          <Box className={classes.field}>
            <PasswordField
              label="Password"
              value={superadmin.password ? superadmin.password : ""}
              onChange={changePasswordHandler}
              error={false}
              errorMessage={""}
            />
          </Box>
          <Box className={classes.field}>
            <PasswordField
              label="Confirm Password"
              value={superadmin.confirmPassword ? superadmin.confirmPassword : ""}
              onChange={changeConfirmPasswordHandler}
              error={false}
              errorMessage={""}
            />
          </Box>
        </Box>
      <Box className={classes.buttonContainer}>
        {button}
      </Box>
      </Box>
    </Box>
  );
};

export default SuperadminEditComponent;