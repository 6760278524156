import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Client } from "model/Client";
import ChipMUI from "@material-ui/core/Chip";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItemWithConfirmation from "components/UI/Toolbar/ToolbarItemWithConfirmation";
import { faBan, faCheckCircle } from "@fortawesome/pro-light-svg-icons";


interface Props {
  client: Client;
  activate: (clientId: number, active: boolean) => void;
  onShowBusiness: (clientId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      paddingLeft: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        paddingTop: "17px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    category: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "5px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "42px"
      },
    },
    businessName: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "27px",
        fontSize: "9px",
        lineHeight: "24px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "43px",
        fontSize: "11px",
        lineHeight: "24px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "57px",
        fontSize: "14px",
        lineHeight: "24px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "87px",
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    locations: {
      textAlign: "center",
      width: "12%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "24px",
        paddingLeft: "13px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "24px",
        paddingLeft: "10px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "24px",
        paddingLeft: "10px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "21px",
        paddingLeft: "8px"
      },
    },
    users: {
      textAlign: "center",
      width: "13%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "6px",
        paddingLeft: "16px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "11px",
        paddingLeft: "16px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "14px",
        paddingLeft: "18px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "21px",
        paddingLeft: "22px"
      },
    },
    terminals: {
      textAlign: "center",
    },
    status: {
      width: "9%",
      textAlign: "center",
      color: "#5E8677",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "6px",
        paddingLeft: "10px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "11px",
        paddingLeft: "15px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "14px",
        paddingLeft: "20px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "21px",
        paddingLeft: "25px"
      }
    },
    actions: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "25px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "33px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "50px"
      },
    },
    chip: {
      borderColor: "transparent",
      backgroundColor: "#E9F0ED",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
        height: "8px",
        fontSize: "5px",
        marginBottom: "5px"
      },
      [theme.breakpoints.only("md")]: {
        width: "60px",
        height: "13px",
        fontSize: "8px",
        marginBottom: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "75px",
        height: "18px",
        fontSize: "10px",
        marginBottom: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        height: "27px",
        fontSize: "14px",
        marginBottom: "16px",
      },
    },
    terminalsContainer: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "6px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "21px",
      },
    },
    dental: {
      backgroundColor: "#A6BAC8"
    },
    grooming: {
      backgroundColor: "#E9F0ED"
    },
    boarding: {
      backgroundColor: "#E4DDD5"
    },
    daycare: {
      backgroundColor: "#FAEFDF"
    },
    statusDisabled: {
      color: "#BCB8AE"
    },
    disabled: {
      color: "#D3D3D3"
    },
    disabledContainer: {
      backgroundColor: "#D3D3D3"
    },
  })
);

export const ClientRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const handleActivate = () => {
    props.activate(props.client.id, true);
  };
  const handleDeactivate = () => {
    props.activate(props.client.id, false);
  };

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.businessName)} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.name}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.category)} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.categories.map((categ) => {
          return <Box>
            <ChipMUI
              label={categ.name}
              className={
                clsx(classes.chip, {
                  [classes.dental]: categ.id === "dental",
                  [classes.grooming]: categ.id === "grooming",
                  [classes.boarding]: categ.id === "boarding",
                  [classes.daycare]: categ.id === "daycare",
                  [classes.disabledContainer]: props.client.status.id === 2
                })
              }
              clickable={false}
            />
          </Box>
        })}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.locations, { [classes.disabled]: props.client.status.id === 2 })} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.locationsQty}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.users, { [classes.disabled]: props.client.status.id === 2 })} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.usersQty}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.terminals, { [classes.disabled]: props.client.status.id === 2 })} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.terminals}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.status, { [classes.statusDisabled]: props.client.status.id === 2 })} onClick={() => props.onShowBusiness(props.client.id)}>
        {props.client.status.name.toUpperCase()}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.actions)}>
        <ToolbarButton>
          {props.client.status.id !== 1 ? (
            <ToolbarItemWithConfirmation
              icon={faCheckCircle}
              onConfirmedClick={handleActivate}
              text={"Activate"}
              confirmQuestion={
                <Typography>
                  Do you want to activate{" "}
                  <b>{props.client.name}</b> ?{" "}
                </Typography>
              }
              confirmButtonLabel="Yes"
              cancelButtonLabel="No"
            />
          ) : (
            <ToolbarItemWithConfirmation
              icon={faBan}
              onConfirmedClick={handleDeactivate}
              text={"Deactivate"}
              confirmQuestion={
                <Typography>
                  Do you want to deactivate{" "}
                  <b>{props.client.name}</b> ?{" "}
                </Typography>
              }
              confirmButtonLabel="Yes"
              cancelButtonLabel="No"
            />
          )}
        </ToolbarButton>
      </TableCell>
    </TableRow >
  );
};

export default ClientRow;
