import { Action } from "redux";
import { User, UserBusinessAppointments } from "model/User";

//Types
export const USERS_RESET_ACTION_TYPE = "USERS_RESET";
export const USERS_GET_START_ACTION_TYPE = "USERS_GET_START";
export const USERS_GET_SUCCESS_ACTION_TYPE = "USERS_GET_SUCCESS";
export const USERS_RESET_PASSWORD_START_ACTION_TYPE = "USERS_RESET_PASSWORD_START";
export const USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE = "USERS_RESET_PASSWORD_SUCCESS";
export const USERS_UPDATE_USER_START_ACTION_TYPE = "USERS_UPDATE_USER_START";
export const USERS_UPDATE_USER_SUCCESS_ACTION_TYPE = "USERS_UPDATE_USER_SUCCESS";
export const USERS_CHANGE_STATUS_START_ACTION_TYPE = "USERS_CHANGE_STATUS_START";
export const USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE = "USERS_CHANGE_STATUS_SUCCESS";
export const USERS_ADD_BUSINESS_START_ACTION_TYPE = "USERS_ADD_BUSINESS_START";
export const USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE = "USERS_ADD_BUSINESS_SUCCESS";
export const USERS_REMOVE_BUSINESS_START_ACTION_TYPE = "USERS_REMOVE_BUSINESS_START";
export const USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE = "USERS_REMOVE_BUSINESS_SUCCESS";
export const USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE = "USERS_CHECK_APPOINTMENTS_START";
export const USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE = "USERS_CHECK_APPOINMENTS_SUCCESS";
export const USERS_ERROR_ACTION_TYPE = "USERS_ERROR";

//Actions
interface UsersResetAction extends Action<typeof USERS_RESET_ACTION_TYPE> { }

interface UsersGetStartAction extends Action<typeof USERS_GET_START_ACTION_TYPE> { }

interface UsersGetSuccessAction extends Action<typeof USERS_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    user: User;
  };
}

interface UsersResetPasswordStartAction extends Action<typeof USERS_RESET_PASSWORD_START_ACTION_TYPE> { }

interface UsersResetPasswordSuccessAction extends Action<typeof USERS_RESET_PASSWORD_SUCCESS_ACTION_TYPE> { }

interface UsersUserUpdateStartAction extends Action<typeof USERS_UPDATE_USER_START_ACTION_TYPE> { }

interface UsersUserUpdateSuccessAction extends Action<typeof USERS_UPDATE_USER_SUCCESS_ACTION_TYPE> { }

interface UsersChangeStatusStartAction extends Action<typeof USERS_CHANGE_STATUS_START_ACTION_TYPE> { }

interface UsersChangeStatusSuccessAction extends Action<typeof USERS_CHANGE_STATUS_SUCCESS_ACTION_TYPE> {
  payload: {
    userId: number,
    active: boolean,
  }
}

interface UsersAddBusinessStartAction extends Action<typeof USERS_ADD_BUSINESS_START_ACTION_TYPE> { }

interface UsersAddBusinessSuccessAction extends Action<typeof USERS_ADD_BUSINESS_SUCCESS_ACTION_TYPE> { }

interface UsersRemoveBusinessStartAction extends Action<typeof USERS_REMOVE_BUSINESS_START_ACTION_TYPE> { }

interface UsersRemoveBusinessSuccessAction extends Action<typeof USERS_REMOVE_BUSINESS_SUCCESS_ACTION_TYPE> { }

interface UsersCheckAppointmentsStartAction extends Action<typeof USERS_CHECK_APPOINTMENTS_START_ACTION_TYPE> { }

interface UsersCheckAppointmentsSuccessAction extends Action<typeof USERS_CHECK_APPOINTMENTS_SUCCESS_ACTION_TYPE> {
  payload: {
    appointments: UserBusinessAppointments;
  }
}

interface UsersErrorAction extends Action<typeof USERS_ERROR_ACTION_TYPE> { }

//UsersAction
export type UsersAction =
  | UsersResetAction
  | UsersGetStartAction
  | UsersGetSuccessAction
  | UsersResetPasswordStartAction
  | UsersResetPasswordSuccessAction
  | UsersUserUpdateStartAction
  | UsersUserUpdateSuccessAction
  | UsersChangeStatusStartAction
  | UsersChangeStatusSuccessAction
  | UsersAddBusinessStartAction
  | UsersAddBusinessSuccessAction
  | UsersRemoveBusinessStartAction
  | UsersRemoveBusinessSuccessAction
  | UsersCheckAppointmentsStartAction
  | UsersCheckAppointmentsSuccessAction
  | UsersErrorAction;
