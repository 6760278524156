import { createStyles, Theme, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import createBreakpoints, { Breakpoints } from "@material-ui/core/styles/createBreakpoints";

export const wbp = 1440;
export const reduceResolution = (size: number): number => size * 0.8;

export const cuddlesStyle = {
  textField: {
    border: {
      colors: {
        active: "#222222",
        inactive: "#E5E5E5",
        error: "#EA6464"
      }
    },
    label: {
      colors: {
        active: "#222222",
        inactive: "#7A7A7A",
        error: "#C14A4A",
      }
    }
  },
  checkBox: {
    color: "#222222",
  },
  radio: {
    color: "#222222",
  },
  incrementField: {
    colors: {
      text: "#222222",
      background: "#F7F7F7",
      border: "#DBDBDB",
    }
  },
  hourField: {
    colors: {
      text: "#222222",
      background: "#FFFFFF",
      border: "#DBDBDB",
      disabled: {
        text: "#D3D3D3",
        background: "#F7F7F7",
      }
    }
  },

  colors: {
    primary: "#EAB464",
    dark: "#D08B25",
    buttonText: "#FFFFFF",
    background: "#F7F7F7",
    border: "#D3D3D3",
    errorBorder: "#EA6464",

    toolbar: {
      background: "#FFFFFF",
      text: "#222222",
    },
    text: {
      primary: "#222222",
      disabled: "#B7B7B7",
      error: "#C14A4A",
    }
  },
};

function createTheme(): Theme {

  const breakpoints: Breakpoints = createBreakpoints({});

  return createMuiTheme({
    breakpoints,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      }
    },
    palette: {
      primary: {
        main: cuddlesStyle.colors.primary,
        dark: cuddlesStyle.colors.dark,
        contrastText: cuddlesStyle.colors.buttonText,
      },
      background: {
        default: cuddlesStyle.colors.background,
      },
      text: {
        primary: cuddlesStyle.colors.text.primary,
        disabled: cuddlesStyle.colors.text.disabled,
      },
      divider: cuddlesStyle.colors.border,
      action: {
        //To disbale de halo effect on the checkbox hover.
        hoverOpacity: 0,
      },
      error: {
        main: cuddlesStyle.textField.label.colors.error
      }
    },
    typography: {
      fontFamily: "Poppins",
      [breakpoints.only("xs")]: {
        fontSize: "14px",
      },
      [breakpoints.only("sm")]: {
        fontSize: "15px",
      },
      [breakpoints.only("md")]: {
        fontSize: "16px",
      },
      [breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: "none !important",
          border: 0,
          padding: 0,
          [breakpoints.down("sm")]: {
            height: "34px",
          },
          [breakpoints.only("md")]: {
            height: "55px",
          },
          [breakpoints.only("lg")]: {
            height: "73px",
          },
          [breakpoints.up("xl")]: {
            height: "110px",
          },
          borderBottom: "2px solid #F1F1F1",
        },
        colorPrimary: {
          backgroundColor: cuddlesStyle.colors.toolbar.background,
          color: cuddlesStyle.colors.toolbar.text,
        },
      },
      MuiToolbar: {
        root: {
          height: "100%",
          justifyContent: "flex-end",
        },
        regular: {
          minHeight: "0px !important"
        }
      },
      MuiPaper: {
        root: {
          border: 0,
          [breakpoints.only("xs")]: {
            fontSize: "14px",
          },
          [breakpoints.only("sm")]: {
            fontSize: "15px",
          },
          [breakpoints.only("md")]: {
            fontSize: "16px",
          },
          [breakpoints.up("lg")]: {
            fontSize: "18px",
          },
        },
        elevation1: {
          boxShadow: "0px 60px 30px -70px rgba(0, 0, 0, 0.5)",
        },
        rounded: {
          [breakpoints.only("xs")]: {
            borderRadius: "28px",
          },
          [breakpoints.only("sm")]: {
            borderRadius: "36px",
          },
          [breakpoints.only("md")]: {
            borderRadius: "46px",
          },
          [breakpoints.up("lg")]: {
            borderRadius: "58px",
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          [breakpoints.down("md")]: {
            borderRadius: "21px",
            height: "29px",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          [breakpoints.only("lg")]: {
            borderRadius: "31px",
            height: "39px",
            paddingTop: "7px",
            paddingBottom: "7px",
            paddingRight: "32px",
            paddingLeft: "32px",
          },
          [breakpoints.up("xl")]: {
            borderRadius: "31px",
            height: "43px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingRight: "36px",
            paddingLeft: "36px",
          },
        },
        label: {
          whiteSpace: "nowrap",
          fontWeight: 600,
          [breakpoints.down("md")]: {
            fontSize: "11px",
            lineHeight: "18px",
          },
          [breakpoints.only("lg")]: {
            fontSize: "14px",
            lineHeight: "24px",
          },
          [breakpoints.up("xl")]: {
            fontSize: "16px",
            lineHeight: "27px",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },

      MuiOutlinedInput: {
        root: {
          fontWeight: 400,
          [breakpoints.down("md")]: {
            borderRadius: "17px",
            height: "26px",
            fontSize: "9px",
            lineHeight: "14px",
          },
          [breakpoints.only("lg")]: {
            borderRadius: "22px",
            height: "35px",
            fontSize: "12px",
            lineHeight: "18px",
          },
          [breakpoints.up("xl")]: {
            borderRadius: "33px",
            height: "53px",
            fontSize: "18px",
            lineHeight: "27px",
          },
          "& legend": {
            maxWidth: 0, //Avoid the label border interuption.
          },
          "& .MuiOutlinedInput-notchedOutline": {
            //borderWidth: "1px !important",
            //borderColor: cuddlesStyle.textField.border.colors.inactive + " !important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
            borderColor: cuddlesStyle.textField.border.colors.active + " !important",
          },
          "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !imporant",
            borderColor: cuddlesStyle.textField.border.colors.active + " !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: cuddlesStyle.textField.border.colors.error + " !important",
          },
        },
        input: {
          paddingTop: "0px",
          paddingBottom: "0px",
          fontFamily: "Poppins",
          [breakpoints.down("md")]: {
            paddingRight: "17px",
            paddingLeft: "17px",
            height: "26px",
          },
          [breakpoints.only("lg")]: {
            paddingRight: "23px",
            paddingLeft: "23px",
            height: "35px",
          },
          [breakpoints.up("xl")]: {
            paddingRight: "35px",
            paddingLeft: "35px",
            height: "53px",
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          paddingTop: "0px",
          paddingBottom: "0px",
          [breakpoints.down("md")]: {
            transform: "translate(17px, 6px)",
            fontSize: "9px",
            lineHeight: "14px"
          },
          [breakpoints.only("lg")]: {
            transform: "translate(23px, 9px)",
            fontSize: "12px",
            lineHeight: "18px"
          },
          [breakpoints.up("xl")]: {
            transform: "translate(35px, 13px)",
            fontSize: "18px",
            lineHeight: "27px"
          },
          "&.MuiFormLabel-filled": {
            color: cuddlesStyle.textField.label.colors.active,
          },
          "&.Mui-focused": {
            color: cuddlesStyle.textField.label.colors.active,
          },
          "&.Mui-error": {
            color: cuddlesStyle.textField.label.colors.error,
          },
          "&.Mui-focused.Mui-error": {
            color: cuddlesStyle.textField.label.colors.error,
          },
          "&.MuiInputLabel-shrink": {
            [breakpoints.down("md")]: {
              transform: "translate(17px, -10px) scale(0.66)",
            },
            [breakpoints.only("lg")]: {
              transform: "translate(23px, -14px) scale(0.66)",
            },
            [breakpoints.up("xl")]: {
              transform: "translate(35px, -20px) scale(0.66)",
            },
          },
        },
      },
      MuiMenu: {
        paper: {
          [breakpoints.only("xs")]: {
            borderRadius: 15,
          },
          [breakpoints.only("sm")]: {
            borderRadius: 19,
          },
          [breakpoints.only("md")]: {
            borderRadius: 24,
          },
          [breakpoints.up("lg")]: {
            borderRadius: 30,
          },
        }
      },
      MuiCheckbox: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          color: cuddlesStyle.checkBox.color,
        }
      },
      MuiRadio: {
        colorPrimary: {
          "&.Mui-checked": {
            color: cuddlesStyle.radio.color,
          }
        }
      }
    },
  });
}

export const commonStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "none",
      [theme.breakpoints.down("md")]: {
        borderRadius: "21px",
        height: "29px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "24px",
        paddingLeft: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "31px",
        height: "39px",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingRight: "32px",
        paddingLeft: "32px",
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "31px",
        height: "43px",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingRight: "36px",
        paddingLeft: "36px",
      },
      "& .MuiButton-label": {
        whiteSpace: "nowrap",
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          fontSize: "11px",
          lineHeight: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "14px",
          lineHeight: "24px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "16px",
          lineHeight: "27px",
        },
      },
    },
    field: {
      "& .MuiInputBase-root": {
        fontWeight: 400,
        [theme.breakpoints.down("md")]: {
          borderRadius: "17px",
          height: "26px",
          fontSize: "9px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("lg")]: {
          borderRadius: "22px",
          height: "35px",
          fontSize: "12px",
          lineHeight: "18px",
        },
        [theme.breakpoints.up("xl")]: {
          borderRadius: "33px",
          height: "53px",
          fontSize: "18px",
          lineHeight: "27px",
        },
        "& legend": {
          maxWidth: 0, //Avoid the label border interuption.
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !important",
          borderColor: "#222222 !important",
        },
        "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !imporant",
          borderColor: "#222222 !important",
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#EA6464 !important",
        },
      },
      "& input": {
        paddingTop: "0px",
        paddingBottom: "0px",
        fontFamily: "Poppins",
        [theme.breakpoints.down("md")]: {
          paddingRight: "17px",
          paddingLeft: "17px",
          height: "26px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingRight: "23px",
          paddingLeft: "23px",
          height: "35px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingRight: "35px",
          paddingLeft: "35px",
          height: "53px",
        },
      },
      "& .MuiInputLabel-outlined": {
        paddingTop: "0px",
        paddingBottom: "0px",
        [theme.breakpoints.down("md")]: {
          transform: "translate(17px, 6px)",
          fontSize: "9px",
          lineHeight: "14px",
        },
        [theme.breakpoints.only("lg")]: {
          transform: "translate(23px, 9px)",
          fontSize: "12px",
          lineHeight: "18px",
        },
        [theme.breakpoints.up("xl")]: {
          transform: "translate(35px, 13px)",
          fontSize: "18px",
          lineHeight: "27px",
        },
        "&.MuiFormLabel-filled": {
          color: "#222222",
        },
        "&.Mui-focused": {
          color: "#222222",
        },
        "&.Mui-error": {
          color: "#C14A4A",
        },
        "&.Mui-focused.Mui-error": {
          color: "#C14A4A",
        },
        "&.MuiInputLabel-shrink": {
          [theme.breakpoints.down("md")]: {
            transform: "translate(17px, -10px) scale(0.66)",
          },
          [theme.breakpoints.only("lg")]: {
            transform: "translate(23px, -14px) scale(0.66)",
          },
          [theme.breakpoints.up("xl")]: {
            transform: "translate(35px, -20px) scale(0.66)",
          },
        },
      },
    },
  })
);

export const cuddlesTheme: Theme = createTheme();
