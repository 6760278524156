import { Option } from "@spike/model";
import { TerminalModel } from "./TerminalOrder";

export const emptyMasterData: Masterdata = {
  accessLevels: [],
  businessAreas: [],
  orderStatuses: [],
  roles: [],
  terminalsModels: [],
  terminalStatuses: [],
};

export interface Masterdata {
  accessLevels: Array<Option<number>>;
  businessAreas: Array<Option<string>>;
  orderStatuses: Array<OrderStatuses>;
  roles: Array<Option<number>>;
  terminalsModels: Array<TerminalModel>;
  terminalStatuses: Array<TerminalStatuses>;
};

export interface OrderStatuses {
  id: string;
  name: string;
  backgroundColor: string;
  fontColor: string;
};

export interface TerminalStatuses {
  id: string;
  name: string;
};
