import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box, Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Spinner } from "components/UI";

interface Props {
  issues: number;
  users: number;
  terminals: number;
  loadingStaff: boolean;
  loadingIssues: boolean;
  gettingBusiness: boolean;
  selectedItemId: string;
  onSelect: (selected: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    sectionContainer: {
      display: "flex",
      cursor: "pointer",
      justifyContent: "center",
      borderBottom: "5px solid #fff",
      [theme.breakpoints.down("sm")]: {
        width: "49px",
      },
      [theme.breakpoints.only("md")]: {
        width: "78px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "104px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "158px",
      },
    },
    sectionSelected: {
      borderColor: "#EAB464"
    },
    users: {
      fontWeight: 300,
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "42px",
      },
    },
    icon: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        marginBottom: "1px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "19px",
        marginBottom: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "26px",
        marginBottom: "9px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "39px",
        marginBottom: "17px"
      },
    },
    centered: {
      textAlign: "center"
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
      },
    },
    sectionContent: {
      display: "flex",
      flexDirection: "column",
    },
    textSection: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "5px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
        lineHeight: "8px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
        lineHeight: "11px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        lineHeight: "16px"
      },
    },
    textSectionSelected: {
      fontWeight: 600
    },
    green: {
      backgroundColor: "#61CE8D"
    },
    yellow: {
      backgroundColor: "#FFDF6B"
    },
    lightRed: {
      backgroundColor: "#EF4F57"
    },
    red: {
      backgroundColor: "#CC444B"
    },
    alerts: {
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "2px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        paddingTop: "2px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "20px",
        paddingTop: "3px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "30px",
        paddingTop: "5px"
      },
    },
    alertsContainer: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "17px",
        height: "17px",
        borderRadius: "3px",
        marginBottom: "1px"
      },
      [theme.breakpoints.only("md")]: {
        width: "28px",
        height: "28px",
        borderRadius: "5px",
        marginBottom: "1px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "37px",
        height: "37px",
        borderRadius: "7px",
        marginBottom: "2px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "56px",
        height: "56px",
        borderRadius: "10px",
        marginBottom: "3px"
      },
    },
    issuesContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "17px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "22px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "33px"
      },
    },
    usersContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "9px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "14px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "19px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "29px"
      },
    },
    calendarContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "15px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "24px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "36px"
      },
    }
  })
);

export const RightHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const alertsItem = "alerts";
  const usersItem = "users";
  const terminalsItem = "terminals";

  return (
    <Box className={classes.container}>
      <Box className={clsx(classes.sectionContainer, classes.issuesContainer, { [classes.sectionSelected]: props.selectedItemId === alertsItem })} onClick={() => props.onSelect(alertsItem)}>
        {props.loadingIssues || props.gettingBusiness ? <Spinner /> : <Box className={classes.sectionContent}>
          <Box className={clsx(classes.alertsContainer, {
            [classes.green]: props.issues === 0,
            [classes.yellow]: props.issues >= 1 && props.issues < 6,
            [classes.lightRed]: props.issues >= 6 && props.issues < 20,
            [classes.red]: props.issues >= 20
          })}>
            <Typography className={classes.alerts}>{props.issues}</Typography>
          </Box>
          <Typography className={clsx(classes.text, classes.centered, { [classes.textSectionSelected]: props.selectedItemId === alertsItem })}>ALERTS</Typography>
        </Box>}
      </Box>
      <Box className={clsx(classes.sectionContainer, classes.usersContainer, { [classes.sectionSelected]: props.selectedItemId === usersItem })} onClick={() => props.onSelect(usersItem)}>
        {props.loadingStaff || props.gettingBusiness ? <Spinner /> : <Box className={classes.sectionContent}>
          <Box className={classes.centered}>
            <Typography className={classes.users}>{props.users}</Typography>
          </Box>
          <Typography className={clsx(classes.text, classes.centered, { [classes.textSectionSelected]: props.selectedItemId === usersItem })}>USERS</Typography>
        </Box>}
      </Box>
      <Box className={clsx(classes.sectionContainer, classes.usersContainer, { [classes.sectionSelected]: props.selectedItemId === terminalsItem })} onClick={() => props.onSelect(terminalsItem)}>
        {props.loadingStaff || props.gettingBusiness ? <Spinner /> : <Box className={classes.sectionContent}>
          <Box className={classes.centered}>
            <Typography className={classes.users}>{props.terminals}</Typography>
          </Box>
          <Typography className={clsx(classes.text, classes.centered, { [classes.textSectionSelected]: props.selectedItemId === terminalsItem })}>TERMINALS</Typography>
        </Box>}
      </Box>
    </Box>
  );
};

export default RightHeader;
