import {
  LoginAction,
  LOGIN_RESET_ACTION_TYPE,
  LOGIN_START_ACTION_TYPE,
  LOGIN_SUCCESS_ACTION_TYPE,
  LOGIN_FAIL_ACTION_TYPE,
  LOGOUT_ACTION_TYPE,
  LOGIN_ERROR_ACTION_TYPE,
} from "./LoginActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { LoginState } from "reducers/login/LoginState";
import ApiClient from "api";
import { push } from "connected-react-router";
import { CallHistoryMethodAction } from "connected-react-router";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { showErrorAlert } from "actions/alerts/AlertsActions";
import { saveInStorage, removeFromStorage } from "auth";
import { LoginResponseDto } from "./LoginDtos";
import { convert } from "./LoginConverter";
import { Auth } from "model/Auth";
import { getSuccess as storeMasterdata } from "actions/masterdata/MasterdataActions";
import { MasterdataAction } from "actions/masterdata/MasterdataActionsTypes";

const url = "login_superadmin";

export const resetThunk = (): ThunkAction<void, LoginState, null, LoginAction> => {
  return async (dispatch) => {
    removeFromStorage();
    dispatch(reset());
  };
};

export const loginThunk = (
  email: string,
  password: string,
  rememberMe: boolean
): ThunkAction<void, LoginState, null, LoginAction | AlertAction | CallHistoryMethodAction | MasterdataAction> => {
  return async (dispatch) => {
    const HTTP_STATUS_401_UNAUTHORIZED = 401;

    dispatch(start());

    removeFromStorage();

    try {
      const response: AxiosResponse<LoginResponseDto> = await ApiClient.post(url, {
        email: email.trim().toLowerCase(),
        password,
      });

      const auth = convert(response.data, true);

      dispatch(loginSuccess(auth));

      rememberUser(auth, rememberMe);
      
      dispatch(storeMasterdata(response.data.master_data));
      
      dispatch(push("/"));
      
    } catch (apiError) {
      if (apiError.response && apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
        dispatch(fail());
      } else {
        dispatch(error());
        dispatch(showErrorAlert("Login error."));
      }
    }
  };
};

export const logoutThunk = (): ThunkAction<void, LoginState, null, LoginAction | CallHistoryMethodAction> => {
  return async (dispatch) => {
    removeFromStorage();
    dispatch(logout());
    dispatch(push("/login"));
  };
};

const rememberUser = (auth: Auth, rememberMe: boolean) => {
  saveInStorage(auth, rememberMe);
};

export const loginSuccess = (auth: Auth): LoginAction => {
  return {
    type: LOGIN_SUCCESS_ACTION_TYPE,
    payload: {
      auth,
    },
  };
};

const start = (): LoginAction => {
  return {
    type: LOGIN_START_ACTION_TYPE,
  };
};

const fail = (): LoginAction => {
  return {
    type: LOGIN_FAIL_ACTION_TYPE,
  };
};

const reset = (): LoginAction => {
  return {
    type: LOGIN_RESET_ACTION_TYPE,
  };
};

const logout = (): LoginAction => {
  return {
    type: LOGOUT_ACTION_TYPE,
  };
};

const error = (): LoginAction => {
  return {
    type: LOGIN_ERROR_ACTION_TYPE,
  };
};
