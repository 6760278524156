import { MasterdataState, MasterdataStatus } from "./MasterdataState";
import {
  MASTERDATA_RESET_ACTION_TYPE,
  MASTERDATA_GET_START_ACTION_TYPE,
  MASTERDATA_GET_SUCCESS_ACTION_TYPE,
  MASTERDATA_ERROR_ACTION_TYPE,
  MasterdataAction,
} from "actions/masterdata/MasterdataActionsTypes";
import { Reducer } from "redux";
import { emptyMasterData } from "model/Masterdata";

const initialState: MasterdataState = {
  data: emptyMasterData,
  status: MasterdataStatus.Initial,
  loading: false,
};

export const masterdataReducer: Reducer<MasterdataState, MasterdataAction> = (state = initialState, action) => {
  switch (action.type) {
    case MASTERDATA_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case MASTERDATA_GET_START_ACTION_TYPE:
      return {
        ...state,
        status: MasterdataStatus.Getting,
        loading: true,
      };
    case MASTERDATA_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        data: { ...action.payload.masterdata },
        status: MasterdataStatus.GetSuccess,
        loading: false,
      };
    case MASTERDATA_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: MasterdataStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default masterdataReducer;
