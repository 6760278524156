import { Action } from "redux";
import { VariantType } from "notistack";

//Types
export const RESET_ALERT_ACTION_TYPE = "RESET_ALERT";
export const ADD_ALERT_ACTION_TYPE = "ADD_ALERT";
export const REMOVE_ALERT_ACTION_TYPE = "REMOVE_ALERT";

export const InfoSeverity: VariantType = "info";
export const SuccessSeverity: VariantType = "success";
export const WarningSeverity: VariantType = "warning";
export const ErrorSeverity: VariantType = "error";

export type AlertSeverity = VariantType;

export interface AddAlertMessage {
  id: string;
  severity: AlertSeverity;
  message: string;
}

export interface RemoveAlertMessage {
  id: string;
}

//Actions
export interface ResetAlertAction extends Action<typeof RESET_ALERT_ACTION_TYPE> {}

export interface AddAlertAction extends Action<typeof ADD_ALERT_ACTION_TYPE> {
  payload: AddAlertMessage;
}

export interface RemoveAlertAction extends Action<typeof REMOVE_ALERT_ACTION_TYPE> {
  payload: RemoveAlertMessage;
}

export type AlertAction =
ResetAlertAction
  | AddAlertAction
  | RemoveAlertAction;
