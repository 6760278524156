import { BusinessesState, BusinessesStatus } from "./BusinessesState";
import {
  BUSINESSES_RESET_ACTION_TYPE,
  BUSINESSES_GET_BUSINESS_START_ACTION_TYPE,
  BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE,
  BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE,
  BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE,
  BUSINESSES_FETCH_STAFF_START_ACTION_TYPE,
  BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE,
  BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE,
  BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE,
  BUSINESSES_ERROR_ACTION_TYPE,
  BusinessesAction,
} from "actions/businesses/BusinessesActionsTypes";
import { Reducer } from "redux";

const initialState: BusinessesState = {
  business: undefined,
  issues: [],
  staff: [],
  terminals: [],
  status: BusinessesStatus.Initial,
  loadingBusiness: false,
  loadingIssues: false,
  loadingTerminal: false,
  loadingUsers: false,
};

export const businessesReducer: Reducer<BusinessesState, BusinessesAction> = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESSES_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case BUSINESSES_GET_BUSINESS_START_ACTION_TYPE:
      return {
        ...state,
        business: undefined,
        staff: [],
        issues: [],
        status: BusinessesStatus.GettingBusiness,
        loadingBusiness: true,
        loadingIssues: false,
        loadingUsers: false,
      };
    case BUSINESSES_GET_BUSINESS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        business: { ...action.payload.business },
        status: BusinessesStatus.GetBusinessSuccess,
        loadingBusiness: false,
      };
    case BUSINESSES_FETCH_ISSUES_START_ACTION_TYPE:
      return {
        ...state,
        issues: [],
        status: BusinessesStatus.FetchingIssues,
        loadingIssues: true,
      };
    case BUSINESSES_FETCH_ISSUES_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        issues: action.payload.issues,
        status: BusinessesStatus.FetchIssuesSuccess,
        loadingIssues: false,
      };
    case BUSINESSES_FETCH_TERMINAL_START_ACTION_TYPE:
      return {
        ...state,
        terminals: [],
        status: BusinessesStatus.FetchingTerminal,
        loadingTerminal: true,
      };
    case BUSINESSES_FETCH_TERMINAL_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        terminals: [...action.payload.terminals],
        status: BusinessesStatus.FetchTerminalSuccess,
        loadingTerminal: false,
      };
    case BUSINESSES_FETCH_STAFF_START_ACTION_TYPE:
      return {
        ...state,
        staff: [],
        status: BusinessesStatus.FetchingStaff,
        loadingUsers: true,
      };
    case BUSINESSES_FETCH_STAFF_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        staff: [...action.payload.staff],
        status: BusinessesStatus.FetchStaffSuccess,
        loadingUsers: false,
      };
    case BUSINESSES_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: BusinessesStatus.Error,
        business: undefined,
        issues: [],
        staff: [],
        loadingBusiness: false,
        loadingIssue: false,
        loadingUsers: false,
      };
    default:
      return state;
  }
};

export default businessesReducer;
