import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Box, Typography, Avatar } from "@material-ui/core";
import clsx from "clsx";
import { BusinessIssue } from "model/Business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  issue: BusinessIssue;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
      },
      "&.MuiTableRow-root": {
        verticalAlign: "baseline"
      }
    },
    cell: {
      paddingLeft: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        paddingTop: "13px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        paddingTop: "17px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    issueName: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "91px",
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "146px",
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "195px",
        fontSize: "14px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "295px",
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    arrow: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "2px",
        fontSize: "9px",
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "4px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "5px",
        fontSize: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "8px",
        fontSize: "18px",
      },
    },
    priorityContainer: {
      display: "flex"
    },
    priority: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "6px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "10px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "13px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "20px"
      },
    },
    lowest: {
      color: "#61CE8D"
    },
    medium: {
      color: "#EAB464"
    },
    high: {
      color: "#EF4F57"
    },
    date: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "24px"
      },
    },
    statusContainer: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        height: "10px",
        width: "31px",
        fontSize: "9px",
        lineHeight: "7px"
      },
      [theme.breakpoints.only("md")]: {
        height: "16px",
        width: "50px",
        fontSize: "11px",
        lineHeight: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        height: "21px",
        width: "66px",
        fontSize: "14px",
        lineHeight: "16px",
        paddingTop: "2px"
      },
      [theme.breakpoints.only("xl")]: {
        height: "32px",
        width: "100px",
        fontSize: "18px",
        lineHeight: "24px",
        paddingTop: "3px"
      },
    },
    pending: {
      backgroundColor: "#FBB9BC"
    },
    working: {
      backgroundColor: "#61A7CE",
      color: "#fff"
    },
    done: {
      backgroundColor: "#C0EBD1"
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "row",
    },
    avatarContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "2px",
        height: "7px !important",
        width: "7px !important",
      },
      [theme.breakpoints.only("md")]: {
        marginRight: "4px",
        height: "11px !important",
        width: "11px !important",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "5px",
        height: "15px !important",
        width: "15px !important",
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "8px",
        height: "22px !important",
        width: "22px !important",
      },
    },
    reporterName: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
  })
);

export const AlertRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.issueName)}>
        {props.issue.name}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.priorityContainer)}>
        <FontAwesomeIcon 
          icon={props.issue.priority.id === 3 ? faArrowDown : faArrowUp} 
          className={clsx(classes.arrow, {
            [classes.lowest]: props.issue.priority.id === 3,
            [classes.medium]: props.issue.priority.id === 2,
            [classes.high]: props.issue.priority.id === 1
          })} />
        <Typography className={classes.priority}>{props.issue.priority.name}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <Box className={classes.avatarContainer}>
          <Box className={classes.avatarContent}>
            <Avatar src="" className={classes.avatar} />
          </Box>
          <Typography noWrap={true} className={classes.reporterName}>
            {props.issue.reporter.firstName} {props.issue.reporter.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.date)}>
        {props.issue.date.format("MM/DD/YYYY")}
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <Box className={clsx(classes.statusContainer, {
          [classes.done]: props.issue.priority.id === 3,
          [classes.working]: props.issue.priority.id === 2,
          [classes.pending]: props.issue.priority.id === 1
        })}>
          {props.issue.status.name}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default AlertRow;