import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import {
  Select as MUISelect,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Option } from "@spike/model";

export interface Props {
  label?: string;
  value?: string | number;
  options: Array<Option<string | number>>;
  onChange: (value: string | number) => void;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .MuiOutlinedInput-input": {
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "7px",
          paddingRight: "7px",
        },
        [theme.breakpoints.only("md")]: {
          paddingLeft: "11px",
          paddingRight: "11px",
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "10px",
          paddingRight: "10px",
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "22px",
          paddingRight: "22px",
        },
      },
      "& .MuiSelect-select:focus": {
        background: "none !important",
      },
    },
    chevron: {
      marginTop: "5px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px",
        marginRight: "5px",
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: "8px",
        marginRight: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: "10px",
        marginRight: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "15px",
        marginRight: "15px",
      },
    },
    menuItem: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
      },
    }
  })
);

const Chevron: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <FontAwesomeIcon
      icon={faChevronDown}
      className={clsx(
        "MuiSelect-icon",
        "MuiSelect-iconOutlined",
        classes.chevron
      )}
    />
  );
};

export const Select: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [error, setError] = useState(props.error);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);



  useEffect(() => {
    setError(props.error);
    setErrorMessage(props.errorMessage);
  }, [props.error, props.errorMessage]);

  const options = props.options.map((option) => (
    <MenuItem value={option.id} key={option.id} className={classes.menuItem}>
      {option.name}
    </MenuItem>
  ));

  const label = (): string => {
    return error && errorMessage ? errorMessage : props.label ? props.label : "";
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    let value: string | number = event.target.value as string|number;
    setError(false);
    setErrorMessage(undefined);
    props.onChange(value);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.formControl, props.className)}
      error={error}
      disabled={props.disabled}
    >
      <InputLabel>{label()}</InputLabel>
      <MUISelect value={props.value} onChange={handleChange} IconComponent={Chevron}>
        {options}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
