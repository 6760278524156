import React, { FunctionComponent, useState, Fragment } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  title: string;
  onBack: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
    },
    backContainer: {
      display: "flex",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "10px",
        height: "10px",
        marginTop: "19px",
        fontSize: "11px",
        marginLeft: "28px"
      },
      [theme.breakpoints.only("md")]: {
        width: "16px",
        height: "16px",
        marginTop: "31px",
        fontSize: "18px",
        marginLeft: "45px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "21px",
        height: "21px",
        marginTop: "41px",
        fontSize: "24px",
        marginLeft: "60px"
      },
      [theme.breakpoints.only("xl")]: {
        width: "32px",
        height: "32px",
        marginTop: "62px",
        fontSize: "36px",
        marginLeft: "90px"
      },
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        marginLeft: "61px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "18px",
        marginLeft: "99px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "24px",
        marginLeft: "132px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
        marginLeft: "200px"
      },
    },
    titleButtonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
        marginBottom: "17px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "25px",
        marginBottom: "27px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "33px",
        marginBottom: "36px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "50px",
        marginBottom: "54px",
      },
    },
  })
);

export const StaffHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.backContainer} onClick={() => props.onBack()}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Box>
      <Box className={classes.titleButtonContainer}>
        <Typography className={classes.title}>{props.title}</Typography>
      </Box>
    </Box>
  );
};

export default StaffHeader;
