import { Option } from "model";

export interface Client {
  id: number;
  name: string;
  city: string;
  categories: Array<Option<string>>;
  locationsQty: number;
  usersQty: number;
  terminals: number;
  status: Option<number>;
}

export interface ClientsOverview {
  totalBusinesses: number;
  totalUsers: number;
  totalRevenue: string;
}

export enum SortFields {
  BUSINESS_NAME = "businessName",
  TERMINALS = "terminals",
}

export enum SortOrder {
  ASCENDANT = "ascendant",
  DESCENDANT = "descendant",
}
