import { Order, Payment, Terminal } from "model/TerminalOrder";

export interface OrdersState {
  orders: Array<Order>;
  order?: Order;
  payment?: Payment;
  terminal: Terminal | undefined;
  status: OrdersStatus;
  loading: boolean;
}

export enum OrdersStatus {
  Initial,
  Fetching,
  FetchSuccess,
  gettingOrder,
  getOrderSucess,
  gettingTerminal,
  getTerminalSucess,
  Saving,
  SaveSuccess,
  SavingTerminal,
  SaveTerminalSuccess,
  SavingRefund,
  SaveRefundSuccess,
  Error,
}
