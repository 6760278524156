import {
  NotificationsAction,
  NOTIFICATIONS_RESET_ACTION_TYPE,
  NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE,
  NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE,
  NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE,
  NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE,
  NOTIFICATIONS_ERROR_ACTION_TYPE,
} from "./NotificationsActionsTypes";
import { ThunkAction } from "redux-thunk";
import { NotificationsState } from "reducers/notifications/NotificationsState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { alertErrorHandler } from "actions/ActionUtils";

const suspendUserUrl = "admin/send_suspend_emails";
const activateUserUrl = "admin/send_activate_emails";

export const notifyUserSuspendedThunk = (
  userId: number,
  notifyOwners: boolean,
  notifyUser: boolean,
): ThunkAction<void, NotificationsState, null, NotificationsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(notifyingUserSuspended());

    const url = `${suspendUserUrl}/${userId}`;

    const body = {
      user: notifyUser,
      business: notifyOwners
    };

    try {
      ApiClient.post(url, body, createTokenConfig());
      dispatch(notifyUserSuspendedSuccess());
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error notifying user suspended userId = ${userId}`);
    }
  };
};

export const notifyUserActivatedThunk = (
  userId: number,
  notifyOwners: boolean,
  notifyUser: boolean,  
): ThunkAction<void, NotificationsState, null, NotificationsAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(notifyingUserActivated());

    const url = `${activateUserUrl}/${userId}`;

    const body = {
      user: notifyUser,
      business: notifyOwners
    };
    
    try {
      ApiClient.post(url, body, createTokenConfig());
      dispatch(notifyUserActivatedSuccess());
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, `Error notifying user activated userId = ${userId}`);
    }
  };
};

export const reset = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_RESET_ACTION_TYPE,
  };
};

const notifyingUserSuspended = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_SUSPENDED_USER_START_ACTION_TYPE,
  };
};

const notifyUserSuspendedSuccess = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_SUSPENDED_USER_SUCCESS_ACTION_TYPE,
  };
};

const notifyingUserActivated = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_ACTIVATED_USER_START_ACTION_TYPE,
  };
};

const notifyUserActivatedSuccess = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_ACTIVATED_USER_SUCCESS_ACTION_TYPE,
  };
};

const error = (): NotificationsAction => {
  return {
    type: NOTIFICATIONS_ERROR_ACTION_TYPE,
  };
};
