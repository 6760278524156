import { Option } from "@spike/model";

export interface User {
  userId: number;
  staffId:number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businesses: Array<UserBusiness>;
  active: boolean;
  activeAppoinments: number;
  hasAppoinments: boolean;
}

export interface UserBusiness {
  workId: number;
  businessId: number;
  businessName: string;
  businessCategories: Array<Option<string>>;
  role: Option<number>;
  accessLevel: Option<number>;
  active: boolean; 
}

export interface UserBusinessAppointments {
  staffId: number;
  businessId: number;
  hasAppointments: boolean;
  activeAppointments: number;
}

export enum SortFields {
  BUSINESS_NAME = "businessName",
}

export enum SortOrder {
  ASCENDANT = "ascendant",
  DESCENDANT = "descendant",
}
