import {
  OrdersAction,
  ORDERS_RESET_ACTION_TYPE,
  ORDERS_FETCH_START_ACTION_TYPE,
  ORDERS_FETCH_SUCCESS_ACTION_TYPE,
  ORDERS_ORDER_GET_START_ACTION_TYPE,
  ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE,
  ORDERS_TERMINAL_GET_START_ACTION_TYPE,
  ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE,
  ORDERS_SAVE_START_ACTION_TYPE,
  ORDERS_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_TERMINAL_SAVE_START_ACTION_TYPE,
  ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_REFUND_SAVE_START_ACTION_TYPE,
  ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE,
  ORDERS_ERROR_ACTION_TYPE,
} from "./OrdersActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { OrdersState } from "reducers/orders/OrdersState";
import ApiClient, { createTokenConfig } from "api";
import { AlertAction } from "actions/alerts/AlertsActionsTypes";
import { alertErrorHandler } from "actions/ActionUtils";
import { showSuccessAlert } from "actions/alerts/AlertsActions";
import { Order, Terminal, Refund, Payment } from "model/TerminalOrder";
import { OrderDto, PaymentDto, TerminalDto } from "./OrdersDtos";
import { convertToOrder, convertToPayment, convertToTerminal } from "./OrdersConverter";

const ordersUrl = "admin/terminal_orders";
const terminalUrl = "admin/terminals";
const refundUrl = "admin/refunds"

export const fetchThunk = (): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(fetching());
    try {
      const response: AxiosResponse<Array<OrderDto>> = await ApiClient.get(ordersUrl, createTokenConfig());
      dispatch(fetchSuccess(response.data.map((dto) => convertToOrder(dto))));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error fetching orders.");
    }
  };
};

export const getOrderThunk = (
  orderId: number,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(gettingOrder());
    try {
      const url = `${ordersUrl}/${orderId}`;
      const response: AxiosResponse<OrderDto> = await ApiClient.get(url, createTokenConfig());
      dispatch(fetchOrderSuccess(convertToOrder(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error fetching orders.");
    }
  };
};

export const getTerminalThunk = (
  terminalId: number,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(gettingTerminal());
    try {
      const url = `${terminalUrl}/${terminalId}`;
      const response: AxiosResponse<TerminalDto> = await ApiClient.get(url, createTokenConfig());
      dispatch(getTerminalSuccess(convertToTerminal(response.data)));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error fetching terminal.");
    }
  };
};

export const changeOrderStatusThunk = (
  orderId: number,
  status: string,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveOrderStart());

    const url = `${ordersUrl}/${orderId}`;
    const apiMethod = ApiClient.patch;

    const terminalOrder = {
      status: status,
    };

    const requestBody = {
      terminal_order: terminalOrder
    };

    try {
      const response: AxiosResponse<OrderDto> = await apiMethod(url, requestBody, createTokenConfig());
      dispatch(saveOrderSuccess(convertToOrder(response.data)));
      dispatch(showSuccessAlert("Order save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving Order.");
    }
  };
};

export const changeTerminalStatusThunk = (
  terminalId: number,
  status: string,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveTerminalStart());
    let formData = new FormData();
    formData.append("terminal[status]", status);

    const url = `${terminalUrl}/${terminalId}`;
    const apiMethod = ApiClient.patch;

    try {
      const response: AxiosResponse<TerminalDto> = await apiMethod(url, formData, createTokenConfig());
      dispatch(saveTerminalSuccess(convertToTerminal(response.data)));
      dispatch(showSuccessAlert("Terminal save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving Terminal.");
    }
  };
};

export const saveTerminalStatusThunk = (
  terminal: Terminal,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveTerminalStart());
    let formData = new FormData();

    formData.append("terminal[name]", terminal.name);
    formData.append("terminal[terminal_model_id]", terminal.terminalModelId);
    formData.append("terminal[serial]", terminal.serial);
    formData.append("terminal[status]", terminal.status);

    const url = `${terminalUrl}/${terminal.id}`;
    const apiMethod = ApiClient.patch;

    try {
      const response: AxiosResponse<TerminalDto> = await apiMethod(url, formData, createTokenConfig());
      dispatch(saveTerminalSuccess(convertToTerminal(response.data)));
      dispatch(showSuccessAlert("Terminal save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving Terminal.");
    }
  };
};

export const saveRefundThunk = (
  refund: Refund,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveRefundStart());

    const body = {
      admin_refund: {
        "admin_payment_id": refund.adminPaymentId,
        "amount": refund.amount,
        "reason": refund.reason,
      }
    };

    const url = `${refundUrl}`;
    const apiMethod = ApiClient.post;

    try {
      const response: AxiosResponse<PaymentDto> = await apiMethod(url, body, createTokenConfig());
      dispatch(saveRefundSuccess(convertToPayment(response.data)));
      dispatch(showSuccessAlert("Order refund save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving refund.");
    }
  };
};


export const activeTerminalStatusThunk = (
  terminalId: number,
  active: boolean,
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveTerminalStart());
    let formData = new FormData();

    formData.append("terminal[active]", active.toString());

    const url = `${terminalUrl}/${terminalId}`;
    const apiMethod = ApiClient.patch;

    try {
      const response: AxiosResponse<TerminalDto> = await apiMethod(url, formData, createTokenConfig());
      dispatch(saveTerminalSuccess(convertToTerminal(response.data)));
      dispatch(showSuccessAlert("Terminal save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving Terminal.");
    }
  };
};

export const saveTerminalOrderThunk = (
  order: Order
): ThunkAction<void, OrdersState, null, OrdersAction | AlertAction> => {
  return async (dispatch) => {
    dispatch(saveOrderStart());

    const url = `${ordersUrl}/${order.id}`;
    const apiMethod = ApiClient.patch;

    const terminalOrder = {
      courier: order.courier,
      tracking_number: order.trackingNumber,
    };

    const requestBody = {
      terminal_order: terminalOrder
    };

    try {
      const response: AxiosResponse<OrderDto> = await apiMethod(url, requestBody, createTokenConfig());
      dispatch(saveOrderSuccess(convertToOrder(response.data)));
      dispatch(showSuccessAlert("Order save successfully"));
    } catch (apiError) {
      dispatch(error());
      alertErrorHandler(apiError, dispatch, "Error saving Order.");
    }
  };
};

export const reset = (): OrdersAction => {
  return {
    type: ORDERS_RESET_ACTION_TYPE,
  };
};

const fetching = (): OrdersAction => {
  return {
    type: ORDERS_FETCH_START_ACTION_TYPE,
  };
};

const gettingOrder = (): OrdersAction => {
  return {
    type: ORDERS_ORDER_GET_START_ACTION_TYPE,
  };
};

const gettingTerminal = (): OrdersAction => {
  return {
    type: ORDERS_TERMINAL_GET_START_ACTION_TYPE,
  };
};


const fetchSuccess = (orders: Array<Order>): OrdersAction => {
  return {
    type: ORDERS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      orders,
    },
  };
};

const fetchOrderSuccess = (order: Order): OrdersAction => {
  return {
    type: ORDERS_ORDER_GET_SUCCESS_ACTION_TYPE,
    payload: {
      order,
    },
  };
};

const getTerminalSuccess = (terminal: Terminal): OrdersAction => {
  return {
    type: ORDERS_TERMINAL_GET_SUCCESS_ACTION_TYPE,
    payload: {
      terminal,
    },
  };
};

const saveOrderStart = (): OrdersAction => {
  return {
    type: ORDERS_SAVE_START_ACTION_TYPE,
  };
};

const saveTerminalStart = (): OrdersAction => {
  return {
    type: ORDERS_TERMINAL_SAVE_START_ACTION_TYPE,
  };
};

const saveRefundStart = (): OrdersAction => {
  return {
    type: ORDERS_REFUND_SAVE_START_ACTION_TYPE,
  };
};

const saveOrderSuccess = (order: Order): OrdersAction => {
  return {
    type: ORDERS_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      order,
    },
  };
};

const saveTerminalSuccess = (terminal: Terminal): OrdersAction => {
  return {
    type: ORDERS_TERMINAL_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      terminal,
    },
  };
};

const saveRefundSuccess = (payment: Payment): OrdersAction => {
  return {
    type: ORDERS_REFUND_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      payment,
    },
  };
};

const error = (): OrdersAction => {
  return {
    type: ORDERS_ERROR_ACTION_TYPE,
  };
};
