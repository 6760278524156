import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import HeaderItem, { Item } from "./HeaderItem";

interface Props {
  items: Array<Item>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
  })
);

export const HeaderItems: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const items = props.items.map(item => {
    return <HeaderItem key={item.path} item={item} />;
  });

  return <Box className={classes.container}>{items}</Box>;
};

export default HeaderItems;
