import React, { FunctionComponent } from "react";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { Client } from "model/Client";
import ClientRow from "../ClientRow";

interface Props {
  clients: Array<Client>;
  activate: (clientId: number, active: boolean) => void;
  onShowBusiness: (clientId: number) => void;
}

export const TableBody: FunctionComponent<Props> = (props) => {
  return (
    <TableBodyMUI>
      {props.clients.map((client, index) => (
        <ClientRow 
          key={index} 
          client={client} 
          activate={props.activate}
          onShowBusiness={props.onShowBusiness}
        /> 
      ))
      }
    </TableBodyMUI>
  );
};

export default TableBody;
